import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { fileDownload, requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import DocPreview from '@components/DocPreview';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module specific components
import ExportTo from '../components/ExportTo';
import InvoiceNote from '../components/InvoiceNote';
import Invoice from '../components/documents/Invoice';
import InvoiceStatus from '../components/InvoiceStatus';
import ListStatsView from '../components/stats/ListStatsView';
import CreateDocument from '../components/forms/CreateDocument';
import InvoiceDivideToCC from '../components/InvoiceDivideToCC';
import InvoiceFilterForm from '../components/forms/InvoiceFilterForm';
import CreateLinkedDocument from '../components/CreateLinkedDocument';
import InvoiceApprovalForm from '../components/forms/InvoiceApprovalForm';
import ApprovalStatusIndicator from '../components/ApprovalStatusIndicator';

const InvoiceList = ({ type, connection, user, choices }) => {
    const { t } = useTranslation()
    const pairing_status = useSelector(state => state.pairing_status)

    function convertSelectedToIds(invoices){
        let ids = ""
        for (let i = 0; i < invoices.length; i++) {
            if( invoices.length - 1 === i ){
                ids += invoices[i].id
            } else {
                ids += invoices[i].id + ","
            }
        }

        return ids
    }

    const exportData = async (selected, exportType, queryParam) => {
        let exports = ["export", "xml_export_pohoda", "xml_export_mksoft", "export_kros_omega", "pdf_zip_download"]
        let fileType = ""
        let filename = ""
        let endpoint = API.API_URL + "/integrations/invoices/" // xlsx by default
        if (exportType === "xlsx") {
            endpoint += exports[0] + "/"
            fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            filename = "xlsx-export.xlsx"
        }
        if (exportType === "pohoda") {
            endpoint += exports[1] + "/"
            fileType = "application/xml"
            filename = "xml-pohoda.xml"
        }
        if (exportType === "mksoft") {
            endpoint += exports[2] + "/"
            fileType = "application/xml"
            filename = "xml-mksoft.xml"
        }
        if (exportType === "kros") {
            endpoint += exports[3] + "/"
            fileType = "text/plain;charset=utf-8"
            filename = "kros_omega.txt"
        }
        if (exportType === "zip_download") {
            endpoint += exports[4] + "/"
            fileType = "application/zip"
            filename = "invoices.zip"
        }

        // append connection and journal + type
        endpoint += "?connection=" + connection.connection_id + "&account=" + user.journal.id + "&is_issued=" + (type === "issued")

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('generating_export') + "...",
            description: t("wait_a_moment_until_its_finished") + ".",
            animation: 'bounce',
            time: 3000,
        })

        // when there are no selected invoices then export all based on filters specified
        if (selected.length === 0){
            await fileDownload("GET", endpoint + queryParam, fileType, filename)
        } else {
            await fileDownload("GET", endpoint + "&ids=" + convertSelectedToIds(selected), fileType, filename)
        }
    }

    const generatePDF = async (invoice) => {
        toast({
            type: 'success',
            icon: 'check circle',
            title: t('generating_export') + "...",
            description: t("wait_a_moment_until_its_finished") + ".",
            animation: 'bounce',
            time: 3000,
        })

        await fileDownload("GET", API.API_URL + `/integrations/invoices/${invoice.id}/pdf/?connection=${connection.connection_id}&account_ids=${user.journal.id}`, "application/pdf", `invoice_${invoice.number}.pdf`)
    }

    const onDelete = async (invoice, setData, setTotal) => {
        // fetch splitted invoices to CC
        const request = await requests.get(API.COST_CENTERS_RECORDS + "?invoice_reference=" + (invoice.id + "__" + invoice.number))

        let errorReason = t('invoice_delete_request_error')
        const deleteInvoiceRequest = await requests.del(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id)

        if( deleteInvoiceRequest.status === 204 ){
            if(request.status === 200) {
                for (let i = 0; i < request.response.length; i++) {
                    await requests.del(API.COST_CENTERS_RECORDS + request.response[i].id + "/")
                }
            }

            const orderRef = invoice.ref_order_number
            if ( orderRef !== null && orderRef !== "" && orderRef !== undefined) {
                const refs = orderRef.split("ref:")
                for (let i in refs) {
                    if (refs[i] !== "") {
                        let refParts = refs[i].split("__")
                        await requests.patch(API.ORDERS + "fulfillment_edit/" + refParts[1] + "/", { invoice_reference: null })
                    }
                }
            }

            setData(prev => prev.filter(item => item.id !== invoice.id))
            setTotal(prev => prev - 1)
        } else {
            if( deleteInvoiceRequest.status === 400 ) errorReason = deleteInvoiceRequest.response?.reason

            toast({
                type: 'error',
                icon: 'warning',
                title: t('error_occured'),
                description: errorReason,
                animation: 'bounce',
                time: 5000,
            });
        }
    }  

    const renderColor = (invoice) => {
        if( parseFloat(invoice.split_ratio) <= 0) return "var(--success)"
        return "var(--danger)"
    }

    const renderTooltip = (invoice) => {
        if( parseFloat(invoice.split_ratio) <= 0) return t('fully_splitted_invoice')
        return t('invoice_not_fully_splitted') + ". " + t("remaining") + ": " + invoice.split_ratio + "%"
    }

    const pairingParams = () => {
        let additionalParams = {}
        if (user.journal?.ibans?.length > 0){
            const ibans = user.journal?.ibans
            let ibanList = "&ibans="
            for (let i = 0; i < ibans.length; i++) {
                const iban = ibans[i].iban.replace(/\s/g, '')
                if (iban.length >= 15){
                    if (i !== ibans.length - 1){
                        ibanList += iban + ";"
                    } else {
                        ibanList += iban
                    }
                }
            }
            
            if (pairing_status) {
                additionalParams['pair_invoices'] = true
                additionalParams['custom_filter'] = ibanList
            }
        }

        return additionalParams
    }

    return (
        <ListView as="table"
            key={type}
            allowSelection
            allowSearch
            actionsCellWidth="2"
            endpoint={API.GET_INVOICES}
            allowBulkNonSelected={true}
            loadingMessage={t('loading_invoices')}
            query={"&connection=" + connection.connection_id + "&is_issued=" + (type === "issued") + "&account_ids=" + user.journal.id}
            initialFilters={{
                invoice_type: "",
                currency: "",
                number: "",
                status: "",
                constant_symbol: "",
                specific_symbol: "",
                variable_symbol: "",

                price_from: "",
                price_to: "",

                supplier_name: "",
                customer_name: "",
                supplier_registration_id: "",
                customer_registration_id: "",
                supplier_vat_id: "",
                customer_vat_id: "",
                supplier_tax_id: "",
                customer_tax_id: "",
                customer_country: "",
                supplier_country: "",
                customer_city: "",
                supplier_city: "",
                date_issue_after: moment().format("YYYY") + "-01-01",
                date_issue_before: moment().format("YYYY") + "-12-31",

                payment_is_approved: "",
                approval: "",
                split_ratio: "",
                split_ratio_to: "",
                split_ratio_from: "",
                ordering: "-created_at",
                ...pairingParams()
            }}
            tableHeaders={[
                { title: t('number') },
                { title: type === "issued" ? t('account') : t('supplier') },
                { title: t('order_number') },
                { title: t('sum') },
                { title: t('dates') },
                { title: t('note') },
                { title: t('status') },
                { title: t('scan') },
                // { title: t('factoring') },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <InvoiceFilterForm
                    type={type}
                    filters={filters}
                    setFilters={setFilters}
                    choices={choices}
                />
            )}
            renderCells={(invoice, setData) => ([
                { content: 
                    <>
                        <strong>{ invoice.number }
                        { !invoice.is_issued && <ApprovalStatusIndicator invoice={invoice} connection={connection}/> }
                        </strong><br/>
                        <small>
                            { invoice.invoice_type_display } { invoice.ref_invoice?.id && 
                                <> 
                                    {t('for') + " " } 
                                    <strong>{ invoice.ref_invoice?.number }</strong> 
                                </>
                            }
                        </small>
                    </> 
                },
                { content:
                    <div style={{ minWidth: "150px" }}>
                        <span style={{ fontSize: "1.1rem" }}>
                            { invoice.is_issued ? invoice.customer_name : invoice.supplier_name }
                        </span> <br/>
                        <strong>
                            { invoice.is_issued ? invoice.customer_registration_id : invoice.supplier_registration_id }
                        </strong>
                    </div>
                },
                { content: 
                    <div style={{ minWidth: "100px" }}>
                        { invoice.order_number }
                    </div>
                },
                { content: invoice?.price_to_pay !== null ? <> { thousandsSeparators(invoice?.price_to_pay || 0) } { invoice.currency } </> : "--" },
                { content: 
                    <>
                        <span>{t('issue_invoice_date')}:</span> <strong> { invoice.date_issue !== null ? moment(invoice.date_issue).format("DD.MM.YYYY") : "--" }   </strong> <br/>
                        <span>{t('supply_invoice_date')}:</span> <strong> { invoice.date_supply !== null ? moment(invoice.date_supply).format("DD.MM.YYYY") : "--" } </strong> <br/>
                        <span>{t('due_to_invoice_date')}:</span> <strong> { invoice.date_due !== null ? moment(invoice.date_due).format("DD.MM.YYYY") : "--" }  </strong>
                    </>
                },
                {
                    content: 
                        <InvoiceNote
                            key={invoice.id}
                            invoice={invoice}
                            setData={setData}
                            connection={connection}
                            canManage={user.canManage}
                        />
                },
                { content: 
                    <div style={{ maxWidth: "100px" }}>
                        <InvoiceStatus 
                            setData={setData}
                            invoice={invoice}
                            connection={connection}
                            canManage={user.canManage}
                        />
                    </div>
                },
                { content: 
                    <>
                        { invoice.scan === null
                            ? "--"
                            :
                            <SuperDuperModal
                                size="large"
                                trigger={
                                    <Icon
                                        title={t('scan')}
                                        name="document-text-outline"
                                        style={{ cursor: "pointer", color: "var(--dark)", fontSize: "1.5rem" }}
                                    />
                                }
                                content={
                                    <DocPreview uri={invoice.scan}/>
                                }
                            />
                        }
                    </>
                },
                // { content: 
                //     <SemanticIcon 
                //         disabled
                //         name="retweet"
                //         title={t('factoring')}
                //         style={{ cursor: "not-allowed", color: "var(--dark)" }}
                //     />
                // },
            ])}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    // name: t('create_document'),
                    modalSize: 'small',
                    modal: <CreateDocument connection={connection} journal={user.journal} type={type}/>,
                    isHidden: (item) => (!user.canManage)
                },
                {
                    as: 'custom',
                    type: 'custom',
                    name: t('generate_pdf'),
                    icon: "document-text-outline",
                    customIconColor: "var(--danger)",
                    onClick: (item) => generatePDF(item),
                    isHidden: (item) => (!user.canView)
                },
                {
                    as: 'modal',
                    type: 'custom',
                    name: t('create_linked_document'),
                    modalSize: 'small',
                    icon: "documents-outline",
                    modal: (item, setData) => 
                        <CreateLinkedDocument 
                            selectedInvoice={item}
                            connection={connection}
                            journal={user.journal}
                        />
                    ,
                    isHidden: (item) => (!user.canManage)
                },
                {
                    as: 'modal',
                    type: 'custom',
                    modalSize: 'large',
                    paddingLeft: 0,
                    paddingRight: 0,
                    icon: "swap-horizontal-outline",
                    name: (item) => parseFloat(item.split_ratio) < 100 ? renderTooltip(item) : t('split_invoice'),
                    customIconColor: (item) => parseFloat(item.split_ratio) < 100 ? renderColor(item) : "var(--black)",
                    modal: (item, setData) => 
                        <InvoiceDivideToCC 
                            invoice={item}
                            connection={connection}
                            setData={setData}
                        />
                    ,
                    isHidden: (item) => !user.canManage
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    modalSize: 'large',
                    modal: (item, setData) => 
                        <Invoice
                            invoice={item}
                            document={(type === "issued") ? "sent_invoices" : "recieved_invoice"}
                            connection={connection}
                            journal={user.journal}
                            setData={setData}
                            isUpdate={true}
                        />
                    ,
                    isHidden: (item) => (!user.canManage)
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('invoice_delete_message'),
                    customDelete: (item, setData, setTotal) => onDelete(item, setData, setTotal),
                    isHidden: (item) => (!user.canDelete)
                },
            ]}
            bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                { 
                    as: "modal",
                    modalSize: "small",
                    name: t('request_approval'),
                    icon: "clipboard-outline",
                    isHidden: type === "issued",
                    disabled: (selected) => selected.length === 0,
                    modal: <InvoiceApprovalForm selected={selected} setSelected={setSelected} connection={connection}/>
                },
                { 
                    as: "custom",
                    name: <ExportTo selected={selected} text="POHODA"/>,
                    onClick: () => exportData(selected, "pohoda", queryParams)
                },
                { 
                    as: "custom",
                    name: <ExportTo selected={selected} text="MKSoft"/>,
                    onClick: () => exportData(selected, "mksoft", queryParams)
                },
                { 
                    as: "custom",
                    name: <ExportTo selected={selected} text="KROS/OMEGA"/>,
                    onClick: () => exportData(selected, "kros", queryParams)
                },
                { 
                    as: "custom",
                    name: <ExportTo selected={selected} text="XLSX"/>,
                    onClick: () => exportData(selected, "xlsx", queryParams)
                },
                { 
                    as: "custom",
                    name: <ExportTo selected={selected} text="PDF - ZIP"/>,
                    onClick: () => exportData(selected, "zip_download", queryParams)
                },
            ]}
            renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                <ListStatsView
                    type={type}
                    data={data}
                    total={total}
                    params={params}
                    filters={filters}
                    setFilters={setFilters}
                    journal={user.journal}
                    connection={connection}
                    fetchData={fetchData}
                />
            )}
        />
    );
};

export default InvoiceList;