import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import moment from 'moment';
// store
import { API } from '@store/config';
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Form } from 'semantic-ui-react';

const ContractFilters = ({ filters, setFilters, employees, employers, positions }) => {
    const { t } = useTranslation()

    const [invalidDateRangeValidUntil, setInvalidDateRangeValidUntil] = useState(false)
    const [invalidDateRangeValidFrom, setInvalidDateRangeValidFrom] = useState(false)
    const [invalidDateRangeTrialPeriod, setInvalidDateRangeTrialPeriod] = useState(false)
    const [invalidDateRangeExpectedEnd, setInvalidDateRangeExpectedEnd] = useState(false)

    // Validate Form
    useEffect( () => {

        if( filters.valid_until_after !== "" && filters.valid_until_before !== ""){
            if( moment(filters.valid_until_before).isAfter(filters.valid_until_after) ){
                setInvalidDateRangeValidUntil(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeValidUntil(false)
            }
        } else {
            setInvalidDateRangeValidUntil(false)
        }

        if( filters.valid_from_before !== "" && filters.valid_from_after !== ""){
            if( moment(filters.valid_from_after).isAfter(filters.valid_from_before) ){
                setInvalidDateRangeValidFrom(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeValidFrom(false)
            }
        } else {
            setInvalidDateRangeValidFrom(false)
        }

        if( filters.trial_period_before !== "" && filters.trial_period_after !== ""){
            if( moment(filters.trial_period_after).isAfter(filters.trial_period_before) ){
                setInvalidDateRangeTrialPeriod(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeTrialPeriod(false)
            }
        } else {
            setInvalidDateRangeTrialPeriod(false)
        }

        if( filters.expected_end_date_before !== "" && filters.expected_end_date_after !== ""){
            if( moment(filters.expected_end_date_after).isAfter(filters.expected_end_date_before) ){
                setInvalidDateRangeExpectedEnd(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeExpectedEnd(false)
            }
        } else {
            setInvalidDateRangeExpectedEnd(false)
        }

        // eslint-disable-next-line
    }, [filters])

    return (
        <div>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    label={t('employee')}
                    value={filters.employee}
                    customOptions={employees.map(item => ({
                        key: item.id, value: item.id, text: item.fullname_with_titles
                    }))}
                    onChange={ (e, {value}) => setFilters({ ...filters, employee: value }) }
                    />
                <SuperField as="choice"
                    search
                    text="name"
                    label={t('employer')}
                    value={filters.employer}
                    customOptions={employers.map(item => ({
                        key: item.id, value: item.id, text: item.name
                    }))}
                    onChange={(e, { value }) => setFilters({ ...filters, employer: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={ t('contract_type') }
                    endpoint={API.CONTRACTS_TYPES}
                    text="title"
                    value={ filters.type }
                    onChange={(e, { value }) => setFilters({...filters, type: value})}
                />
                <SuperField as="choice"
                    search
                    label={ t('work_position') }
                    value={ filters.work_position }
                    customOptions={positions.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                    onChange={(e, { value }) => setFilters({...filters, work_position: value})}
                />
            </Form.Group>
            
            <SuperField as="checkbox"
                label={t('indefinite_period_only')}
                width={10}
                checked={filters.is_valid_until_none}
                onChange={() => setFilters({...filters, is_valid_until_none: filters.is_valid_until_none === "" ? true : ""})}
            />

            <CustomFormDivider
                header={t('valid_from_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={filters.valid_from_after}
                            onChange={ (e, { value }) => setFilters({...filters, valid_from_after: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRangeValidFrom}
                            value={filters.valid_from_before}
                            onChange={ (e, { value }) => setFilters({...filters, valid_from_before: value})}
                        />
                    </Form.Group>
                }
            />
            <CustomFormDivider
                header={t('valid_until_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={filters.valid_until_after}
                            onChange={ (e, { value }) => setFilters({...filters, valid_until_after: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRangeValidUntil}
                            value={filters.valid_until_before}
                            onChange={ (e, { value }) => setFilters({...filters, valid_until_before: value})}
                        />
                    </Form.Group>
                }
            />
            <CustomFormDivider
                header={t('trial_period_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={filters.trial_period_after}
                            onChange={ (e, { value }) => setFilters({...filters, trial_period_after: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRangeTrialPeriod}
                            value={filters.trial_period_before}
                            onChange={ (e, { value }) => setFilters({...filters, trial_period_before: value})}
                        />
                    </Form.Group>
                }
            />
            <CustomFormDivider
                header={t('expected_end_date_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={filters.expected_end_date_after}
                            onChange={ (e, { value }) => setFilters({...filters, expected_end_date_after: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRangeExpectedEnd}
                            value={filters.expected_end_date_before}
                            onChange={ (e, { value }) => setFilters({...filters, expected_end_date_before: value})}
                        />
                    </Form.Group>
                }
            />
        </div>
    )
}

export default ContractFilters;