import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes/index';
import { icons } from '@icons';
import { allowedModules as modules } from '@store/permissions/index';
// components
import { Grid } from "semantic-ui-react";
import HexagonLink from "@components/general/HexagonLink";
import CanView from '@components/perms/CanView';
import MainNavigation from "./MainNavigation";


function IconsView({ changeViewType }) {
    const { t } = useTranslation()

    const IconItem = ({ permissions, modules, name, to, icon, fill }) => {
        return (
            <CanView permissions={permissions} modules={modules}>
                <Grid.Column computer={3} tablet={4} mobile={8}>
                    <HexagonLink to={to} name={name} icon={icon} fill={fill} />
                </Grid.Column>
            </CanView>
        )
    }


    return (
        <div className="main fullwidth">
            <MainNavigation changeViewType={changeViewType} isFullWidth={true}/>
            <Grid container style={{ marginTop: ".5rem" }}>
                <CanView permissions={[
                    'jobs.c_view_job_positions',
                    'contracts.c_view_all_contracts',
                    'contracts.c_view_user_contracts',
                    'common.c_view_all_employees',
                    'common.c_view_user_employees',
                    'benefits.c_view_all_benefits',
                    'benefits.c_view_user_benefits',
                    'courses.c_view_all_courses',
                    'courses.c_view_user_courses',
                    'jobs.c_view_job_offers',
                    'common.c_view_all_candidates',
                    'common.c_view_user_profile',
                    'candidates.c_view_recruitment',
                    'onboarding.c_view_all_checklist_instances',
                    'attendance.c_view_all_records',
                    'attendance.c_view_user_records',
                    'company.c_manage_only_assigned_unit_employees',
                    'common.c_view_only_agency_employees',
                    'common.c_view_only_internal_employees',
                    'common.c_view_only_external_employees'
                ]}>
                    <Grid.Row>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <h2 className='h3-dashboard' style={{ marginLeft: "2.5rem", marginTop: "1rem" }}>{t('hr_long')}</h2>
                        </Grid.Column>
                    </Grid.Row>
                </CanView>
                <Grid.Row>
                    <IconItem
                        fill="var(--info)"
                        name={t('jobs_positions')}
                        icon={icons.POSITIONS}
                        to={routes.JOBS_POSITIONS}
                        modules={modules.POSITIONS}
                        permissions={['jobs.c_view_job_positions']}
                    />

                    <IconItem
                        fill="var(--primary)"
                        name={t('contracts')}
                        icon={icons.CONTRACTS}
                        to={routes.CONTRACTS}
                        modules={modules.CONTRACTS}
                        permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts', 'company.c_manage_only_assigned_unit_employees']}
                    />

                    <IconItem
                        fill="var(--iceblue)"
                        name={t('terminations')}
                        icon={icons.TERMINATIONS}
                        to={routes.TERMINATIONS}
                        modules={modules.TERMINATIONS}
                        permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts', 'company.c_manage_only_assigned_unit_employees']}
                    />

                    <IconItem
                        fill="var(--warning)"
                        name={t('employees')}
                        icon={icons.EMPLOYEES}
                        to={routes.EMPLYOEES_LIST}
                        modules={modules.EMPLOYEES}
                        permissions={['common.c_view_all_employees', 'common.c_view_user_employees', 'company.c_manage_only_assigned_unit_employees', 'common.c_view_only_agency_employees', 'common.c_view_only_internal_employees', 'common.c_view_only_external_employees']}
                    />

                    <IconItem
                        fill="var(--danger)"
                        name={t('benefits')}
                        icon={icons.BENEFITS}
                        to={routes.BENEFITS_LIST}
                        modules={modules.BENEFITS}
                        permissions={['benefits.c_view_all_benefits', 'benefits.c_view_user_benefits']}
                    />

                    <IconItem
                        fill="var(--info)"
                        name={t('courses')}
                        icon={icons.COURSES}
                        to={routes.COURSES}
                        modules={modules.COURSES}
                        permissions={['courses.c_view_all_courses', 'courses.c_view_user_courses']}
                    />

                    <IconItem
                        fill="var(--primary)"
                        name={t('jobs')}
                        icon={icons.JOBS}
                        to={routes.JOBS}
                        modules={modules.JOBS}
                        permissions={['jobs.c_view_job_offers']}
                    />

                    <IconItem
                        fill="var(--iceblue)"
                        name={t('candidates')}
                        icon={icons.CANDIDATES}
                        to={routes.CANDIDATES_LIST}
                        modules={modules.CANDIDATES}
                        permissions={['common.c_view_all_candidates', 'common.c_view_user_profile', 'company.c_manage_only_assigned_unit_employees']}
                    />

                    <IconItem
                        fill="var(--warning)"
                        name={t('hiring_rooms')}
                        icon={icons.HIRING_ROOM}
                        to={routes.HIRING}
                        modules={modules.HIRING_ROOMS}
                        permissions={['candidates.c_view_recruitment']}
                    />

                    <IconItem
                        fill="var(--danger)"
                        name={'On/Off-Boarding'}
                        icon={icons.ONBOARDING}
                        to={routes.ONBOARDING}
                        modules={modules.ONBOARDING}
                        permissions={['onboarding.c_view_all_checklist_instances']}
                    />

                    <IconItem
                        fill="var(--info)"
                        name={t('attendance')}
                        icon={icons.ATTENDANCE}
                        to={routes.ATTENDANCE}
                        modules={modules.ATTENDANCE}
                        permissions={['attendance.c_view_all_records', 'attendance.c_view_user_records', 'company.c_manage_only_assigned_unit_employees']}
                    />
                </Grid.Row>
                <CanView permissions={[
                    "projects.c_view_all_projects", "projects.c_view_user_projects",
                    "teams.c_view_all_teams", "teams.c_view_user_teams",
                    "timesheets.c_view_all_records", "timesheets.c_view_user_records",
                    "company.c_manage_only_assigned_unit_employees",
                    "tasks.c_view_tasks", "tasks.c_manage_user_tasks"
                ]}>
                    <Grid.Row>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <h2 className='h3-dashboard' style={{ marginLeft: "2.5rem", marginTop: "1rem" }}>{t('pm_long')}</h2>
                        </Grid.Column>
                    </Grid.Row>
                </CanView>

                <Grid.Row>
                    <IconItem
                        fill="var(--info)"
                        name={t('tasks')}
                        icon={icons.TASKS}
                        to={routes.TASKS}
                        modules={modules.TASKS}
                        permissions={['tasks.c_view_tasks', 'tasks.c_manage_user_tasks']}
                    />
                    <IconItem
                        fill="var(--primary)"
                        name={t('projects')}
                        icon={icons.PROJECTS}
                        to={routes.PROJECT_LIST}
                        modules={modules.PROJECTS}
                        permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']}
                    />

                    <IconItem
                        fill="var(--iceblue)"
                        name={t('timesheets')}
                        icon={icons.TIMESHEETS}
                        to={routes.TIMESHEETS}
                        modules={modules.TIMESHEETS}
                        permissions={['timesheets.c_view_all_records', 'timesheets.c_view_user_records', 'company.c_manage_only_assigned_unit_employees']}
                    />

                    <IconItem
                        fill="var(--warning)"
                        name={t('teams')}
                        icon={icons.TEAMS}
                        to={routes.TEAMS_LIST}
                        modules={modules.TEAMS}
                        permissions={['teams.c_view_all_teams', 'teams.c_view_user_teams']}
                    />
                </Grid.Row>

                <CanView permissions={[]}>
                    <Grid.Row>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <h2 className='h3-dashboard' style={{ marginLeft: "2.5rem", marginTop: "1rem" }}>{t('sm_long')}</h2>
                        </Grid.Column>
                    </Grid.Row>
                </CanView>
                <Grid.Row>
                    <IconItem
                        fill="var(--info)"
                        name={t('suppliers')}
                        icon={icons.ACCOUNTS}
                        to={routes.SUPPLIERS}
                        modules={modules.ACCOUNTS}
                        permissions={['accounts.c_view_all_accounts', 'accounts.c_view_user_accounts', 'accounts.c_view_assigned_accounts', 'common.c_view_all_employees']}
                    />

                    <IconItem
                        fill="var(--primary)"
                        name={t('accounts')}
                        icon={icons.ACCOUNTS}
                        to={routes.ACCOUNTS}
                        modules={modules.ACCOUNTS}
                        permissions={['accounts.c_view_all_accounts', 'accounts.c_view_user_accounts', 'accounts.c_view_assigned_accounts']}
                    />

                    <IconItem
                        fill="var(--iceblue)"
                        name={t('orders')}
                        icon={icons.ORDERS}
                        to={routes.ORDERS}
                        modules={modules.ORDERS}
                        permissions={['orders.c_view_order']}
                    />

                    <IconItem
                        fill="var(--warning)"
                        name={t('invoices')}
                        icon={icons.INVOICES}
                        to={routes.INVOICES}
                        modules={[]}
                        permissions={[]}
                    />

                    <IconItem
                        fill="var(--danger)"
                        name={t('payments')}
                        icon={icons.PAYMENTS}
                        to={routes.PAYMENTS}
                        modules={[]}
                        permissions={[]}
                    />

                    <IconItem
                        fill="var(--info)"
                        name={t('cost_centers')}
                        icon={icons.COST_CENTERS}
                        to={routes.COST_CENTERS}
                        modules={modules.COST_CENTERS}
                        permissions={['cost_centers.c_view_cost_centers']}
                    />
                    {/* <IconItem
                        fill="var(--warning)"
                        name={t('contacts')}
                        icon={icons.CONTACTS}
                        to={routes.CONTACTS}
                        modules={modules.CONTRACTS}
                        permissions={['contacts.c_view_contacts']}
                    /> */}
                </Grid.Row>

                {(
                    window.location.hostname === "renviro.hriis.io" || 
                    window.location.hostname === "renviro.pulsawork.com" || 
                    window.location.hostname === "parkogroup.pulsawork.com" ||
                    window.location.hostname === "demo.hriis.io" || 
                    window.location.hostname === "demo.pulsawork.com" ||
                    window.location.hostname === "sodonbos.pulsawork.com" ||
                    window.location.hostname === "dev-hriis.sodonbos.com" ||
                    window.location.hostname === "localhost"
                    ) &&
                        <>
                            <CanView permissions={['orders.c_view_offer']}>
                                <Grid.Row>
                                    <Grid.Column computer={16} tablet={16} mobile={16}>
                                        <h2 className='h3-dashboard' style={{ marginLeft: "2.5rem", marginTop: "1rem" }}>{t('sales_long')}</h2>
                                    </Grid.Column>
                                </Grid.Row>
                            </CanView>
                            
                            <Grid.Row>
                                <IconItem
                                    fill="var(--info)"
                                    name={t('leads')}
                                    icon={icons.ORDERS}
                                    to={routes.LEADS}
                                    modules={modules.LEADS}
                                    permissions={['orders.c_view_offer']}
                                />
                                <IconItem
                                    fill="var(--primary)"
                                    name={t('opportunities')}
                                    icon={icons.ORDERS}
                                    to={routes.OPPORTUNITIES}
                                    modules={modules.LEADS}
                                    permissions={['orders.c_view_offer']}
                                />
                            </Grid.Row>
                        </>
                }

                <CanView permissions={[
                    'approvals.c_view_all_approvals',
                    'approvals.c_view_user_approvals',
                    'cases.c_view_all_cases',
                    'cases.c_view_assigned_user_cases',
                    'assets.c_view_items',
                    'assets.c_view_warehouses',
                    'assets.c_view_item_categories',
                    'company.c_view_active_company',
                    'forecasts.c_view_forecasts',
                    'vehicles.c_view_all_vehicles',
                    'vehicles.c_view_assigned_vehicles',
                    'reports.c_view_employees_reports',
                    'reports.c_view_recruitments_reports',
                    'reports.c_view_attendance_reports',
                    'logistics.c_view_all_transports',
                    'logistics.c_view_user_transports',
                    'accommodations.c_view_accommodation'
                ]}>
                    <Grid.Row>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <h2 className='h3-dashboard' style={{ marginLeft: "2.5rem", marginTop: "1rem" }}>{t('misc_long')}</h2>
                        </Grid.Column>
                    </Grid.Row>
                </CanView>
                <Grid.Row>
                    <IconItem
                        fill="var(--info)"
                        name={t('approvals')}
                        icon={icons.APPROVALS}
                        to={routes.APPROVALS}
                        modules={modules.APPROVALS}
                        permissions={['approvals.c_view_all_approvals', 'approvals.c_view_user_approvals']}
                    />

            { (
                window.location.hostname === "ascensio.hriis.io" || 
                window.location.hostname === "ascensio.pulsawork.com" || 
                window.location.hostname === "shefaragency.pulsawork.com" ||
                window.location.hostname === "msmprofi.pulsawork.com" ||
                window.location.hostname === "parkogroup.pulsawork.com" || 
                window.location.hostname === "demo.hriis.io" || 
                window.location.hostname === "demo.pulsawork.com" ||
                window.location.hostname === "sodonbos.pulsawork.com" ||
                window.location.hostname === "dev-hriis.sodonbos.com" ||
                window.location.hostname === "localhost"
                ) &&
                    <IconItem
                        fill="var(--primary)"
                        name={t('accommodation')}
                        icon={icons.ACCOMMODATIONS}
                        to={routes.ACCOMMODATIONS}
                        modules={modules.ACCOMMODATIONS}
                        permissions={['accommodations.c_view_accommodation']}
                    />
            }

                    <IconItem
                        fill="var(--iceblue)"
                        name={t('cases')}
                        icon={icons.CASES}
                        to={routes.CASES}
                        modules={modules.CASES}
                        permissions={['cases.c_view_all_cases', 'cases.c_view_assigned_user_cases']}
                    />

                    <IconItem
                        fill="var(--warning)"
                        name={t('vehicles')}
                        icon={icons.VEHICLES}
                        to={routes.VEHICLES}
                        modules={modules.VEHICLES}
                        permissions={['vehicles.c_view_all_vehicles', 'vehicles.c_view_assigned_vehicles']}
                    />

                    <IconItem
                        fill="var(--danger)"
                        name={t('logistics')}
                        icon={icons.LOGISTICS}
                        to={routes.LOGISTICS}
                        modules={modules.LOGISTICS}
                        permissions={['logistics.c_view_all_transports', 'logistics.c_view_user_transports']}
                    />

                    <IconItem
                        fill="var(--info)"
                        name={t('forecasts')}
                        icon={icons.FORECASTS}
                        to={routes.FORECASTS}
                        modules={modules.FORECASTS}
                        permissions={['forecasts.c_view_forecasts']}
                    />

                    <IconItem
                        fill="var(--primary)"
                        name={t('reports')}
                        icon={icons.REPORTS}
                        to={routes.REPORTS}
                        modules={modules.REPORTS}
                        permissions={['reports.c_view_employees_reports', 'reports.c_view_recruitments_reports', 'reports.c_view_attendance_reports']}
                    />

                    <IconItem
                        fill="var(--iceblue)"
                        name={t('assets')}
                        icon={icons.ASSETS}
                        to={routes.WAREHOUSING}
                        modules={modules.WAREHOUSING}
                        permissions={['assets.c_view_items', 'assets.c_view_warehouses']}
                    />

                    <IconItem
                        fill="var(--warning)"
                        name={t('assets')}
                        icon={icons.ASSETS}
                        to={routes.ASSETS}
                        modules={modules.ASSETS}
                        permissions={['assets.c_view_items', 'assets.c_view_warehouses', 'assets.c_view_item_categories']}
                    />

                    <IconItem
                        fill="var(--danger)"
                        name={t('company')}
                        icon={icons.COMPANY}
                        to={routes.COMPANY}
                        modules={modules.COMPANY}
                        permissions={['company.c_view_active_company']}
                    />

                    <IconItem
                        fill="var(--info)"
                        name={t('gdpr_agreements')}
                        icon={icons.GDPR_AGREEMENTS}
                        to={routes.GDPR_AGREEMENTS}
                        modules={modules.GDPR_AGREEMENTS}
                        permissions={['common.c_view_gdpr_agreements']}
                    />

                    <IconItem
                        fill="var(--primary)"
                        name={t('settings')}
                        icon={icons.SETTINGS}
                        to={routes.SETTINGS}
                        modules={modules.SETTINGS}
                        permissions={[]}
                    />      
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default IconsView;