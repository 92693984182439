import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// store
import { icons } from '@icons';
import { routes } from '@routes';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import { Button, Container, Divider, Grid } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import Action from '@components/general/Action';
// module specific components
import MyAttendance from './views/MyAttendance';
import ManageEmployee from './views/ManageEmployee';
import ImportAttendance from './components/ImportAttendance';
import AttendancePlanner from './views/AttendancePlanner';
import DayOverview from './views/DayOverview';

const Attendance = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [view, setView] = useState(() => {
        let current_view = 1
        if (window.location.pathname === routes.ATTENDANCE + "planner") current_view = 5
        if (window.location.pathname === routes.ATTENDANCE + "employee") current_view = 2
        if (window.location.pathname === routes.ATTENDANCE + "groups") current_view = 3
        if (window.location.pathname === routes.ATTENDANCE + "day") current_view = 4

        return current_view
    })

    const setViewType = (to, view) => {
        setView(view)
        history.push(to)
    }

    return (
        <CanView permissions={['attendance.c_view_all_records', 'attendance.c_view_user_records', 'company.c_manage_only_assigned_unit_employees']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("attendance"), 'icon': icons.ATTENDANCE, 'href': '' }
                ]}
            />
            <Container fluid>
                <Grid stackable>
                    <Grid.Row style={{ padding: 0 }} columns={2}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Button.Group basic size="small" style={{ borderRadius: 0 }}>
                                <Button active={view === 1} style={{ fontWeight: view === 1 ? 'bold' : 'normal' }} content={t('my_attendance')} onClick={() => setViewType(routes.ATTENDANCE, 1)}/>
                                <CanView permissions={['attendance.c_manage_all_records', 'company.c_manage_only_assigned_unit_employees']}>
                                    <Button active={view === 4} style={{ fontWeight: view === 4 ? 'bold' : 'normal' }} content={t('day_overview')} onClick={() => setViewType(routes.ATTENDANCE + "day", 4)}/>
                                    <Button active={view === 2} style={{ fontWeight: view === 2 ? 'bold' : 'normal' }} content={t('manage_employee')} onClick={() => setViewType(routes.ATTENDANCE + "employee", 2)}/>
                                    <Button active={view === 5} style={{ fontWeight: view === 5 ? 'bold' : 'normal' }} content={t('attendance_planner')} onClick={() => setViewType(routes.ATTENDANCE + "planner", 5)}/>
                                </CanView>
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: 'right', padding: 0 }}>
                            <CanView permissions={['attendance.c_manage_all_records', 'company.c_manage_only_assigned_unit_employees']}>
                                <Action as='modal' type='button' primary
                                    header={t('import_attendance')}
                                    buttonText={
                                        <>
                                            <Icon name="cloud-upload-outline" style={{ marginRight: "0.5rem" }}/> 
                                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                                {t('import_attendance')}
                                            </span>
                                        </>
                                    }
                                    modal={
                                        <ImportAttendance/>
                                    }
                                />
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>
                <Switch>
                    <Route exact path={routes.ATTENDANCE} component={MyAttendance}/>
                    <CanView permissions={['attendance.c_manage_all_records', 'company.c_manage_only_assigned_unit_employees']}>
                        <Route exact path={routes.ATTENDANCE + "planner"} component={AttendancePlanner}/>
                        <Route exact path={routes.ATTENDANCE + "employee"} component={ManageEmployee}/>
                        <Route exact path={routes.ATTENDANCE + "day"} component={DayOverview}/>
                    </CanView>
                </Switch>
            </Container>
        </CanView>
    );
};

export default Attendance;