import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import { Divider } from 'semantic-ui-react';
import LabelsList from '@components/lists/LabelsList';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ProfilePreview from '@components/common/profile-card/ProfilePreview';
// import SkillSeniorityFilterField from '@components/forms/SkillSeniorityFilterField';
// specific components
import EmployeeForm from '../components/forms/EmployeeForm';
import EmployeeFilters from '../components/EmployeeFilters';
import GenerateBirthDayList from '../components/GenerateBirthDayList';
// import StartOnOff from '../../onboarding/components/forms/StartOnOff';
import Notes from '@components/Notes';
import Skills from '@components/Skills';
import UnitChange from '../components/UnitChange';

const EmployeesList = () => {
    const { t } = useTranslation();
    const [employeesStats, setEmployeesStats] = useState({});
    const [forceModalOpen, setForceModalOpen] = useState(0);

    const switchToCandidate = useSelector(state => state.switch_to_candidate)
    const allowedEmploymentOrder = useSelector(state => state.allowed_order_types?.employment_order)


    useEffect(() => {
        async function fetchEmployeesStats() {
            const request = await requests.get(API.EMPLOYEES_TOTAL_STATS)
            if (request.status === 200) {
                setEmployeesStats(request.response)
            }
        }

        fetchEmployeesStats()
    }, [])

    const onDelete = async (item, setData, setTotal) => {
        let removed = false
        if (switchToCandidate) {
            const request = await requests.post(API.EMPLOYEES + `switch_to_candidate/?id=${item.id}`)
            if (request.status === 200) {
                removed = true

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: "",
                    description: t('successfully_moved_to_candidates'),
                    animation: 'bounce',
                    time: 3000,
                });
            } else if (request.status === 400) {
                toast({
                    type: 'error',
                    icon: 'warning circle',
                    // title: 'error',
                    description: request.response?.[0] || request.response?.non_field_errors?.[0] || request.response?.detail || "--",
                    animation: 'bounce',
                    time: 5000,
                })
            }
        } else {
            const request = await requests.post(API.EMPLOYEES + item.id + '/delete/', {});
            if (request.status === 201 || request.status === 200) {
                removed = true
            }
        }

        if (removed) {
            setData(prev => prev.filter(emp => emp.id !== item.id))
            setTotal(prev => prev - 1)
        }
    }

    const displayEmployeeType = (employee) => {
        let employeeTypes = []
        if (employee.business_detail) employeeTypes.push(t('self_employed'))
        if (employee.is_external === true) employeeTypes.push(t('external'))
        if (employee.is_employed_by_agencies) employeeTypes.push(t('agency_employees'))
        if (employee.is_external === false && employee.is_employed_by_agencies !== true) employeeTypes.push(t('internal'))

        return employeeTypes.map(item => item).join(', ')
    }

    const displayFullNameWithTitles = (employee) => {
        let titles_before = employee?.titles_before || ""
        let titles_after = employee?.titles_after || ""
        let fullname = employee.fullname

        let fullnameWithTitles = `${titles_before || ""} ${fullname} ${titles_after || ""}`

        return fullnameWithTitles.trim()
    }

    return (
        <CanView permissions={["common.c_view_all_employees", "common.c_view_user_employees", "company.c_manage_only_assigned_unit_employees", "common.c_view_only_agency_employees", "common.c_view_only_internal_employees", "common.c_view_only_external_employees"]} redirect>
            <ListView
                as="table"
                allowSearch
                allowSelection
                allowRowExport
                endpoint={API.EMPLOYEES}
                actionsCellWidth="2"
                allowBulkNonSelected={true}
                query={"&query={id, uuid, positions, skills, unit, is_active, is_external, is_employed_by_agencies, fullname, profile_picture, contact_labels, business_detail, custom_id, contacts, notes, active_contracts}"}
                exports={[
                    {
                        endpoint: API.EXPORTS + "dynamic_employees/",
                        permissions: ['common.c_view_all_employees', 'company.c_manage_only_assigned_unit_employees', 'common.c_view_only_agency_employees', 'common.c_view_only_internal_employees', 'common.c_view_only_external_employees'],
                        apply_filters: true,
                        type: "xlsx",
                        filename: "employees"
                    },
                ]}
                // imports={() => routes.IMPORTS + "?type=employees&scheme=import-employees"}
                initialFilters={{
                    gender: "",
                    country: "",
                    is_external: "",
                    is_freelancer: "",
                    education: "",
                    position: "",
                    unit: "",
                    city: "",
                    state: "",
                    experience: "",
                    not_assigned_unit: "",
                    show_duplicates: "",
                    have_active_contract: "",
                    has_multiple_active_contracts: "",
                    // isExact: false,
                    // skills: [],
                    contact_labels: [],
                    language: "",
                    language_evel: "",
                    employment_order_active_only: "",
                    skill_and_seniority_conjoined: "",
                    skill_and_seniority: ""
                }}
                tableHeaders={[
                    { title: t("fullname"), orderBy: "lastname" },
                    { title: t("employer") },
                    { title: t("department"), orderBy: "unit__name" },
                    { title: t("contact") },
                    { title: t("labels") },
                    { title: t("skills") },
                    { title: t("notes") },
                    { title: t("status"), orderBy: "status" },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <EmployeeFilters
                        filters={filters}
                        setFilters={setFilters}
                        allowedEmploymentOrder={allowedEmploymentOrder}
                    />
                )}
                listAdditionActions={(data, setData, total, setTotal, response) => ([
                    {
                        as: "filter",
                        index: 0,
                        name: t('all'),
                        filters: {
                            is_external: "",
                            is_freelancer: "",
                            is_employed_by_agencies: "",
                            is_foreigner: ""
                        },
                        count: employeesStats?.total_count || 0
                    },
                    {
                        as: "filter",
                        index: 1,
                        name: t('internal'),
                        filters: {
                            is_external: false,
                            is_freelancer: "",
                            is_employed_by_agencies: "",
                            is_foreigner: ""
                        },
                        count: employeesStats?.internal_count || 0
                    },
                    {
                        as: "filter",
                        index: 2,
                        name: t('external'),
                        filters: {
                            is_external: true,
                            is_freelancer: "",
                            is_employed_by_agencies: "",
                            is_foreigner: ""
                        },
                        count: employeesStats?.external_count || 0
                    },
                    {
                        as: "filter",
                        index: 3,
                        name: t('agency_employees'),
                        filters: {
                            is_employed_by_agencies: true,
                            is_external: "",
                            is_freelancer: "",
                            is_foreigner: ""
                        },
                        count: employeesStats?.agency_count || 0
                    },
                    {
                        as: "filter",
                        index: 4,
                        name: t('self_employed'),
                        filters: {
                            is_freelancer: true,
                            is_external: "",
                            is_employed_by_agencies: "",
                            is_foreigner: ""
                        },
                        count: employeesStats?.freelancer_count || 0
                    },
                    
                    {
                        as: "filter",
                        index: 5,
                        name: t('foreigner_employees'),
                        filters: {
                            is_foreigner: true,
                            is_external: "",
                            is_freelancer: "",
                            is_employed_by_agencies: ""
                        },
                        count: employeesStats?.foreigner_count || 0
                    },
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        name: t('create_employee'),
                        modal: <EmployeeForm />,
                        permissions: ['common.c_manage_all_employees', 'common.c_manage_user_employees', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']
                    },
                    {  
                        as: "modal",
                        type: "custom",
                        icon: "eye-outline",
                        name: t('view_preview'),
                        modalSize: "tiny",
                        modal: <ProfilePreview />,
                        permissions: ['common.c_view_all_employees', 'common.c_view_user_employees', 'company.c_manage_only_assigned_unit_employees', 'common.c_view_only_agency_employees', 'common.c_view_only_internal_employees', 'common.c_view_only_external_employees']
                    },
                    {
                        as: "link",
                        type: "detail",
                        target: "_blank",
                        icon: "id-card-outline",
                        name: t('view_card'),
                        redirect: (item) => routes.EMPLYOEES_DETAIL + item.id,
                        permissions: ['common.c_view_all_employees', 'common.c_view_user_employees', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']
                    },
                    // {
                    //     name: t('start_on_off_boarding'),
                    //     type: "custom", as: "link",
                    //     icon: "barcode-outline",
                    //     customIconColor: "var(--dark)",
                    //     redirect: (item) => routes.EMPLYOEES_DETAIL + item.id,
                    //     permissions: ['common.c_delete_all_employees']
                    // },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: switchToCandidate ? t('move_to_candidate_employee_message') : t('delete_employee_message'),
                        permissions: ['common.c_delete_all_employees', 'common.c_manage_all_employees', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees'],
                        customDelete: (item, setData, setTotal) => onDelete(item, setData, setTotal)

                    },
                ]}
                renderCells={(employee, setData) => ([
                    {   width: 2,
                        content:
                            <>
                                <strong style={{ fontSize: "1rem" }}>
                                    { displayFullNameWithTitles(employee) }
                                </strong><br />
                                { displayEmployeeType(employee) }
                                { employee.active_contracts.length > 0 ? " - " : "" }
                                { employee.active_contracts.map((contract, index) => (
                                    employee.active_contracts.length === index + 1 
                                        ? <span>{ contract.work_position_title }</span>
                                        : <span>{ contract.work_position_title } {", "}</span>
                                    
                                )) }
                            </>
                    },
                    {
                        content: 
                        <>
                            { employee.active_contracts.length === 0 && 
                                <strong style={{ fontSize: "0.9rem", color: "var(--danger)" }}>{ t('no_active_contract') }</strong>
                            }
                            { employee.active_contracts.map(contract => (
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <strong style={{ fontSize: "0.9rem" }}>{ contract.employer_name }</strong> <br/>
                                        <div style={{ marginLeft: "1rem" }}>
                                            <Action as="modal"
                                                tooltip={t('contract_notes') + " - " + contract.employer_name}
                                                header={t('contract_notes')}
                                                open={contract.id + "_contract" === forceModalOpen}
                                                onClose={() => setForceModalOpen(0)}
                                                trigger={
                                                    <span style={{ fontSize: "1.1rem", cursor: "pointer" }} onClick={() => setForceModalOpen(contract.id + "_contract")}>
                                                        <Icon name="document-text-outline" /> <sup style={{ padding: "0.2rem", fontWeight: "bold", opacity: "0.6" }}>{contract.notes.length}</sup>
                                                    </span>
                                                }
                                                modal={
                                                    <Notes 
                                                        model="employee"
                                                        notes={contract.notes}
                                                        endpoint={API.CONTRACTS}
                                                        id={contract.id}
                                                        canManage={['contracts.c_manage_all_contracts']}
                                                        canDelete={['contracts.c_manage_all_contracts']}
                                                        onResponse={(response) => { 
                                                            setData(prev => prev.filter(item => {
                                                                if (item.id === employee.id) {
                                                                    item.active_contracts = item.active_contracts.filter(activeContract => {
                                                                        if (activeContract.id === contract.id) {
                                                                            activeContract.notes = response.notes
                                                                        }

                                                                        return activeContract
                                                                    })
                                                                }

                                                                return item
                                                            }))
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <small>{contract.employer_identification_number}</small>
                                </div>
                            )) }
                        </>
                    },
                    { content: employee?.unit?.name || t('') },
                    {
                        content:
                        <div>
                                { employee.contacts.length === 0 
                                    ? <strong style={{ color: "var(--danger)" }}> { t('no_active_contact') } </strong>
                                    : 
                                    <>
                                        { employee.contacts[0]?.contact_person_name ? <strong>{ employee.contacts[0]?.contact_person_name } <br/></strong> : "" }
                                        { employee.contacts[0]?.phone ? <><a href={"callto:" + employee.contacts[0]?.phone}> <Icon name="call-outline" style={{ marginRight: "0.2rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ employee.contacts[0]?.phone }</span> </a> <br/></> : "" }
                                        { employee.contacts[0]?.email ? <><a href={"mailto:" + employee.contacts[0]?.email}> <Icon name="mail-outline" style={{ marginRight: "0.2rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ employee.contacts[0]?.email }</span> </a> </> : "" }
                                        { employee.contacts.length > 1 && 
                                            <div>
                                                <SuperDuperModal 
                                                    size="tiny"
                                                    content={
                                                        <div>
                                                            {employee.contacts.map((contact, idx) => (
                                                                <div key={idx}>
                                                                    <div style={{ display: "flex", flexDirection: "row", itemsAlign: "start", justifyContent: "space-between" }}>
                                                                        <div>
                                                                            { contact.contact_person_name !== "" && <strong>{ contact.contact_person_name } <br/></strong> } 
                                                                            { contact.phone !== "" ? <><a href={"callto:" + contact.phone}> <Icon name="call-outline" style={{ marginRight: "0.2rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ contact.phone }</span> </a> <br/></> : ""}
                                                                            { contact?.email !== "" ? <><a href={"mailto:" + contact.email}> <Icon name="mail-outline" style={{ marginRight: "0.2rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ contact.email }</span> </a> </> : ""}
                                                                            { contact?.is_invoicing && 
                                                                                <div style={{ opacity: 0.8, fontSize: "0.9rem", fontWeight: "bold", marginTop: "0.2rem" }}> 
                                                                                    {t('used_for_invoicing')} 
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div style={{ textAlign: "right" }}>
                                                                            <span style={{ fontWeight: "bold", opacity: 0.5, marginRight: "1rem", position: "relative", top: "-0.1rem" }}>#{idx + 1}.</span>
                                                                        </div>
                                                                    </div>
                                                                    <Divider/>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                    trigger={
                                                        <span className="ref-link" style={{ color: "var(--primary)" }}>
                                                            {t('show_more_contacts')}
                                                        </span> 
                                                    } 
                                                />
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                    },
                    // { content: <SkillsTooltip skills={employee.skills} /> },
                    {
                        width: 3, content:
                            <>
                                <LabelsList
                                    rowID={employee.id}
                                    labels={employee?.contact_labels}
                                    endpoint={API.EMPLOYEES}
                                    label_key="contact_labels"
                                />
                            </>
                    },
                    {
                        content:
                            <Action as="modal"
                                modalSize="tiny"
                                tooltip={t('skills')}
                                header={t('skills')}
                                trigger={
                                    <span style={{ fontSize: "1.2rem", cursor: "pointer" }}>
                                        <Icon name="library-outline" /> <sup style={{ padding: "0.2rem", fontWeight: "bold", opacity: "0.6" }}>{employee.skills.length}</sup>
                                    </span>
                                }
                                modal={
                                    <Skills skills={employee.skills} />
                                }
                            />
                    },
                    {
                        content:
                            <Action as="modal"
                                tooltip={t('employee_notes')}
                                header={t('employee_notes')}
                                open={employee.id === forceModalOpen}
                                onClose={() => setForceModalOpen(0)}
                                trigger={
                                    <span style={{ fontSize: "1.2rem", cursor: "pointer" }} onClick={() => setForceModalOpen(employee.id)}>
                                        <Icon name="document-text-outline" /> <sup style={{ padding: "0.2rem", fontWeight: "bold", opacity: "0.6" }}>{employee.notes.length}</sup>
                                    </span>
                                }
                                modal={
                                    <Notes
                                        notes={employee.notes}
                                        endpoint={API.EMPLOYEES}
                                        id={employee.id}
                                        setData={setData}
                                    />
                                }
                            />
                    },
                    {
                        content:
                            <div style={{ display: "flex" }}>
                                <Icon name='ellipse' style={{ color: employee.is_active ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }} />
                                <span style={{ position: "relative", top: "-0.3rem" }}> {employee.is_active ? t('active_him') : t('inactive_him')} </span>
                            </div>
                    },
                ])}
                bulkActions={(selected, setData) => [
                    {
                        as: "modal",
                        modalSize: "small",
                        name: t('generate_birthday_list'),
                        icon: "document-text-outline",
                        modal: <GenerateBirthDayList />
                    },
                    {
                        as: "modal",
                        modalSize: "small",
                        name: t('change_unit'),
                        icon: "document-text-outline",
                        modal: <UnitChange selected={selected} setData={setData} />
                    }
                ]}
            />
        </CanView>
    );
};

export default EmployeesList;