import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '@store/languages';
import moment from 'moment';
// store
import { API } from '@store/config';
import { dates, setLocaleLanguage } from '@helpers/dates';
import { requests } from '@helpers/requests';
import { alertDialog } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Grid, Container, Button, Header, Divider } from 'semantic-ui-react';
import InterruptionReasonsSelectList from './InterruptionReasonsSelectList';
import ChooseWorkingContract from './ChooseWorkingContract';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const Timer = ({ setMode, setEmployee }) => {
    const { t } = useTranslation()
    const [seconds, setSeconds] = useState(30)
    const [displayText, setDisplayText] = useState(t('tracker_seconds'))

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prev => {
                let result = prev - 1
                if ((result) === 0) {
                    setMode(1)
                    setEmployee(null)
                    clearInterval(interval)
                }

                if (result === 1) {
                    setDisplayText(t('tracker_second'))
                }
                if (result > 1 && result < 5 ) {
                    setDisplayText(t('tracker_seconds_1_to_5'))
                }

                return result
            })
        }, 1000)

        return () => clearInterval(interval);
    })

    return (
        <>
            { seconds } {displayText} { t('x_seconds_left_to_select_action') }
        </>
    )
}

const AttendanceSelfManagement = ({ setMode, employee, setEmployee, interruptions }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)
    const today = employee?.record?.date ? moment(employee?.record?.date).format(dateFormat) : moment().format(dateFormat)
    const endActivityDisplayText = t('end_activity_display_text')
    const cancelActionDisplayText = t('cancel_action_display_text')
    const [processingType, setProcessingType] = useState()

    const [contract, setContract] = useState(employee?.record?.contract || null)

    const start = employee?.record?.start ? moment(dates.convertUTCDateToLocalDate(employee?.record?.start)).format(`${dateFormat} HH:mm`) : ""
    const end = employee?.record?.end ? moment(dates.convertUTCDateToLocalDate(employee?.record?.end)).format(`${dateFormat} HH:mm`) : ""
    const start_pause = employee?.record?.start_pause ? moment(dates.convertUTCDateToLocalDate(employee?.record?.start_pause)).format(`${dateFormat} HH:mm`) : ""
    const end_pause = employee?.record?.end_pause ? moment(dates.convertUTCDateToLocalDate(employee?.record?.end_pause)).format(`${dateFormat} HH:mm`) : ""
    const start_second_pause = employee?.record?.second_start_pause ? moment(dates.convertUTCDateToLocalDate(employee?.record?.second_start_pause)).format(`${dateFormat} HH:mm`) : ""
    const end_second_pause = employee?.record?.second_end_pause ? moment(dates.convertUTCDateToLocalDate(employee?.record?.second_end_pause)).format(`${dateFormat} HH:mm`) : ""
    const shift = employee?.record?.shift || null

    // const activeInterruption = employee?.record?.interruptions?.filter(interruption => interruption.end === null)?.length > 0

    useEffect(() => {
        if (employee.contracts.length === 1 && (employee?.record?.contract !== null || employee?.record?.contract !== undefined)) {
            setContract(employee.contracts[0])
        }
        // eslint-disable-next-line
    }, [])

    const onReturn = () => {
        setEmployee(null)
        setMode(1)
    }

    const handleRestore = async (type, response) => {
        if (type === "start"){
            const request = await requests.del(API.ATTENDANCE + response.id + "/")

            if (request.status === 204) {
                setEmployee(prev => ({
                    ...prev,
                    record: null
                }))
            }
        } else {
            const data = {}
            data['contract'] = response.contract?.id
            data['employee'] = response.employee?.id
            if (type === "end"){
                data['end'] = null
                data['closed'] = false
            } else if (type === "pause"){
                if (response?.end_pause === null && response?.start_pause !== null ) {
                    data['start_pause'] = null
                } 
                
                if (response?.start_pause !== null && response?.end_pause !== null) {
                    data['end_pause'] = null
                }
            } else if (type === "second_pause"){
                if (response?.second_end_pause === null && response?.second_start_pause !== null ) {
                    data['second_start_pause'] = null
                } 
                
                if (response?.second_start_pause !== null && response?.second_end_pause !== null) {
                    data['second_end_pause'] = null
                }
            }

            const request = await requests.patch(API.ATTENDANCE + response.id + "/close_reset/", data)
            if (request.status === 200) {
                setEmployee(prev => ({
                    ...prev,
                    record: request.response
                }))
            }
        }
    }

    const handleAction = async (type, contract) => {
        contract = contract || null
        setProcessingType(type)
        if (type === "start") {
            let startData = {
                employee: employee.id,
            }

            if (contract !== null) {
                startData['contract'] = contract.id
            }

            const request = await requests.post(API.ATTENDANCE, startData)

            if (request.status === 400) {
                let dialogCountDownTimer = 5
                let timerInterval
                MySwal.fire({
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: t('no_active_contract_found') || "",
                    showConfirmButton: false,
                    html: '<p style="font-size: 1.5rem; font-weight: bold;"></p>' + endActivityDisplayText + ' <strong></strong>...',
                    timer: dialogCountDownTimer * 1000,
                    timerProgressBar: true,
                    didOpen: () => {
                        MySwal.hideLoading()
                        const b = MySwal.getHtmlContainer().querySelector('strong')
                        timerInterval = setInterval(() => {
                            b.textContent = Math.ceil(MySwal.getTimerLeft() / 1000)
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    },
                }).then((result) => {
                    if (result.dismiss === MySwal.DismissReason.timer) {
                        onReturn()
                    }
                })
            }
            if ([200, 201].includes(request.status)) {
                const response = request.response
                const date = dates.convertUTCDateToLocalDate(response.start)

                setEmployee(prev => ({
                    ...prev,
                    record: response
                }))

                alertDialog(t('tracker_start_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(type, response), endActivityDisplayText, cancelActionDisplayText, 0.7)
            }
        }

        if (type === "pause") {
            let isStart = false
            if (employee?.record?.start_pause === null) isStart = true

            if (isStart) {
                const request = await requests.patch(API.ATTENDANCE + employee.record.id + "/", {
                    employee: employee.id,
                    start_pause: moment.utc().format("YYYY-MM-DD HH:mm:ss")
                })
    
                if ([200, 201].includes(request.status)) {
                    const response = request.response
                    const date = dates.convertUTCDateToLocalDate(response.start_pause)
    
                    setEmployee(prev => ({
                        ...prev,
                        record: response
                    }))
    
                    alertDialog(t('tracker_pause_start_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(type, response), endActivityDisplayText, cancelActionDisplayText)
                }
            } else {
                const request = await requests.patch(API.ATTENDANCE + employee.record.id + "/", {
                    employee: employee.id,
                    end_pause: moment.utc().format("YYYY-MM-DD HH:mm:ss")
                })
    
                if ([200, 201].includes(request.status)) {
                    const response = request.response
                    const date = dates.convertUTCDateToLocalDate(response.end_pause)
    
                    setEmployee(prev => ({
                        ...prev,
                        record: response
                    }))
    
                    alertDialog(t('tracker_pause_end_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(type, response), endActivityDisplayText, cancelActionDisplayText)
                }
            }
        }

        if (type === "second_pause") {
            let isStart = false
            if (employee?.record?.second_start_pause === null) isStart = true

            if (isStart) {
                const request = await requests.patch(API.ATTENDANCE + employee.record.id + "/", {
                    employee: employee.id,
                    second_start_pause: moment.utc().format("YYYY-MM-DD HH:mm:ss")
                })
    
                if ([200, 201].includes(request.status)) {
                    const response = request.response
                    const date = dates.convertUTCDateToLocalDate(response.second_start_pause)
    
                    setEmployee(prev => ({
                        ...prev,
                        record: response
                    }))
    
                    alertDialog(t('tracker_pause_start_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(type, response), endActivityDisplayText, cancelActionDisplayText)
                }
            } else {
                const request = await requests.patch(API.ATTENDANCE + employee.record.id + "/", {
                    employee: employee.id,
                    second_end_pause: moment.utc().format("YYYY-MM-DD HH:mm:ss")
                })
    
                if ([200, 201].includes(request.status)) {
                    const response = request.response
                    const date = dates.convertUTCDateToLocalDate(response.second_end_pause)
    
                    setEmployee(prev => ({
                        ...prev,
                        record: response
                    }))
    
                    alertDialog(t('tracker_pause_end_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(type, response), endActivityDisplayText, cancelActionDisplayText)
                }
            }
        }

        if (type === "end") {
            let additional = {}
            if (employee.record?.start_pause !== null && employee.record?.end_pause === null) {
                additional['end_pause'] = moment.utc().format("YYYY-MM-DD HH:mm:ss")
            }

            const request = await requests.patch(API.ATTENDANCE + employee.record.id + "/", {
                employee: employee.id,
                end: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                ...additional
            })

            if ([200, 201].includes(request.status)) {
                const response = request.response
                const date = dates.convertUTCDateToLocalDate(response.end)

                setEmployee(prev => ({
                    ...prev,
                    record: response
                }))

                alertDialog(t('tracker_end_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(type, response), endActivityDisplayText, cancelActionDisplayText, 0.7)
            }
        }

        setProcessingType(null)
    }

    const handleInterruptionRestore = async (response) => {
        const request = await requests.patch(API.ATTENDANCE_INTERRUPTIONS + response.id + "/", {
            end: null
        })

        if (request.status === 200) {
            const fetchAttendance = await requests.patch(API.ATTENDANCE + employee.record.id + "/", {
                end: response.start
            })
            if (fetchAttendance.status === 200) {
                setEmployee(prev => ({ 
                    ...prev, 
                    record: fetchAttendance.response
                }))
            }
        }
    }

    const handleInterruptionStop = async () => {
        setProcessingType("interruption")
        const activeInterruption = employee?.record?.interruptions.find(item => item.end === null)

        if (activeInterruption) {
            let end = moment.utc().format("YYYY-MM-DD HH:mm:ss")
            const date = dates.convertUTCDateToLocalDate(end)
            const request = await requests.patch(API.ATTENDANCE_INTERRUPTIONS + activeInterruption.id + "/", {
                end: end
            })

            if (request.status === 200) {
                if (request.status === 200) {
                    const fetchAttendance = await requests.get(API.ATTENDANCE + employee.record.id + "/")
                    if (fetchAttendance.status === 200) {
                        setEmployee(prev => ({ 
                            ...prev, 
                            record: fetchAttendance.response
                        }))
                    }
                    alertDialog(t('tracker_interruption_end_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleInterruptionRestore(request.response), endActivityDisplayText, cancelActionDisplayText)
                }
            }
        }
        setProcessingType("")
    }

    const displayWorkplace = (contract) => {
        let workplace = ""
        if (contract !== null) {
            if (contract.workplace_address !== null) {
                workplace = `${contract.workplace_address.city}, ${contract.workplace_address.country_display}`
            } else {
                if (contract.employer?.billing_address !== null) {
                    workplace = `${contract.employer?.billing_address?.city || ""}, ${contract.employer?.billing_address?.country_display || ""}`
                }
            }
        }

        return workplace
    }

    return (
        <Container style={{ marginTop: "1rem" }}>
            <Header as="h1" content={t('attendance_tracker')} textAlign="center"/>
            <div style={{ 
                color: "var(--danger)",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginBottom: "2rem"
            }}>
                <Timer setMode={setMode} setEmployee={setEmployee}/>
            </div>
            <Grid stackable>
                <Grid.Row className="rounded-edges" style={{ background: "var(--light)", padding: "1rem" }} columns="equal" verticalAlign="middle">
                    <Grid.Column width={2}>
                        <Button fluid className="rounded-edges circular" style={{ color: "var(--light)", background: "var(--dark)", borderRadius: "1rem" }} onClick={() => onReturn()}>
                            <Icon name="arrow-back-outline" style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.1rem" }}>{ t('return') }</span>
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <strong style={{ fontSize: "1.3rem" }}>{ today }</strong> <br/>
                        <span style={{ textTransform: "capitalize" }}>
                            { moment(today, dateFormat).locale(setLocaleLanguage()).format('dddd') }
                        </span>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <strong style={{ fontSize: "1.3rem" }}>{ employee?.name || "--" }</strong>
                        <div>
                        { contract === null ? <span style={{ opacity: "0.5" }}>{ t('not_specified') }</span> : 
                            <span>
                                { contract.employer?.name } - { displayWorkplace(contract) }
                            </span>
                        }
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        
                        <strong>{ t('start') }: </strong> { start || "--" }<br/>
                        <strong>{ t('end') }: </strong> { end || "--" }
                        { employee?.record?.interruptions?.filter(interruption => interruption.end === null)?.length > 0 && <strong> ({t('interruption')})</strong> }
                        <br/>
                    </Grid.Column>
                    <Grid.Column>
                        <strong style={{ textTransform: "capitalize" }}>{ t('shift') }: </strong> { shift?.title || t('not_specified') }<br/>
                        <strong>1: { t('pause') }: </strong> { start_pause ? moment(start_pause).format("HH:mm") : "--:--" } / { end_pause ? moment(end_pause).format("HH:mm") : "--:--" }<br/>
                        <strong>2: { t('pause') }: </strong> 
                            { shift === null 
                                ? "--" 
                                : 
                                <>
                                    { start_second_pause ? moment(start_second_pause).format("HH:mm") : "--:--" } / { end_second_pause ? moment(end_second_pause).format("HH:mm") : "--:--" }
                                </>
                            }<br/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <Grid stackable>
                <Grid.Row verticalAlign="top" columns={3}>
                    <Grid.Column>
                        { employee?.record === null 
                            ? 
                                <>
                                    { employee.contracts.length > 1 
                                        ?
                                            <SuperDuperModal
                                                trigger={
                                                    <Button 
                                                        fluid
                                                        size="massive"
                                                        disabled={processingType === "start"}
                                                        className="rounded-edges circular"
                                                        loading={processingType === "start"}
                                                        style={{ 
                                                            paddingTop: "1.5rem",
                                                            paddingBottom: "1.5rem",
                                                            marginLeft: "0.5rem",
                                                            marginRight: "0.5rem",
                                                            background: "green",
                                                            color: "var(--light)",
                                                            textTransform: "capitalize"
                                                        }}
                                                    >
                                                        <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                                            <Icon name="play-outline"/>
                                                        </div>
                                                        {t('start')}
                                                    </Button>
                                                }
                                                content={
                                                    <ChooseWorkingContract 
                                                        setContract={setContract}
                                                        handleAction={handleAction}
                                                        contracts={employee.contracts}
                                                        displayWorkplace={displayWorkplace}
                                                    />
                                                }
                                            />
                                        :
                                            <Button 
                                                fluid
                                                size="massive"
                                                disabled={processingType === "start"}
                                                className="rounded-edges circular"
                                                loading={processingType === "start"}
                                                onClick={() => handleAction("start", contract)}
                                                style={{ 
                                                    paddingTop: "1.5rem",
                                                    paddingBottom: "1.5rem",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                    background: "green",
                                                    color: "var(--light)",
                                                    textTransform: "capitalize"
                                                }}
                                            >
                                                <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                                    <Icon name="play-outline"/>
                                                </div>
                                                {t('start')}
                                            </Button>
                                    }
                                </>
                            : 
                                <Button
                                    fluid
                                    size="massive"
                                    disabled={
                                        employee?.record?.end !== null ||
                                        processingType === "end" ||
                                        (employee?.record?.start_pause !== null && employee?.record?.end_pause === null) ||
                                        employee?.record?.interruptions?.filter(interruption => interruption.end === null)?.length > 0
                                    }
                                    className="rounded-edges circular" 
                                    loading={processingType === "end"}
                                    onClick={() => handleAction("end")}
                                    style={{ 
                                        paddingTop: "1.5rem",
                                        paddingBottom: "1.5rem",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                        background: "var(--danger)",
                                        color: "var(--light)",
                                        textTransform: "capitalize"
                                    }}
                                >
                                    <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                        <Icon name="stop-circle-outline"/>
                                    </div>
                                    {t('end')}
                                </Button>
                        }
                    </Grid.Column>
                    <Grid.Column>
                        { employee?.record?.end_pause !== null && shift !== null && shift.second_pause_length !== null
                        ? 
                        <Button 
                            fluid
                            size="massive"
                            disabled={
                                (employee?.record?.second_start_pause !== null && employee?.record?.second_end_pause !== null) ||
                                (employee?.record?.interruptions?.filter(interruption => interruption.end === null)?.length > 0) ||
                                processingType === "pause"
                            }
                            className="rounded-edges circular" 
                            loading={processingType === "pause"}
                            onClick={() => handleAction("second_pause")}
                            style={{ 
                                paddingTop: "1.5rem",
                                paddingBottom: "1.5rem",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                                background: "var(--dark)",
                                color: "var(--light)"
                            }}
                        >
                            <small style={{ textTransform: "lowercase"}}> 
                                { (employee?.record?.second_start_pause === null || employee?.record === null) 
                                    ? 
                                        <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                            <Icon name="play-outline"/>
                                        </div>
                                    :  
                                        <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                            <Icon name="stop-circle-outline"/>
                                        </div>
                                }
                            </small>
                            <span style={{ textTransform: "capitalize" }}>{t('pause')}</span>
                        </Button>
                        : 
                            <Button 
                                fluid
                                size="massive"
                                disabled={
                                    (employee?.record?.start_pause !== null && employee?.record?.end_pause !== null) ||
                                    (employee?.record?.interruptions?.filter(interruption => interruption.end === null)?.length > 0) ||
                                    processingType === "pause"
                                }
                                className="rounded-edges circular" 
                                loading={processingType === "pause"}
                                onClick={() => handleAction("pause")}
                                style={{ 
                                    paddingTop: "1.5rem",
                                    paddingBottom: "1.5rem",
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem",
                                    background: "var(--dark)",
                                    color: "var(--light)"
                                }}
                            >
                                <small style={{ textTransform: "lowercase"}}> 
                                    { (employee?.record?.start_pause === null || employee?.record === null) 
                                        ? 
                                            <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                                <Icon name="play-outline"/>
                                            </div>
                                        :  
                                            <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                                <Icon name="stop-circle-outline"/>
                                            </div>
                                    }
                                </small>
                                <span style={{ textTransform: "capitalize" }}>{t('pause')}</span>
                            </Button>
                        }
                    </Grid.Column>
                    <Grid.Column>
                        { [null, undefined, 0].includes(employee?.record?.interruptions?.filter(interruption => interruption.end === null)?.length) 
                            ? 
                                <SuperDuperModal
                                    header={<div style={{ textAlign: "center" }}>{ t("select_interruptions_reason") }</div>}
                                    trigger={
                                        <Button 
                                            fluid
                                            size="massive"
                                            disabled={
                                                employee?.record === null ||
                                                processingType === "interruption" ||
                                                (employee?.record?.start_pause !== null && employee?.record?.end_pause === null)
                                            }
                                            className="rounded-edges circular" 
                                            loading={processingType === "interruption"}
                                            style={{ 
                                                paddingTop: "1.5rem",
                                                paddingBottom: "1.5rem",
                                                marginLeft: "0.5rem",
                                                marginRight: "0.5rem",
                                                background: "var(--dark)",
                                                color: "var(--light)"
                                            }}
                                        >
                                            <small style={{ textTransform: "lowercase"}}> 
                                                <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                                    <Icon name="list-outline"/>
                                                </div>
                                            </small>
                                            <span style={{ textTransform: "capitalize" }}>{t('interruption')}</span>
                                        </Button>
                                    }
                                    content={
                                        <InterruptionReasonsSelectList 
                                            employee={employee}
                                            onReturn={onReturn}
                                            setEmployee={setEmployee}
                                            interruptions={interruptions}
                                            endActivityDisplayText={endActivityDisplayText}
                                            cancelActionDisplayText={cancelActionDisplayText}
                                        />
                                    }
                                />
                            : 
                                <Button 
                                    fluid
                                    size="massive"
                                    disabled={
                                        employee.record === null ||
                                        processingType === "interruption" ||
                                        (employee?.record?.start_pause !== null && employee?.record?.end_pause === null)
                                    }
                                    className="rounded-edges circular" 
                                    loading={processingType === "interruption"}
                                    onClick={() => handleInterruptionStop()}
                                    style={{ 
                                        paddingTop: "1.5rem",
                                        paddingBottom: "1.5rem",
                                        marginLeft: "0.5rem",
                                        marginRight: "0.5rem",
                                        background: "var(--danger)",
                                        color: "var(--light)"
                                    }}
                                >
                                    <small style={{ textTransform: "lowercase"}}> 
                                        <div style={{ fontSize: "3rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                            <Icon name="stop-circle-outline"/>
                                        </div>
                                    </small>
                                    <span style={{ textTransform: "capitalize" }}>{t('interruption')}</span>
                                </Button>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default AttendanceSelfManagement;