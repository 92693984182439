import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { getRandomColor } from '@helpers/functions'
import { tzDateTime, convertTime, handleTimeInput, isTimeValid } from '@helpers/dates'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import { Form, Divider, Label } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const ShiftManagement = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={['attendance.c_manage_shifts']} redirect>
            <div style={{ marginTop: '1rem' }}></div>
            <ListView
                as="table"
                allowSearch
                // allowSelection
                // isSoftDeleteAllowed
                endpoint={API.ATTENDANCE_BASE + 'shifts/'}
                tableHeaders={[
                    { title: t('custom_id') },
                    { title: t('title') },
                    { title: t('time_from') },
                    { title: t('time_to') },
                    { title: t('first_break_duration') },
                    { title: t('second_break_duration') },
                    { title: t('duration') },
                    { title: t('night_shift') },
                    { title: t('color') },
                ]}
                renderCells={(shift) => [
                    { content: shift.custom_id },
                    { content: shift.title },
                    { content: convertTime(shift.time_from, false) },
                    { content: convertTime(shift.time_to, false) },
                    { content: shift.pause_length },
                    { content: shift.second_pause_length },
                    { content: shift.length },
                    { content: shift.is_night_shift ? t('yes') : t('no') },
                    { content: <Label style={{ width: '50px', height: '25px', backgroundColor: shift.color }} /> },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        modalSize: 'tiny',
                        name: t('add_shift'),
                        modal: <ShiftForm />,
                        permissions: ['attendance.c_manage_shifts'],
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'tiny',
                        permissions: ['attendance.c_manage_shifts'],
                        modal: <ShiftForm />,
                    },
                    {
                        as: 'delete',
                        name: t('delete'),
                        type: 'delete',
                        text: t('delete_shift'),
                        permissions: ['attendance.c_manage_shifts'],
                    },
                ]}
            />
        </CanView>
    )
}

const ShiftForm = ({ onClose, record, setData, setTotal }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        title: record?.title || '',
        custom_id: record?.custom_id || '',
        time_from: record?.time_from ? convertTime(record?.time_from, false) : '',
        time_to: record?.time_to ? convertTime(record?.time_to, false) : '',
        pause_length: record?.pause_length || '30', // in minutes
        second_pause_length: record?.second_pause_length || '', // in minutes
        is_night_shift: record?.is_night_shift ? record?.is_night_shift : false, // day shift by default
        color: record?.color || getRandomColor(),
        // length: "" -> difference from Expr. "time_from - time_to"
    })

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let day = moment().format('YYYY-MM-DD')
        let start_time = start ? tzDateTime(moment(day + ' ' + start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(day + ' ' + end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    const isFormValid = () => {
        if (form.title === '') return false
        if (form.time_from === '' || !isTimeValid(form.time_from)) return false
        if (form.time_to === '' || !isTimeValid(form.time_to)) return false
        return true
    }

    const handleBreakDeduction = (time_from, time_to) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (form.pause_length !== '' && parseFloat(form.pause_length) > 0) {
            duration = duration - parseFloat(form.pause_length)
        }

        if (form.second_pause_length !== '' && parseFloat(form.second_pause_length) > 0) {
            duration = duration - parseFloat(form.second_pause_length)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)
        const data = {
            ...form,
            pause_length: form.pause_length === '' ? null : form.pause_length,
            second_pause_length: form.second_pause_length === '' ? null : form.second_pause_length,
            length: handleBreakDeduction(form.time_from, form.time_to),
            time_from: convertTime(form.time_from, true),
            time_to: convertTime(form.time_to, true),
        }
        if (record?.id === undefined) {
            const request = await requests.post(API.ATTENDANCE_BASE + 'shifts/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setTotal((prev) => prev + 1)
                setData((prev) => [
                    ...prev,
                    {
                        ...request.response,
                    },
                ])
                onClose()
            }
        } else {
            const request = await requests.patch(API.ATTENDANCE_BASE + 'shifts/' + record?.id + '/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (record?.id === item.id) {
                            item = {
                                ...request.response,
                            }
                        }
                        return item
                    })
                )

                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField
                    as="input"
                    required
                    autoFocus
                    width="10"
                    label={t('title')}
                    value={form.title}
                    error={errors !== null ? errors?.title?.[0] : false}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            title: value,
                            custom_id: form.custom_id === '' ? value.charAt(0) : form.custom_id,
                        }))
                    }
                />
                <SuperField
                    as="input"
                    width="6"
                    required
                    label={t('custom_id')}
                    value={form.custom_id}
                    error={errors !== null ? errors?.custom_id?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, custom_id: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="timepicker"
                    required
                    label={t('time_from')}
                    value={form.time_from}
                    error={!isTimeValid(form.time_from) ? true : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, time_from: handleTimeInput(value) }))}
                />
                <SuperField
                    as="timepicker"
                    required
                    label={t('time_to')}
                    value={form.time_to}
                    error={!isTimeValid(form.time_to) ? true : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, time_to: handleTimeInput(value) }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    label={t('first_break_duration') + ' (' + t('in_minutes') + ')'}
                    value={form.pause_length}
                    error={errors !== null ? errors?.pause_length?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, pause_length: value }))}
                />
                <SuperField
                    as="input"
                    label={t('second_break_duration') + ' (' + t('in_minutes') + ')'}
                    value={form.second_pause_length}
                    error={errors !== null ? errors?.second_pause_length?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, second_pause_length: value }))}
                />
            </Form.Group>

            <p>
                {' '}
                {t('duration')}:{' '}
                <strong>
                    {handleBreakDeduction(form.time_from, form.time_to)} {t('hours_shortcut')}.
                </strong>{' '}
            </p>

            <SuperField
                as="checkbox"
                label={t('mark_as_night_shift')}
                checked={form.is_night_shift}
                onChange={() => setForm((prev) => ({ ...prev, is_night_shift: !form.is_night_shift }))}
            />

            <SuperField
                as="colorpicker"
                label={t('color')}
                help={t('color_to_improve_visual_in_graphs')}
                color={form.color}
                setColor={(value) => setForm((prev) => ({ ...prev, color: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} disabled={isProcessing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default ShiftManagement
