import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { tzDateTime } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import { Popup, Button, Segment, Table } from 'semantic-ui-react'
// specific components
import PauseForm from './PauseForm'
import RecordDuplicityMergeView from './RecordDuplicityMergeView'

const WeekManagement = ({
    key,
    week,
    weekDates,
    shifts,
    loadingShifts,
    employee,
    contract,
    excludeWeekend,
    setWeeksStats,
}) => {
    const { t } = useTranslation()
    const language = useSelector((state) => state.language)
    const date_format = useSelector((state) => state.date_format)
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        const generateWeeklyAttendance = async () => {
            setLoading(true)
            let dateFrom = weekDates[0]?.day
            let dateTo = weekDates[weekDates.length - 1]?.day

            let dataset = []
            const request = await requests.get(
                API.ATTENDANCE +
                    '?employee=' +
                    contract.employee.id +
                    '&date_from=' +
                    moment(dateFrom).format('YYYY-MM-DD') +
                    '&date_to=' +
                    moment(dateTo).format('YYYY-MM-DD')
            )
            if (request.status === 200) {
                dataset = request.response
            }

            const dates = getDates(moment(dateFrom), moment(dateTo))
            const attendance = []

            for (let i = 0; i < dates.length; i++) {
                const attendance_record = dataset.find((item) => item.date === moment(dates[i]).format('YYYY-MM-DD'))

                // convert to timezone
                let start_time = attendance_record?.start ? tzDateTime(attendance_record?.start).format('HH:mm') : ''
                let end_time = attendance_record?.end ? tzDateTime(attendance_record?.end).format('HH:mm') : ''
                let start_pause = attendance_record?.start_pause
                    ? tzDateTime(attendance_record?.start_pause).format('YYYY-MM-DD HH:mm')
                    : ''
                let end_pause = attendance_record?.end_pause
                    ? tzDateTime(attendance_record?.end_pause).format('YYYY-MM-DD HH:mm')
                    : ''

                attendance.push({
                    week: moment(dates[i]).isoWeek(),
                    day: dates[i],
                    errors: [],
                    isProcessing: false,
                    multipleData: dataset.filter((item) => item.date === moment(dates[i]).format('YYYY-MM-DD')),
                    data:
                        attendance_record !== undefined
                            ? {
                                  ...attendance_record,
                                  start_time: start_time,
                                  end_time: end_time,
                                  start_pause: start_pause,
                                  end_pause: end_pause,
                                  shift: attendance_record?.shift?.id || '',
                              }
                            : {},
                })
            }

            setRecords(attendance)
            setLoading(false)
        }

        generateWeeklyAttendance()
        // eslint-disable-next-line
    }, [])

    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'))
        }

        return dates
    }

    const isWeekendDay = (day) => {
        if (excludeWeekend === false) return false

        return [0, 6].includes(moment(day).day())
    }

    const handleDecimalValue = (value) => {
        if (value === '') return value

        return value.replace(',', '.')
    }

    const handleTimeValue = (value) => {
        value = value.replace('.', ':')
        value = value.replace(',', ':')
        value = value.replace(';', ':')
        // value = value.replace(" ", ":")

        return value
    }

    const isNumberValid = (value) => {
        if (value === '') return true
        if (value === null) return true
        if (value === undefined) return true

        return !isNaN(value)
    }

    const isTimeValid = (value) => {
        if (value === '') return true
        if (value === null) return true
        if (value === undefined) return true

        return moment(value, 'HH:mm', true).isValid()
    }

    const handleRecordDelete = async (day) => {
        setIsProcessing(day, true)
        const record = records.find((item) => item.day === day)
        if (record) {
            const request = await requests.post(API.ATTENDANCE + record.data.id + '/delete/')
            if (request.status === 200) {
                setRecords((prev) =>
                    prev.filter((item) => {
                        if (item.day === day) {
                            item.data = {}
                        }

                        return item
                    })
                )
            }
        }
        setIsProcessing(day, false)
    }

    const handleWeekSaveAction = async (week) => {
        setIsSaving(true)
        const week_records = records.filter((record) => record.week === week)

        for (let i = 0; i < week_records.length; i++) {
            if (Object.keys(week_records[i].data).length !== 0) {
                // skip day when no data are filled
                setIsProcessing(week_records[i].day, true)
                const start = week_records[i].data?.start_time
                    ? moment(week_records[i].day + ' ' + week_records[i].data?.start_time)
                    : null
                const tmp_end = week_records[i].data?.end_time
                    ? moment(week_records[i].day + ' ' + week_records[i].data?.end_time)
                    : null

                let end_day = moment(week_records[i].day).format('YYYY-MM-DD')
                if (start !== null && tmp_end !== null) {
                    // find if tmp_end is not less then given day if yes then add one day
                    if (start.isAfter(tmp_end)) {
                        end_day = moment(week_records[i].day).add(1, 'days').format('YYYY-MM-DD')
                    }
                }

                const end = week_records[i].data?.end_time
                    ? moment(end_day + ' ' + week_records[i].data?.end_time)
                    : null

                const data = {
                    start: start !== null ? tzDateTime(start, true).format('YYYY-MM-DD HH:mm:ss') : start,
                    end: end !== null ? tzDateTime(end, true).format('YYYY-MM-DD HH:mm:ss') : end,
                    duration: week_records[i].data?.duration || 0,
                    start_pause: week_records[i].data?.start_pause
                        ? tzDateTime(week_records[i].data?.start_pause, true).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    end_pause: week_records[i].data?.end_pause
                        ? tzDateTime(week_records[i].data?.end_pause, true).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    shift: week_records[i].data?.shift !== '' ? week_records[i].data?.shift : null,
                    lunch_voucher: week_records[i].data?.lunch_voucher || null,
                    vacation: week_records[i].data?.vacation || null,
                    absention: week_records[i].data?.absention || null,
                    paragraph: week_records[i].data?.paragraph || null,
                    sick_day: week_records[i].data?.sick_day || null,
                    date: week_records[i].day,
                    employee: contract.employee.id,
                    contract: contract.id,
                }

                let request = null
                if (week_records[i].data?.id > 0) {
                    request = await requests.patch(API.ATTENDANCE + week_records[i].data?.id + '/', data)
                } else {
                    request = await requests.post(API.ATTENDANCE_MANUAL, data)
                }

                if (request.status === 200 || request.status === 201) {
                    const response = request.response

                    // convert to timezone
                    let start_time = response?.start ? tzDateTime(response?.start).format('HH:mm') : ''
                    let end_time = response?.end ? tzDateTime(response?.end).format('HH:mm') : ''
                    let start_pause = response?.start_pause ? tzDateTime(response?.start_pause).format('HH:mm') : ''
                    let end_pause = response?.end_pause ? tzDateTime(response?.end_pause).format('HH:mm') : ''

                    setRecords((prev) =>
                        prev.map((item) => {
                            if (item.day === week_records[i].day) {
                                item.data = {
                                    ...response,
                                    start_time: start_time,
                                    end_time: end_time,
                                    start_pause: start_pause,
                                    end_pause: end_pause,
                                    shift: response?.shift?.id || '',
                                }
                            }

                            return item
                        })
                    )
                }

                if (request.status === 400) {
                    setRecords((prev) =>
                        prev.filter((item) => {
                            if (item.day === week_records[i].day) {
                                item.errors = request.response
                            }

                            return item
                        })
                    )
                }

                setIsProcessing(week_records[i].day, false)
            }
        }

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('success_changed'),
            description: '',
            animation: 'bounce',
            time: 3000,
        })

        // fetch latest statistics
        let date_from = weekDates[0]?.day
        let date_to = weekDates[weekDates.length - 1]?.day
        const request_week_stats = await requests.get(
            API.ATTENDANCE +
                `employee_stats/?date_from=${date_from}&date_to=${date_to}&employee=${contract.employee.id}`
        )

        if (request_week_stats.status === 200) {
            setWeeksStats((prev) =>
                prev.map((item) => {
                    if (item.week === week) {
                        item = {
                            week: week,
                            ...request_week_stats.response,
                        }
                    }
                    return item
                })
            )
        }

        setIsSaving(false)
    }

    const handleChange = async (day, value, attribute) => {
        setRecords((prev) =>
            prev.filter((record) => {
                if (record.day === day) {
                    record.data[attribute] = value
                }

                return record
            })
        )
    }

    const setIsProcessing = (day, value) => {
        setRecords((prev) =>
            prev.filter((item) => {
                if (item.day === day) {
                    item.isProcessing = value
                }

                return item
            })
        )
    }

    const calculateDuration = (record) => {
        let duration = ''
        if (Object.keys(record.data).length === 0) return
        if (record.data?.start_time === undefined || record.data?.start_time === null || record.data?.start_time === '')
            return
        if (record.data?.end_time === undefined || record.data?.end_time === null || record.data?.end_time === '')
            return

        // create start, end intervals
        if (
            moment(record.data?.start_time, 'HH:mm', true).isValid() &&
            moment(record.data?.end_time, 'HH:mm', true).isValid()
        ) {
            let start = record.data?.start_time ? moment(record.day + ' ' + record.data?.start_time) : null
            const tmp_end = record.data?.end_time ? moment(record.day + ' ' + record.data?.end_time) : null

            let end_day = moment(record.day).format('YYYY-MM-DD')
            if (start !== null && tmp_end !== null) {
                // find if tmp_end is not less then given day if yes then add one day
                if (start.isAfter(tmp_end)) {
                    end_day = moment(record.day).add(1, 'days').format('YYYY-MM-DD')
                }
            }

            let end = record.data?.end_time
                ? moment(end_day + ' ' + record.data?.end_time).format('YYYY-MM-DD HH:mm')
                : null
            duration = moment(end).diff(moment(moment(start).format('YYYY-MM-DD HH:mm')), 'hours', true)
        }

        let breakTime = 0
        // create start, end intervals
        if (
            moment(moment(record.data?.start_pause).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', true).isValid() &&
            moment(moment(record.data?.end_pause).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', true).isValid()
        ) {
            let start_pause = moment(record.data?.start_pause).format('YYYY-MM-DD HH:mm')
            let end_pause = moment(record.data?.end_pause).format('YYYY-MM-DD HH:mm')

            breakTime = moment(end_pause).diff(moment(start_pause), 'hours', true)
        }

        if (duration !== '') {
            if (duration > 0) {
                duration -= breakTime
            }
            handleChange(record.day, duration?.toFixed(2) || '', 'duration')
        }
    }

    const autoFillShift = (shift_id, record_day) => {
        const shift = shifts.find((item) => item.id === shift_id)

        if (shift) {
            if (![null, ''].includes(shift.time_from)) {
                handleChange(record_day, shift.time_from, 'start_time')
            }
            if (![null, ''].includes(shift.time_to)) {
                handleChange(record_day, shift.time_to, 'end_time')
            }

            handleChange(record_day, shift.length, 'duration')
            handleChange(record_day, ['', null, 0, undefined].includes(shift.pause_length) ? false : true, 'auto_break')
        }
    }

    const applyToAll = (field) => {
        const findRecord = records[0]
        if (findRecord) {
            for (let i = 0; i < records.length; i++) {
                handleChange(records[i].day, findRecord?.data?.[field] || '', field)
                if (field === 'shift') {
                    autoFillShift(findRecord?.data?.[field], records[i].day)
                }
            }
        }
    }

    const handleCloseAction = async (record) => {
        const request = await requests.patch(API.ATTENDANCE + record.id + '/', { closed: !record.closed })
        if (request.status === 200) {
            if (request.response.end !== null) {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('record_can_not_be_changed_already_locked_hint'),
                    animation: 'pulse',
                    time: 5000,
                })
            } else {
                setRecords((prev) =>
                    prev.map((item) => {
                        if (item.data.id === record.id) {
                            item.data = {
                                ...item.data,
                                closed: request.response.closed,
                            }
                        }

                        return item
                    })
                )
            }
        }
    }

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                marginBottom: '1rem',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '10rem', paddingBottom: '4rem' }}>
                    {t('loading_weekly_attendance')}
                </p>
            )}

            {!loading && (
                <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
                    <Table striped selectable unstackable className="table responsive" size="small">
                        <Table.Header>
                            <Table.Row verticalAlign="middle">
                                <Table.HeaderCell content={t('date')} />
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem', textTransform: 'capitalize' }}>
                                        {t('shift')}{' '}
                                    </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('shift')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('time_from')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('start_time')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('time_to')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('end_time')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('duration')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="information-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                }}
                                            />
                                        }
                                        content={t('enter_value_excluding_pause')}
                                    />
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('duration')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell content={t('pause')} textAlign="center" />
                                <Table.HeaderCell content={t('working_hours')} textAlign="center" />
                                <Table.HeaderCell textAlign="center">
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('lunch_voucher')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('lunch_voucher')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('vacation')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('vacation')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('absention')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('absention')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('paragraph')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('paragraph')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    <span style={{ position: 'relative', top: '-0.2rem' }}> {t('sick_day')} </span>
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="chevron-down-circle-outline"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    marginLeft: '0.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => applyToAll('sick_day')}
                                            />
                                        }
                                        content={t('apply_to_all_attendance_hint')}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Button
                                        primary
                                        fluid
                                        size="small"
                                        loading={isSaving}
                                        disabled={isSaving}
                                        type="button"
                                        content={t('save_changes')}
                                        onClick={() => handleWeekSaveAction(week)}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {records.map((record, index) => (
                                <>
                                    {!isWeekendDay(record.day) && (
                                        <Table.Row
                                            verticalAlign="middle"
                                            key={index}
                                            style={
                                                record.multipleData.length > 1 ? { background: '#f7b0b0' } : undefined
                                            }
                                        >
                                            <Table.Cell width="1">
                                                {moment(record.day).format(date_format)} <br />
                                                <div style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                                    {moment(record.day).locale(language).format('dddd')}
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <SuperField
                                                    as="choice"
                                                    search
                                                    width="16"
                                                    value={record?.data?.shift || ''}
                                                    loading={loadingShifts}
                                                    disabled={loadingShifts}
                                                    customOptions={shifts.map((item) => ({
                                                        key: item.id,
                                                        value: item.id,
                                                        text: item.title,
                                                    }))}
                                                    style={{ marginTop: '0.25rem' }}
                                                    onChange={(e, { value }) => {
                                                        autoFillShift(value, record.day)
                                                        handleChange(record.day, value, 'shift')
                                                    }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isTimeValid(record.data?.start_time || '')}
                                                    trigger={
                                                        <SuperField
                                                            as="timepicker"
                                                            style={{ width: '100px' }}
                                                            value={record.data?.start_time || ''}
                                                            onChange={(e, { value }) => {
                                                                handleChange(
                                                                    record.day,
                                                                    handleTimeValue(value),
                                                                    'start_time'
                                                                )
                                                                let changed_record = record
                                                                changed_record.data['start_time'] =
                                                                    handleTimeValue(value)
                                                                calculateDuration(changed_record)
                                                            }}
                                                            error={
                                                                !isTimeValid(record.data?.start_time || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    content={
                                                        !isTimeValid(record.data?.start_time || '')
                                                            ? t('wront_time_format_use_hh_mm')
                                                            : ''
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isTimeValid(record.data?.end_time || '')}
                                                    trigger={
                                                        <SuperField
                                                            as="timepicker"
                                                            style={{ width: '100px' }}
                                                            value={record.data?.end_time || ''}
                                                            error={
                                                                !isTimeValid(record.data?.end_time || '') ? true : false
                                                            }
                                                            onChange={(e, { value }) => {
                                                                handleChange(
                                                                    record.day,
                                                                    handleTimeValue(value),
                                                                    'end_time'
                                                                )
                                                                let changed_record = record
                                                                changed_record.data['end_time'] = handleTimeValue(value)
                                                                calculateDuration(changed_record)
                                                            }}
                                                        />
                                                    }
                                                    content={
                                                        !isTimeValid(record.data?.end_time || '')
                                                            ? t('wront_time_format_use_hh_mm')
                                                            : ''
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isNumberValid(record.data?.duration || '')}
                                                    content={
                                                        !isNumberValid(record.data?.duration) ? t('not_a_number') : ''
                                                    }
                                                    trigger={
                                                        <SuperField
                                                            as="input"
                                                            style={{ width: '100px' }}
                                                            value={record.data?.duration?.replace('.00', '') || ''}
                                                            onChange={(e, { value }) =>
                                                                handleChange(
                                                                    record.day,
                                                                    handleDecimalValue(value),
                                                                    'duration'
                                                                )
                                                            }
                                                            error={!isNumberValid(record.data?.duration) ? true : false}
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Action
                                                    as="modal"
                                                    type="icon"
                                                    modalSize="tiny"
                                                    iconColor="var(--dark)"
                                                    icon={record.data?.start_pause ? 'pencil-outline' : 'add-outline'}
                                                    tooltip={
                                                        record.data?.start_pause ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <strong>{t('update_pause')}</strong> <br />
                                                                {moment(record.data?.start_pause).format(
                                                                    date_format + ' HH:mm'
                                                                )}{' '}
                                                                <br />
                                                                {moment(record.data?.end_pause).format(
                                                                    date_format + ' HH:mm'
                                                                )}
                                                            </div>
                                                        ) : (
                                                            t('add_pause')
                                                        )
                                                    }
                                                    modal={
                                                        <PauseForm
                                                            record={record}
                                                            handleChange={handleChange}
                                                            calculateDuration={calculateDuration}
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" style={{ fontWeight: 'bold' }}>
                                                {record.data?.work_hours === undefined ? (
                                                    '--'
                                                ) : (
                                                    <>
                                                        {record.data?.work_hours || '0'}{' '}
                                                        <span style={{ textTranform: 'lowercase' }}>
                                                            {t('hours_shortcut')}.
                                                        </span>
                                                    </>
                                                )}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isNumberValid(record.data?.lunch_voucher || '')}
                                                    content={
                                                        !isNumberValid(record.data?.lunch_voucher)
                                                            ? t('not_a_number')
                                                            : ''
                                                    }
                                                    trigger={
                                                        <SuperField
                                                            as="input"
                                                            style={{ width: '100px' }}
                                                            className="input-centered-value"
                                                            value={
                                                                record.data?.lunch_voucher
                                                                    ?.toString()
                                                                    ?.replace('.00', '') || ''
                                                            }
                                                            onChange={(e, { value }) =>
                                                                handleChange(
                                                                    record.day,
                                                                    handleDecimalValue(value),
                                                                    'lunch_voucher'
                                                                )
                                                            }
                                                            error={
                                                                !isNumberValid(record.data?.lunch_voucher || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isNumberValid(record.data?.vacation || '')}
                                                    content={
                                                        !isNumberValid(record.data?.vacation) ? t('not_a_number') : ''
                                                    }
                                                    trigger={
                                                        <SuperField
                                                            as="input"
                                                            style={{ width: '100px' }}
                                                            className="input-centered-value"
                                                            value={record.data?.vacation?.replace('.00', '') || ''}
                                                            onChange={(e, { value }) =>
                                                                handleChange(
                                                                    record.day,
                                                                    handleDecimalValue(value),
                                                                    'vacation'
                                                                )
                                                            }
                                                            error={
                                                                !isNumberValid(record.data?.vacation || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isNumberValid(record.data?.absention || '')}
                                                    content={
                                                        !isNumberValid(record.data?.absention) ? t('not_a_number') : ''
                                                    }
                                                    trigger={
                                                        <SuperField
                                                            as="input"
                                                            style={{ width: '100px' }}
                                                            className="input-centered-value"
                                                            value={record.data?.absention?.replace('.00', '') || ''}
                                                            onChange={(e, { value }) =>
                                                                handleChange(
                                                                    record.day,
                                                                    handleDecimalValue(value),
                                                                    'absention'
                                                                )
                                                            }
                                                            error={
                                                                !isNumberValid(record.data?.absention || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isNumberValid(record.data?.paragraph || '')}
                                                    content={
                                                        !isNumberValid(record.data?.paragraph) ? t('not_a_number') : ''
                                                    }
                                                    trigger={
                                                        <SuperField
                                                            as="input"
                                                            style={{ width: '100px' }}
                                                            className="input-centered-value"
                                                            value={record.data?.paragraph?.replace('.00', '') || ''}
                                                            onChange={(e, { value }) =>
                                                                handleChange(
                                                                    record.day,
                                                                    handleDecimalValue(value),
                                                                    'paragraph'
                                                                )
                                                            }
                                                            error={
                                                                !isNumberValid(record.data?.paragraph || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" width="1">
                                                <Popup
                                                    position="top center"
                                                    disabled={isNumberValid(record.data?.sick_day || '')}
                                                    content={
                                                        !isNumberValid(record.data?.sick_day) ? t('not_a_number') : ''
                                                    }
                                                    trigger={
                                                        <SuperField
                                                            as="input"
                                                            style={{ width: '100px' }}
                                                            className="input-centered-value"
                                                            value={record.data?.sick_day?.replace('.00', '') || ''}
                                                            onChange={(e, { value }) =>
                                                                handleChange(
                                                                    record.day,
                                                                    handleDecimalValue(value),
                                                                    'sick_day'
                                                                )
                                                            }
                                                            error={
                                                                !isNumberValid(record.data?.sick_day || '')
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell width="1" style={{ width: '150px', textAlign: 'center' }}>
                                                {/* <Popup
                                                disabled={true}
                                                position="left center"
                                                trigger={
                                                    <Icon className="disabled" name="chatbubble-ellipses-outline" style={{ cursor: "not-allowed" }}/>
                                                }
                                                content={t('add_note')}
                                            /> */}
                                                <CanView permissions={['attendance.c_delete_all_records']}>
                                                    {record.data?.id > 0 && (
                                                        <>
                                                            {record.multipleData.length > 1 ? (
                                                                <Action
                                                                    as="modal"
                                                                    type="icon"
                                                                    size="huge"
                                                                    tooltip={t('correction_multiple_records')}
                                                                    iconColor="var(--dark)"
                                                                    header={t('correction_multiple_records')}
                                                                    icon="pencil-outline"
                                                                    modal={
                                                                        <RecordDuplicityMergeView
                                                                            setRecords={setRecords}
                                                                            day={record.day}
                                                                            records={record.multipleData}
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                <Popup
                                                                    position="left center"
                                                                    content={t('remove')}
                                                                    trigger={
                                                                        <Icon
                                                                            className={
                                                                                record.isProcessing ? 'disabled' : ''
                                                                            }
                                                                            name="close-outline"
                                                                            style={{
                                                                                position: 'relative',
                                                                                cursor: 'pointer',
                                                                                fontSize: '1.2rem',
                                                                                marginRight: '2rem',
                                                                                top: '0.2rem',
                                                                                color: record.isProcessing
                                                                                    ? 'var(--black)'
                                                                                    : 'var(--danger)',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleRecordDelete(record.day)
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </CanView>

                                                <CanView permissions={['attendance.c_manage_all_records']}>
                                                    {record?.data?.id && record.multipleData.length === 1 && (
                                                        <Popup
                                                            position="right center"
                                                            content={
                                                                record?.data?.closed === true
                                                                    ? t('reopen_closed_day')
                                                                    : t('close_day')
                                                            }
                                                            trigger={
                                                                <Icon
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        fontSize: '1.2rem',
                                                                        color:
                                                                            record?.data?.closed === true
                                                                                ? 'var(--dark)'
                                                                                : 'green',
                                                                    }}
                                                                    onClick={() => handleCloseAction(record?.data)}
                                                                    name={
                                                                        record?.data?.closed === true
                                                                            ? 'lock-closed'
                                                                            : 'lock-open'
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </CanView>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </Segment>
    )
}

export default WeekManagement
