import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { tzDateTime, setLocaleLanguage } from '@helpers/dates'
import { requests } from '@helpers/requests'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import { Form } from 'semantic-ui-react'
import DayRecordForm from '../components/DayRecordForm'

const DayOverview = () => {
    const { t } = useTranslation()
    const profile_id = useSelector((state) => state?.user?.profile_id || null)
    const dateFormat = useSelector((state) => state.date_format)

    const [day, setDay] = useState(moment().format('YYYY-MM-DD'))
    const currentTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    const [unit, setUnit] = useState('')
    const [shifts, setShifts] = useState([])

    const [loadingUnits, setLoadingUnits] = useState(false)
    const [units, setUnits] = useState([])

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let start_time = start ? tzDateTime(moment(start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    const handleShiftConvertion = (shift) => {
        if (!['', null].includes(shift.time_from)) {
            let datetime_from = moment(moment().format('YYYY-MM-DD') + ' ' + shift.time_from).format('YYYY-MM-DD HH:mm')
            // convert to Localtime:
            shift.time_from = tzDateTime(datetime_from).format('HH:mm')
        }

        if (!['', null].includes(shift.time_to)) {
            let datetime_to = moment(moment().format('YYYY-MM-DD') + ' ' + shift.time_to).format('YYYY-MM-DD HH:mm')
            // convert to UTC:
            shift.time_to = tzDateTime(datetime_to).format('HH:mm')
        }

        if (!['', null].includes(shift.pause_length)) {
            // convert from minutes to hours:
            if (parseFloat(shift.pause_length) > 0) {
                shift.pause_length = parseFloat(shift.pause_length)
            }
        }
        return shift
    }

    const fetchShifts = async () => {
        const request = await requests.get(API.ATTENDANCE_BASE + 'shifts/')

        if (request.status === 200) {
            setShifts(
                request.response.map((item) => ({ ...handleShiftConvertion(item), is_processing: false, errors: null }))
            )
        }
    }

    const fetchUnits = async () => {
        setLoadingUnits(true)
        const request = await requests.get(
            API.UNITS + '?query={id, name}&only_from_active_company=true&responsible_persons=' + profile_id
        )
        if (request.status === 200) {
            const unitList = request.response.map((item) => ({
                id: item.id,
                value: item.id,
                text: item.name,
            }))
            setUnits(unitList)

            if (unitList.length === 1) setUnit(unitList[0].value)
        }
        setLoadingUnits(false)
    }

    useEffect(() => {
        fetchShifts()
        fetchUnits()
        // eslint-disable-next-line
    }, [])

    return (
        <CanView permissions={['attendance.c_view_all_records']} redirect>
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.ATTENDANCE}
                actionsCellWidth="2"
                initialFilters={{
                    date_from: day,
                    date_to: day,
                    unit: unit,
                }}
                renderFilterFields={(filters, setFilters) => (
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="choice"
                            search
                            value={unit}
                            label={t('unit')}
                            disabled={loadingUnits}
                            loading={loadingUnits}
                            customOptions={units}
                            onChange={(e, { value }) => {
                                setFilters({
                                    ...filters,
                                    unit: value,
                                })
                            }}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('day')}
                            value={filters.date_from}
                            onChange={(e, { value }) => {
                                setFilters({
                                    ...filters,
                                    date_from: value,
                                    date_to: value,
                                })
                                setDay(value)
                            }}
                        />
                    </Form.Group>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'tiny',
                        permissions: ['attendance.c_manage_all_records', 'attendance.c_manage_user_records'],
                        modal: (item, setData) => <DayRecordForm record={item} setData={setData} shifts={shifts} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        permissions: ['attendance.c_delete_all_records'],
                        text: t('delete'),
                    },
                ]}
                title={
                    <>
                        {moment(day).format(dateFormat)} {' - '}
                        <span style={{ textTransform: 'capitalize' }}>
                            {moment(day).locale(setLocaleLanguage()).format('dddd')}
                        </span>
                    </>
                }
                tableHeaders={[
                    { title: t('employee') },
                    { title: t('department') },
                    { title: t('work_shift') },
                    { title: t('arrival') },
                    { title: t('leave') },
                    { title: t('pause') },
                    { title: t('working_hours') },
                    { title: t('note') },
                ]}
                renderCells={(record) => [
                    {
                        content: (
                            <span>
                                <strong style={{ fontSize: '1rem' }}>
                                    {record?.contract?.employee?.fullname_with_titles}
                                </strong>
                                <br />
                                <small style={{ opacity: '0.8' }}>
                                    {record?.contract?.employee?.custom_prefix_and_id !== ''
                                        ? record?.contract?.employee?.custom_prefix_and_id + ' · '
                                        : ''}
                                    {record?.contract?.work_position?.title}
                                </small>
                                <br />
                                <small>{}</small>
                            </span>
                        ),
                    },
                    { content: record?.employee?.unit?.name || t('unspecified') },
                    { content: record?.shift?.title || t('unspecified') },
                    {
                        content: record?.start && <>{moment(tzDateTime(record?.start)).format('HH:mm')}</>,
                    },
                    {
                        content: record?.end ? <>{moment(tzDateTime(record?.end)).format('HH:mm')}</> : '--:--',
                    },
                    {
                        content: record?.pause && (
                            <span>
                                1. {record?.start_pause ? tzDateTime(record?.start_pause).format('HH:mm') : '--:--'} /{' '}
                                {record?.end_pause ? tzDateTime(record?.end_pause).format('HH:mm') : '--:--'}
                                {record?.second_start_pause === null ||
                                (record?.shift === null && record?.shift?.second_pause_length === null) ? null : (
                                    <>
                                        <br />
                                        2.{' '}
                                        {record?.second_start_pause
                                            ? tzDateTime(record?.second_start_pause).format('HH:mm')
                                            : '--:--'}{' '}
                                        /{' '}
                                        {record?.second_end_pause
                                            ? tzDateTime(record?.second_end_pause).format('HH:mm')
                                            : '--:--'}
                                    </>
                                )}
                                <br />
                            </span>
                        ),
                    },
                    {
                        content: record?.closed ? (
                            <span style={{ fontSize: '1rem' }}>{record.work_hours + ' h'}</span>
                        ) : (
                            <span style={{ fontSize: '1rem' }}>{calculateTime(record?.start, currentTime) + ' h'}</span>
                        ),
                    },
                    { content: record?.note },
                ]}
            />
        </CanView>
    )
}

export default DayOverview
