import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import { Divider, Form, Header } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalSubmit from '@components/buttons/ModalSubmit'
import ModalCancel from '@components/buttons/ModalCancel'

const DayRecordForm = ({ record, setData, shifts, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    // eslint-disable-next-line
    const [day] = useState(tzDateTime(record.start).format('YYYY-MM-DD'))
    const [dataset, setDataset] = useState({
        employee: record?.contract?.employee?.id || null,
        contract: record?.contract?.id || null,
        shift: record?.shift?.id || null,
        start: record?.start ? tzDateTime(record?.start).format('HH:mm') : '',
        end: record?.end ? tzDateTime(record?.end).format('HH:mm') : '',
        start_pause: record?.start_pause ? tzDateTime(record?.start_pause).format('HH:mm') : '',
        end_pause: record?.end_pause ? tzDateTime(record?.end_pause).format('HH:mm') : '',
        second_start_pause: record?.second_start_pause ? tzDateTime(record?.second_start_pause).format('HH:mm') : '',
        second_end_pause: record?.second_end_pause ? tzDateTime(record?.second_end_pause).format('HH:mm') : '',
        note: record?.note || '',
    })

    const onSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)

        // handle start <-> end
        const starts = tzDateTime(day + ' ' + dataset.start, true)
        const ends = dataset.end ? tzDateTime(day + ' ' + dataset.end, true) : null

        if (starts && ends) {
            if (ends.isBefore(starts)) {
                ends.add(1, 'day')
            }
        }

        // handle pause start <-> pause end
        const start_pause = dataset.start_pause ? tzDateTime(day + ' ' + dataset.start_pause, true) : null
        const end_pause = dataset.end_pause ? tzDateTime(day + ' ' + dataset.end_pause, true) : null

        if (start_pause && end_pause) {
            if (end_pause.isBefore(start_pause)) {
                end_pause.add(1, 'day')
            }
        }

        // handle second pause start <-> pause end
        const second_start_pause = dataset.second_start_pause
            ? tzDateTime(day + ' ' + dataset.second_start_pause, true)
            : null
        const second_end_pause = dataset.second_end_pause
            ? tzDateTime(day + ' ' + dataset.second_end_pause, true)
            : null

        if (second_start_pause && second_end_pause) {
            if (second_end_pause.isBefore(start_pause)) {
                second_end_pause.add(1, 'day')
            }
        }

        const data = {
            employee: dataset.employee,
            contract: dataset.contract,
            shift: dataset.shift,
            start: starts ? starts.format('YYYY-MM-DD HH:mm') : null,
            end: ends ? ends.format('YYYY-MM-DD HH:mm') : null,
            start_pause: start_pause ? start_pause.format('YYYY-MM-DD HH:mm') : null,
            end_pause: end_pause ? end_pause.format('YYYY-MM-DD HH:mm') : null,
            second_start_pause: second_start_pause ? second_start_pause.format('YYYY-MM-DD HH:mm') : null,
            second_end_pause: second_end_pause ? second_end_pause.format('YYYY-MM-DD HH:mm') : null,
            note: dataset.note,
        }

        const request = await requests.patch(API.ATTENDANCE + record.id + '/', data)
        if (request.status === 400) setErrors(request.response)
        if (request.status === 200) {
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) {
                        item = request.response
                    }

                    return item
                })
            )
            onClose()
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <Header as="h3" content={t('update_record')} />
            <Divider />

            <NonFieldErrors errors={errors} />

            <SuperField
                as="choice"
                search
                label={t('work_shift')}
                value={dataset.shift}
                customOptions={shifts.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.title,
                }))}
                error={errors ? errors?.shift?.[0] : false}
                onChange={(e, { value }) =>
                    setDataset({
                        ...dataset,
                        shift: value,
                    })
                }
            />
            <Form.Group widths={'equal'}>
                <SuperField
                    required
                    label={t('start')}
                    as="timepicker"
                    value={dataset.start}
                    error={errors ? errors?.starts?.[0] : false}
                    onChange={(e, { value }) =>
                        setDataset({
                            ...dataset,
                            start: value,
                        })
                    }
                />
                <SuperField
                    label={t('end')}
                    as="timepicker"
                    value={dataset.end}
                    error={errors ? errors?.ends?.[0] : false}
                    onChange={(e, { value }) =>
                        setDataset({
                            ...dataset,
                            end: value,
                        })
                    }
                />
            </Form.Group>

            <Form.Group widths={'equal'}>
                <SuperField
                    label={t('start_pause')}
                    as="timepicker"
                    error={errors ? errors?.start_pause?.[0] : false}
                    value={dataset.start_pause}
                    onChange={(e, { value }) =>
                        setDataset({
                            ...dataset,
                            start_pause: value,
                        })
                    }
                />
                <SuperField
                    label={t('end_pause')}
                    as="timepicker"
                    error={errors ? errors?.end_pause?.[0] : false}
                    value={dataset.end_pause}
                    onChange={(e, { value }) =>
                        setDataset({
                            ...dataset,
                            end_pause: value,
                        })
                    }
                />
            </Form.Group>

            <Form.Group widths={'equal'}>
                <SuperField
                    label={t('second_start_pause')}
                    as="timepicker"
                    error={errors ? errors?.second_start_pause?.[0] : false}
                    value={dataset.second_start_pause}
                    onChange={(e, { value }) =>
                        setDataset({
                            ...dataset,
                            second_start_pause: value,
                        })
                    }
                />
                <SuperField
                    label={t('second_end_pause')}
                    as="timepicker"
                    error={errors ? errors?.second_end_pause?.[0] : false}
                    value={dataset.second_end_pause}
                    onChange={(e, { value }) =>
                        setDataset({
                            ...dataset,
                            second_end_pause: value,
                        })
                    }
                />
            </Form.Group>

            {}

            <SuperField
                as="textarea"
                label={t('note')}
                value={dataset.note}
                onChange={(e, { value }) =>
                    setDataset({
                        ...dataset,
                        note: value,
                    })
                }
            />

            <Divider />

            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} text={t('cancel')} />
                <ModalSubmit loading={isProcessing} text={t('save')} disabled={dataset.start === ''} />
            </Form.Field>
        </Form>
    )
}

export default DayRecordForm
