import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import _ from 'lodash';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
import { useHasPermission } from "@helpers/hooks";
// components 
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Message, Form, Divider, Icon as SemanticIcon, Segment, Header, Grid, Dropdown, Button, Loader, Popup } from 'semantic-ui-react';
// custom components
import DetailsForm from './forms/DetailsForm';
import InvoiceOrderRef from './InvoiceOrderRef';

const Invoice = ({ invoice, invoiceItems, document, connection, journal, isUpdate, setData, setTotal, onClose }) => {
    const { t } = useTranslation()

    const canViewFulfillments = useHasPermission('orders.c_view_order_fulfillment')
    const canManageFulfillments = useHasPermission('orders.c_manage_order_fulfillment')
    const canViewOrderRefFeature = canViewFulfillments && canManageFulfillments

    const [scannedFile, setScannedFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const [fetching, setFetching] = useState(true)
    const [errors, setErrors] = useState({})
    const [items, setItems] = useState(invoiceItems !== undefined ? invoiceItems : [])
    const [itemsToRemove, setItemsToRemove] = useState([])
    const [loadingItems, setLoadingItems] = useState(false)
    const [dueDays, setDueDays] = useState(14)
    const [rounding, setRounding] = useState(0)
    const [form, setForm] = useState({
        id: invoice?.id || 0,
        number: invoice?.number || "",
        invoice_type: invoice?.invoice_type ||  1,
        payment_method: invoice?.payment_method || 1,
        status: invoice?.status || 4,
        is_issued: document === "recieved_invoice" ? false : true,
        date_issue: invoice?.date_issue || moment().format("YYYY-MM-DD"),
        date_supply: invoice?.date_supply || moment().format("YYYY-MM-DD"),
        date_due: invoice?.date_due || moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD").add(dueDays, 'days').format('YYYY-MM-DD'),
        tax_mode: invoice?.tax_mode || 1,
        variable_symbol: invoice?.variable_symbol || "",
        customer_name: "",
        currency: "EUR",
    })

    const [orderRef, setOrderRef] = useState(invoice?.ref_order_number || "")

    const fillForm = () => {
        if(invoice === undefined || invoice === null) return;

        setForm(prev => ({
            ...invoice,
            date_issue: invoice.date_issue || moment().format("YYYY-MM-DD"),
            date_supply: invoice.date_supply || moment().format("YYYY-MM-DD"),
            currency: invoice.currency || "EUR",
            tax_mode: invoice.tax_mode || 1,
            invoice_type: invoice?.invoice_type || 1,
            bank_name: ""
        }))

        if (invoice.round_up === null) setRounding(0)
        if (invoice.round_up === true) setRounding(1)
        if (invoice.round_up === false) setRounding(2)
    }

    useEffect( () => {
        async function fetchReciever(){
            if( isUpdate === undefined || isUpdate === false ){
                setFetching(true)
                const request = await requests.get(API.COMPANIES + "?is_active=true&query={id, business_detail, contact_info}")
                
                if( request.status === 200 ){
                    if( request.response?.[0]?.business_detail?.identification_number === journal.id_number ){
                        if( document === "recieved_invoice" ){
                            let customerData = {
                                customer_name: request.response[0].business_detail?.name || "",
                                customer_registration_id: request.response[0].business_detail?.identification_number || "",
                                customer_tax_id: request.response[0].business_detail?.dit_number || "",
                                customer_vat_id: request.response[0].business_detail?.vat_number || "",
                                customer_business_register_folder: request.response[0].business_detail?.business_register_folder,
                                customer_street: request.response[0].business_detail?.billing_address?.street || "",
                                customer_zip: request.response[0].business_detail?.billing_address?.postcode || "",
                                customer_city: request.response[0].business_detail?.billing_address?.city || "",
                                customer_country: request.response[0].business_detail?.billing_address?.country || "",
                                customer_email: request.response[0]?.contact_info?.emails?.length > 0 ? request.response[0]?.contact_info?.emails[0].email : "",
                                customer_phone: request.response[0]?.contact_info?.phones?.length > 0 ? request.response[0]?.contact_info?.phones[0].phone : "",
                                tax_mode: request.response[0]?.business_detail?.tax_mode || 1
                            }
                            setForm(prevState => ({
                                ...prevState, 
                                ...customerData,
                            }))
                        } else {
                            const defaultBankAccount = request.response[0]?.business_detail?.bank_accounts?.find(bank => bank.default)
                            let supplierData = {
                                supplier_name: request.response[0].business_detail?.name || "",
                                supplier_registration_id: request.response[0].business_detail?.identification_number || "",
                                supplier_tax_id: request.response[0].business_detail?.dit_number || "",
                                supplier_vat_id: request.response[0].business_detail?.vat_number || "",
                                supplier_business_register_folder: request.response[0].business_detail?.business_register_folder,
                                supplier_street: request.response[0].business_detail?.billing_address?.street || "",
                                supplier_zip: request.response[0].business_detail?.billing_address?.postcode || "",
                                supplier_city: request.response[0].business_detail?.billing_address?.city || "",
                                supplier_country: request.response[0].business_detail?.billing_address?.country || "",
                                supplier_email: request.response[0]?.contact_info?.emails?.length > 0 ? request.response[0]?.contact_info?.emails[0].email : "",
                                supplier_phone: request.response[0]?.contact_info?.phones?.length > 0 ? request.response[0]?.contact_info?.phones[0].phone : "",

                                tax_mode: request.response[0]?.business_detail?.tax_mode || 1
                            }
    
                            if( defaultBankAccount !== undefined ){
                                supplierData.bank_iban = defaultBankAccount.iban
                                supplierData.bank_swift_bic = defaultBankAccount.swift_code
                            }
                            setForm(prevState => ({...prevState, ...supplierData}))
                        }
                    } else {
                        // try units
                        const requestUnits = await requests.get(API.COMPANIES + "units/?only_from_active_company=true&query={id, business_detail, contact_info}")
                        
                        if( requestUnits.status === 200 ){
                            const units = requestUnits.response
                            for (let i = 0; i < units.length; i++) {
                                if( units[i].business_detail?.identification_number === journal.id_number ){
                                    if( document === "recieved_invoice" ){
                                        let customerData = {
                                            customer_name: units[i].business_detail?.name || "",
                                            customer_registration_id: units[i].business_detail?.identification_number || "",
                                            customer_tax_id: units[i].business_detail?.dit_number || "",
                                            customer_vat_id: units[i].business_detail?.vat_number || "",
                                            customer_business_register_folder: units[i].business_detail?.business_register_folder,
                                            customer_street: units[i].business_detail?.billing_address?.street || "",
                                            customer_zip: units[i].business_detail?.billing_address?.postcode || "",
                                            customer_city: units[i].business_detail?.billing_address?.city || "",
                                            customer_country: units[i].business_detail?.billing_address?.country || "",
                                            customer_email: units[i]?.contact_info?.emails?.length > 0 ? units[i]?.contact_info?.emails[0].email : "",
                                            customer_phone: units[i]?.contact_info?.phones?.length > 0 ? units[i]?.contact_info?.phones[0].phone : "",
                                            tax_mode: units[i]?.business_detail?.tax_mode || 1
                                        }
                                        setForm(prevState => ({
                                            ...prevState, 
                                            ...customerData,
                                        }))
                                    } else {
                                        const defaultBankAccount = units[i]?.business_detail?.bank_accounts?.find(bank => bank.default)
                                        let supplierData = {
                                            supplier_name: units[i].business_detail?.name || "",
                                            supplier_registration_id: units[i].business_detail?.identification_number || "",
                                            supplier_tax_id: units[i].business_detail?.dit_number || "",
                                            supplier_vat_id: units[i].business_detail?.vat_number || "",
                                            supplier_business_register_folder: units[i].business_detail?.business_register_folder,
                                            supplier_street: units[i].business_detail?.billing_address?.street || "",
                                            supplier_zip: units[i].business_detail?.billing_address?.postcode || "",
                                            supplier_city: units[i].business_detail?.billing_address?.city || "",
                                            supplier_country: units[i].business_detail?.billing_address?.country || "",
                                            supplier_email: units[i]?.contact_info?.emails?.length > 0 ? units[i]?.contact_info?.emails[0].email : "",
                                            supplier_phone: units[i]?.contact_info?.phones?.length > 0 ? units[i]?.contact_info?.phones[0].phone : "",
                                            tax_mode: units[i]?.business_detail?.tax_mode || 1
                                        }
    
                                        if( defaultBankAccount !== undefined ){
                                            supplierData.bank_iban = defaultBankAccount.iban
                                            supplierData.bank_swift_bic = defaultBankAccount.swift_code
                                        }
                                        setForm(prevState => ({...prevState, ...supplierData}))
                                        // setForm(prevState => ({...prevState, supplier_name: request.response[0].business_detail?.name}))
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            setFetching(false)
        }

        fetchReciever()
        fillForm()
        // eslint-disable-next-line
    }, [])

    // fetch items if exists
    useEffect(() => {
        async function fetchInvoiceItems(){
            if( invoice !== undefined ){
                setLoadingItems(true)
                const request = await requests.get(API.INVOICES + "items/?connection="+connection.connection_id + "&account_ids=" + journal.id + "&invoice__id=" + invoice.id)
                
                if( request.status === 200 ){
                    if( request.response.length > 0 ){
                        let itemList = []
                        for (let i = 0; i < request.response.length; i++) {
                            itemList.push({
                                id: request.response[i].id,
                                invoice: invoice.id,
                                service_type: request.response[i].service_type,
                                amount: request.response[i].amount,
                                code: request.response[i].code,
                                name: request.response[i].name,
                                unit: request.response[i].unit,
                                unit_price: request.response[i].unit_price,
                                tax_rate: request.response[i].tax_rate,
                                discount: request.response[i].discount,
                                note: request.response[i].note,
                                processing: false,
                                error: null
                            });
                            
                        }
                        setItems(itemList)
                        // setWithItems(true)
                    }
                }
                setLoadingItems(false)
            }
        }

        fetchInvoiceItems()
        // eslint-disable-next-line
    }, [invoice])

    useEffect(() => {
        if( dueDays > 0 ){
            let date = moment().format('YYYY-MM-DD')
            if( form.date_issue !== "" && form.date_issue !== null && form.date_issue !== undefined ){
               date = form.date_issue 
            }

            setForm(prevState => {
                return {
                    ...prevState,
                    date_due: moment(date, "YYYY-MM-DD").add(dueDays, 'days').format('YYYY-MM-DD')
                }
            })
        }
    }, [form.date_issue, dueDays])

    useEffect(() => {
        if ( invoice?.id ){
            let dayDifference = 14
            let date = moment().format('YYYY-MM-DD')
            if( invoice.date_issue !== "" && invoice.date_issue !== null && invoice.date_issue !== undefined ){
               date = invoice.date_issue 
            }

            const issued = moment(date)
            let due = null
            if( invoice.date_due !== null && invoice.date_due !== undefined && invoice.date_due !== "" ){
                due = moment(invoice.date_due)
                dayDifference = due.diff(issued, 'days')
                setDueDays(dayDifference)
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if ( (invoice === null || invoice === undefined || invoice?.id === undefined) && document === "recieved_invoice" ){
            // check if vs is filled
            if (form.variable_symbol === "" || form.variable_symbol !== form.number) {
                setForm(prev => ({...prev, variable_symbol: form.number}))
            }
        }
        // eslint-disable-next-line
    }, [form.number])

    // eslint-disable-next-line
    const [isProcessing, setIsProcessing] = useState(false)

    const onItemFormAdd = () => {
        const determinateTax = (tax_mode) => {
            if (parseInt(tax_mode) === 1) return "20.00"
            if (parseInt(tax_mode) === 3) return "0"
            
            return "0"
        }

        setItems(prevState => [...prevState, {
            id: 0,
            invoice: invoice ? invoice.id : 0,
            service_type: 1,
            amount: 1,
            code: null,
            name: null,
            unit: null,
            unit_price: null,
            tax_rate: determinateTax(form.tax_mode),
            discount: 0,
            note: "",
            processing: false,
            error: null
        }])
    }

    const onRecordChange = (index, updatedKey, value) => {
        setItems(prevState => prevState.filter((record, key) => {
            if( index === key ){ record[updatedKey] = value }
            return record
        }))
    }

    const onRemove = async (item, index) => {
        onRecordChange(index, "processing", true)
        if( item.id === 0 ){
            setItems(prevState => prevState.filter((stateItem, key) => key !== index))
        } else {
            setItems(prevState => prevState.filter(stateItem => stateItem.id !== item.id))
            setItemsToRemove(prevState => [
                ...prevState,
                item.id
            ])
        }
        onRecordChange(index, "processing", false)
    }

    const round = (value) => {
        value = parseFloat(value)
        return (rounding === 0 ? _.round(value, 2) : (rounding === 1 ? _.ceil(value, 2) : _.floor(value, 2)))
    }

    const calculateTotalForItem = (amount, unitPrice, taxMode, taxRate) => {
        let total = 0
        let parsedAmount = parseFloat(amount)
        let parsedUnitPrice = parseFloat(unitPrice)
        if( !isNaN(parsedAmount) && !isNaN(parsedUnitPrice) ) {
            total = round(parseFloat(parsedAmount * parsedUnitPrice), 2)

            // check tax mode (VAT PAYER)
            if( (parseInt(taxMode) === 1 || parseInt(taxMode) === 3) && taxRate > 0 ){
                let tax = round((total / 100) * parseFloat(taxRate), 2)
                total = parseFloat(tax) + parseFloat(total)
            }
        }

        return thousandsSeparators(parseFloat(total).toFixed(2))
    }

    const calculateTotalForInvoice = (items, taxMode) => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let parsedAmount = parseFloat(items[i].amount)
            let parsedUnitPrice = parseFloat(items[i].unit_price)

            if( !isNaN(parsedAmount) && !isNaN(parsedUnitPrice) ) {
                let totalForItem = round(parseFloat(parsedAmount * parsedUnitPrice), 2)

                // check tax mode (VAT PAYER)
                if( (parseInt(taxMode) === 1 || parseInt(taxMode) === 3) && parseFloat(items[i].tax_rate) > 0 ){
                    let tax = round((totalForItem / 100) * parseFloat(items[i].tax_rate), 2)
                    totalForItem = parseFloat(tax) + parseFloat(totalForItem)
                }

                total += totalForItem
            }

        }

        return thousandsSeparators(parseFloat(total).toFixed(2))
    }

    const isValidTaxRate = (taxRate) => {
        if( isNaN(parseFloat(taxRate)) || taxRate === "" || parseFloat(taxRate) < 0 ) return false 

        return true
    }

    const setDotSeparator = (input) => {
        return input.replace(',', '.')
    }

    const handleScanUpload = async (requestInvoice) => {
        if( scannedFile !== null && scannedFile !== undefined ){
            setUploading(true)
            const file = scannedFile
            const formData = new FormData()

            let newName = requestInvoice.response.id + "__" + requestInvoice.response.number + ".pdf" // ex. 123__2023001.pdf
            const scanFile = new File([file], newName, { type: file.type })
            formData.append("scan", scanFile)
            
            const requestFileUpload = await requests.post(API.INVOICES + requestInvoice.response.id + "/scan/upload/?connection=" + connection.connection_id, formData)
            if( requestFileUpload.status === 200 ){
                setForm(prevState => ({
                    ...prevState,
                    scan: requestFileUpload.response.scan
                }))
            }
            if( requestFileUpload.status === 400 ){
                setUploadError(requestFileUpload.response)
            }
            setUploading(false)

            return requestFileUpload.status
        }

        return null
    }

    const BaseCalculator = ({ item, index }) => {

        const CalculatorForm = ({ onClose }) => {
            const { t } = useTranslation();
            const [taxRate, setTaxRate] = useState(item.tax_rate)
            const [unitPrice, setUnitPrice] = useState("")
            const [taxPrice, setTaxPrice] = useState("")
            const [totalPrice, setTotalPrice] = useState("")

            const onConfirm = () => {
                onRecordChange(index, "tax_rate", taxRate)
                onRecordChange(index, "unit_price", unitPrice)
                onClose()
            }

            useEffect(() => {
                setUnitPrice("")
                setTaxPrice("")
                setTotalPrice("")
            }, [taxRate])

            const onCalculation = (field, value) => {
                if( field === "unitPrice" ){ // perform calculation with unitPrice and tax
                    setUnitPrice(value)
                    const tax = (parseFloat(value) / 100) * parseFloat(taxRate)
                    setTaxPrice(parseFloat(tax).toFixed(2))
                    setTotalPrice(parseFloat(parseFloat(tax) + parseFloat(value)).toFixed())
                } else if( field === "taxPrice" ){ // perform calculation with taxPrice and tax
                    setTaxPrice(value)
                    const base = (parseFloat(value) / parseFloat(taxRate)) * 100
                    setUnitPrice(parseFloat(base).toFixed(2))
                    setTotalPrice(parseFloat(parseFloat(base) + parseFloat(value)).toFixed(2))
                } else if( field === "totalPrice" ){ // perform calculation with totalPrice and tax
                    setTotalPrice(value)
                    const base = (parseFloat(value) / (100 + parseFloat(taxRate))) * 100
                    setUnitPrice(parseFloat(base).toFixed(2))
                    setTaxPrice(parseFloat(parseFloat(value) - parseFloat(base)).toFixed(2))
                }
            }

            return (
                <Form>
                    <Message
                        style={{ borderRadius: 0 }}
                        info
                        header={ t('hint_calculate_base_unit_price') }
                        content={ t('hint_enter_values_to_calculate') }
                    />
                    <Divider/>
                    <Form.Field>
                        <label> { t('tax_rate') } </label>
                        <Button.Group size="small" basic style={{ borderRadius: 0 }}>
                            <Button type="button" 
                                content={"0 %"}
                                active={parseFloat(taxRate) === 0}
                                style={{ fontWeight: parseFloat(taxRate) === 0 ? "bold" : "normal" }}
                                onClick={ () => setTaxRate(0)} 
                            />
                            <Button type="button" 
                                content={"10 %"}
                                active={parseFloat(taxRate) === 10}
                                style={{ fontWeight: parseFloat(taxRate) === 10 ? "bold" : "normal" }}
                                onClick={ () => setTaxRate(10) }
                            />
                            <Button type="button"
                                content={"20 %"}
                                active={parseFloat(taxRate) === 20}
                                style={{ fontWeight: parseFloat(taxRate) === 20 ? "bold" : "normal" }}
                                onClick={ () => setTaxRate(20) }
                            />
                            <Button type="button" style={{ padding: 0, cursor: "normal"}}>
                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                    <FlexItem>
                                        <SuperField as="input" 
                                            size="small" 
                                            placeholder={ t("custom") } 
                                            style={{ width: "5rem"}}
                                            value={ [0, 10, 20].includes(parseFloat(taxRate)) ? "" : taxRate }
                                            onChange={(e, { value }) => {
                                                if( value === "" ){
                                                    setTaxRate(20)
                                                } else if( value === 0 ){
                                                    setTaxRate(0)
                                                } else if( value > 0 ){
                                                    setTaxRate(setDotSeparator(value))
                                                }
                                            }}
                                        />
                                    </FlexItem>
                                    <FlexItem textAlign="center">
                                        <div style={{ margin: "0 0.5rem" }}> % </div>
                                    </FlexItem>
                                </FlexRow>
                            </Button>
                        </Button.Group>
                    </Form.Field>
                    <Divider/>
                    <Form.Group widths="equal">
                        <SuperField as="input" 
                            value={ unitPrice }
                            size="small"
                            disabled={!isValidTaxRate(taxRate)}
                            label={t('unit_price_without_tax')}
                            onChange={(e, { value }) => onCalculation("unitPrice", setDotSeparator(value))}
                        />
                        <SuperField 
                            value={ taxPrice }
                            as="input"
                            size="small" 
                            label={t('tax')}
                            disabled={!isValidTaxRate(taxRate) || parseFloat(taxRate) === 0} 
                            onChange={(e, { value }) => onCalculation("taxPrice", setDotSeparator(value))}
                        />
                        <SuperField 
                            value={ totalPrice }
                            as="input"
                            size="small" 
                            label={t('unit_price_with_tax')}
                            disabled={!isValidTaxRate(taxRate) || parseFloat(taxRate) === 0} 
                            onChange={(e, { value }) => onCalculation("totalPrice", setDotSeparator(value))}
                        />
                    </Form.Group>
                    <p style={{ fontWeight: "bold" }}>{ t('hint_enter_at_least_one_value') }.</p>
                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <Button 
                            size="small"
                            primary
                            type="button" 
                            content={t('confirm')} 
                            onClick={() => onConfirm()}
                        />
                    </Form.Field>
                </Form>
            )
        }

        return (
            <SuperDuperModal 
                size="small"
                style={{ marginTop: "2rem" }}
                trigger={
                    <SemanticIcon name="calculator" link>
                        <Popup
                            trigger={
                                <SemanticIcon style={{ paddingTop: "0.8rem", paddingLeft: "0.3rem", opacity: 0 }} name='calculator'/>
                            }
                            content={t('hint_calculate_base_unit_price')}
                        />
                    </SemanticIcon>
                }
                content={<CalculatorForm/>}
            />
        )
    }

    const handleSubmit = async () => {
        setErrors({})
        setIsProcessing(true);
        let invoiceData = form
        delete invoiceData.account // we are removing in case of nested object is used from API
        delete invoiceData.group
        delete invoiceData.price
        delete invoiceData.price_to_pay
        delete invoiceData.scan
        delete invoiceData.ref_invoice
        invoiceData.account = invoice ? invoice.account.id : journal.account_id
        invoiceData.round_up = rounding === 0 ? null : (rounding === 1 ? true : false)
        invoiceData.ref_order_number = orderRef

        let requestInvoice = undefined
        let status = null
        let invoiceID = 0

        // eslint-disable-next-line
        let deletedItems = 0
        if( itemsToRemove.length > 0 ) {
            for (let i = 0; i < itemsToRemove.length; i++) {
                const request = await requests.del(API.INVOICES + "items/" + itemsToRemove[i] + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id)
                if( request.status === 204 ){
                    deletedItems += 1 // TODO: perform verification check for deleted items and set error if something went wrong
                }
            }
        }

        if( form.id !== 0 ){
            requestInvoice = await requests.patch(API.INVOICES + form.id + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id, invoiceData)
        } else {
            requestInvoice = await requests.post(API.INVOICES + "?connection=" + connection.connection_id + "&account_ids=" + journal.id, invoiceData)
        }

            
        status = requestInvoice?.status || null

        if( requestInvoice.status === 400 ){
            setErrors(requestInvoice.response)
        }

        if( requestInvoice?.status === 200 || requestInvoice?.status === 201 ){
            invoiceID = requestInvoice?.response?.id
            if ( requestInvoice?.status === 201 ) {
                setForm(prev => ({...prev, id: requestInvoice?.response?.id}))
            }

            for (let i = 0; i < items.length; i++) {
                if( items[i].id === 0 && (items[i].name === "" || items[i].name === null) && 
                    (items[i].amount === "" || items[i].amount === null) && 
                    (items[i].unit_price === "" || items[i].unit_price === null)) {
                    continue;
                }

                let data = {
                    invoice: parseInt(requestInvoice.response.id),
                    service_type: parseInt(items[i].service_type),
                    amount: parseFloat(items[i].amount),
                    code: items[i].code,
                    name: items[i].name,
                    unit: items[i].unit,
                    unit_price: parseFloat(items[i].unit_price),
                    tax_rate: parseFloat(items[i].tax_rate),
                    discount: parseFloat(items[i].discount),
                    note: items[i].note
                }


                onRecordChange(i, "processing", true)
                let request = undefined
                if( items[i].id === 0 ){ // create item
                    request = await requests.post(API.INVOICES + "items/?connection=" + connection.connection_id + "&account_ids=" + journal.id, data)
                } else { // update item
                    request = await requests.put(API.INVOICES + "items/" + items[i].id + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id, data)
                    
                }
                
                if( request !== undefined ){
                    if( request.status === 201 ){
                        onRecordChange(i, "id", request.response.id)
                    }
                    
                    if( request.status === 201 || request.status === 200 ){
                        // update item state
                        onRecordChange(i, "error", null)
                    } else {
                        // set error
                        onRecordChange(i, "error", request.response)
                    }
                }

                onRecordChange(i, "processing", false)
            }

            const uploadFile = await handleScanUpload(requestInvoice)

            if( uploadFile === 200 || scannedFile === null ){
                // when everything is OK
                if( items.filter(item => item.error !== null).length === 0 ){
                    // fetch invoice by ID:
                    const getInvoice = await requests.get(API.INVOICES + invoiceID + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id)
                    if (getInvoice.status === 200) {
                        if ( status === 201 ) {
                            setTotal(prev => prev + 1)
                            setData(prev => [getInvoice.response ,...prev])
                        } else if (status === 200) {
                            setData(prev => {
                                if (prev.find(invoiceObj => invoiceObj.id === invoiceID) !== undefined) {
                                    return prev.map(invoiceItem => {
                                        if (invoiceItem.id === invoiceID) {
                                            invoiceItem = getInvoice.response
                                        }
                
                                        return invoiceItem
                                    })
                                } else {
                                    return [getInvoice.response ,...prev]
                                }
                            })
                        }

                        // handle reference update:
                        if ( orderRef !== null || orderRef !== "" || orderRef !== undefined) {
                            const invoiceObj = getInvoice.response
                            let invoice_reference = null

                            invoice_reference = `${invoiceObj.is_issued ? "income" : "expense"};${invoiceObj.id};${invoiceObj.number};VS${invoiceObj.variable_symbol};${invoiceObj.status};${invoiceObj.date_issue};${invoiceObj.date_supply};${invoiceObj.date_due};${invoiceObj.date_paid}`

                            const refs = orderRef.split("ref:")
                            const clearedRefs = refs.filter(item => item !== "")
                            let total = clearedRefs.length
                            
                            for (let i in clearedRefs) {
                                if (clearedRefs[i] !== "") {
                                    let refParts = clearedRefs[i].split("__")
                                    const request = await requests.patch(API.ORDERS + "fulfillment_edit/" + refParts[1] + "/", { invoice_reference: invoice_reference })
                                    if (request.status === 200) {
                                        total = total - 1
                                    }
                                }
                            }

                            if (total > 0){
                                toast({
                                    type: 'error',
                                    icon: 'warning circle',
                                    description: t('unable_to_update_order_references'),
                                    animation: 'bounce',
                                    time: 5000,
                                })
                            }
                        }
                    }
                }
            }

        }
        setIsProcessing(false);
    }

    const isValidSupplier = () => {
        // if( form.status !== 4 ){
            if( errors?.supplier_name ) return false
            if( errors?.supplier_registration_id ) return false
            if( errors?.supplier_tax_id ) return false
            if( errors?.supplier_vat_id ) return false
            if( errors?.supplier_business_register_folder ) return false
            if( errors?.supplier_street ) return false
            if( errors?.supplier_zip ) return false
            if( errors?.supplier_city ) return false
            if( errors?.supplier_country ) return false
            if( errors?.supplier_email ) return false
            if( errors?.supplier_phone ) return false
        // }

        return true
    }

    const isValidCustomer = () => {
        // if( form.status !== 4 ){
            if( errors?.customer_name ) return false
            if( errors?.customer_registration_id ) return false
            if( errors?.customer_tax_id ) return false
            if( errors?.customer_vat_id ) return false
            if( errors?.customer_business_register_folder ) return false
            if( errors?.customer_street ) return false
            if( errors?.customer_zip ) return false
            if( errors?.customer_city ) return false
            if( errors?.customer_country ) return false
            if( errors?.customer_email ) return false
            if( errors?.customer_phone ) return false
        // }

        return true
    }

    return (
        <Segment loading={fetching} style={{ 
            padding: 0, 
            margin: 0, 
            background: "transparent", 
            boxShadow: "none", 
            border: "none", 
            paddingTop: fetching ? "2rem" : 0,
            paddingBottom: fetching ? "2rem" : 0,
        }}>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column computer={8}>
                        <Icon name="albums-outline" style={{ marginRight: "0.2rem" }}/> <strong style={{ position: "relative", top: "-0.1rem" }}>{ journal.name }</strong> 
                        <span style={{ paddingLeft: "1rem" }}>
                            <Icon name="document-outline" style={{ marginRight: "0.2rem" }}/> <strong style={{ position: "relative", top: "-0.1rem" }}>{ invoice !== undefined ? invoice.invoice_type_display : t('invoice') }</strong>
                        </span>
                        <span style={{ paddingLeft: "1rem", position: "relative", top: "-0.1rem" }}>{ t('rounding') }: 
                            <sup>
                                <Popup 
                                    position="center bottom"
                                    content={t('info_rounding_hint')}
                                    trigger={
                                        <Icon name="information-circle-outline" style={{ marginLeft: "0.2rem" }}/>
                                    } 
                                />
                            </sup>
                        </span>
                        <Dropdown
                            style={{ fontWeight: "normal", paddingLeft: "0.5rem", position: "relative", top: "-0.1rem" }}
                            inline
                            simple
                            options={[
                                { key: 1, value: 0, text: t('normal') },
                                { key: 2, value: 1, text: t('round_up') },
                                { key: 3, value: 2, text: t('round_down') },
                            ]}
                            value={rounding}
                            onChange={(e, { value }) => setRounding(value)}
                        />
                        
                    </Grid.Column>
                    <Grid.Column computer={8} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right"}}>
                        <Dropdown
                            basic
                            className="superfield"
                            size="small"
                            as={Button}
                            style={{
                                top: "-0.1rem",
                                marginRight: "1rem",
                                borderRadius: "10px",
                                fontWeight: "normal",
                                position: "relative",
                            }}
                            inline
                            simple
                            options={[
                                { key: 4, value: 4, text: t('draft') },
                                { key: 1, value: 1, text: t('pending') },
                                { key: 2, value: 2, text: t('canceled') },
                                { key: 3, value: 3, text: t('paid') },
                            ]}
                            value={form.status}
                            onChange={(e, { value }) => setForm({
                                ...form,
                                status: value
                            })}
                        />
                        <Form.Field>
                            <SuperField as="input"
                                inline
                                required
                                autoFocus
                                size="small"
                                icon='file text alternate'
                                iconPosition='left'
                                className={"inline-input" + (errors?.number && ' error') }
                                style={{ background: "var(--danger)", position: "relative", top: "-0.1rem" }}
                                placeholder={t('invoice_number')}
                                value={form.number}
                                onChange={(e, { value }) => setForm({
                                    ...form,
                                    number: value,
                                })}
                            />
                            { errors?.number && <p style={{ color: "var(--danger)" }}>{ errors?.number }</p> }
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            <Form onSubmit={handleSubmit}>
                <Grid>
                    <Grid.Row columns={2} style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Message style={{ background: "transparent", border: "none", boxShadow: "none", padding: "1rem", paddingLeft: 0 }}>
                                <Header as="h3" content={t('supplier')}/>
                                <span style={{ fontSize: "1.2rem" }}>
                                    { form.supplier_name || t('not_specified') }
                                    { !isValidSupplier() && <Icon name="alert-outline" style={{ marginLeft: "0.5rem", color: "var(--danger)" }}/>}
                                </span>

                                <br/>
                                <SuperDuperModal 
                                    closeIcon={false}
                                    closeOnDimmerClick={false}
                                    closeOnDocumentClick={false}
                                    size="small"
                                    header={t('supplier')}
                                    content={<DetailsForm document={document} form={form} setForm={setForm} type={"supplier"} errors={errors}/>}
                                    trigger={
                                        <span className="ref-link" style={{ color: "var(--primary)" }}>
                                            { t('manage_supplier_details') }
                                        </span>
                                    }
                                />

                                <>
                                    <br/>
                                    <br/>

                                    <Form.Group style={{ paddingTop: "0.5rem" }}>
                                        <SuperField as="input"
                                            fluid
                                            size="small"
                                            width={canViewOrderRefFeature  ? "11" : "16"}
                                            label={t('order_number_full')}
                                            value={form.order_number}
                                            error={errors?.order_number}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                order_number: value
                                            })}
                                        />
                                        { (canViewOrderRefFeature) &&
                                            <Form.Field width="5" style={{ paddingTop: "1.7rem" }}>
                                                <InvoiceOrderRef
                                                    isIssued={form.is_issued}
                                                    journal={journal}
                                                    setForm={setForm}
                                                    invoiceID={form.id}
                                                    taxMode={form.tax_mode}
                                                    setItems={setItems}
                                                    orderRef={orderRef}
                                                    setOrderRef={setOrderRef}
                                                />
                                            </Form.Field>
                                        }
                                    </Form.Group>

                                    <Form.Group>
                                        <SuperField as="input"
                                            style={{ marginBottom: "0.5rem" }}
                                            size="tiny"
                                            width="11"
                                            label={ <div style={{ marginTop: "1rem", fontWeight: "bold" }}> { t('iban') } </div> }
                                            value={form.bank_iban}
                                            error={errors?.bank_iban}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                bank_iban: value
                                            })}
                                        />
                                        <SuperField as="input"
                                            style={{ marginBottom: "0.5rem" }}
                                            size="tiny"
                                            width="5"
                                            label={ <div style={{ marginTop: "1rem", fontWeight: "bold" }}> { t('swift') } </div> }
                                            value={form.bank_swift_bic}
                                            error={errors?.bank_swift_bic}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                bank_swift_bic: value
                                            })}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <SuperField as="input"
                                            size="tiny"
                                            width="6"
                                            label={ t('variable_symbol') }
                                            value={form.variable_symbol}
                                            error={errors?.variable_symbol}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                variable_symbol: value
                                            })}
                                        />
                                        <SuperField as="input"
                                            size="tiny"
                                            width="5"
                                            label={ t('constant_symbol') }
                                            value={form.constant_symbol}
                                            error={errors?.constant_symbol}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                constant_symbol: value
                                            })}
                                        />
                                        <SuperField as="input"
                                            size="tiny"
                                            width="5"
                                            label={ t('specific_symbol') }
                                            value={form.specific_symbol}
                                            error={errors?.specific_symbol}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                specific_symbol: value
                                            })}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <SuperField as="choice"
                                            size="small"
                                            width="5"
                                            clearable={false}
                                            label={<div style={{ marginBottom: "0.3rem", fontWeight: "bold" }}> { t('payment_method') } </div>}
                                            customOptions={[
                                                { key: 1, value: 1, text: t('payment_order') },
                                                { key: 2, value: 2, text: t('cash') },
                                                { key: 3, value: 3, text: t('cash_on_delivery') },
                                                { key: 4, value: 4, text: t('credit') },
                                                { key: 5, value: 5, text: t('card_payment') },
                                            ]}
                                            value={form.payment_method || 1}
                                            error={errors?.payment_method}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                payment_method: value
                                            })}
                                        />

                                        <SuperField as="input"
                                            style={{ position: "relative", top: "0.4rem" }}
                                            size="small"
                                            id="delivery_methods"
                                            width="11"
                                            label={ t('delivery_method') }
                                            error={errors?.delivery_method}
                                            value={form.delivery_method}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                delivery_method: value
                                            })}
                                        />
                                    </Form.Group>
                                </>
                            </Message>
                        </Grid.Column>
                        <Grid.Column style={{ padding: 0 }}>
                            <Message style={{ borderRadius: 0, background: "transparent", border: "none", boxShadow: "none", padding: "1rem", paddingRight: 0 }}>
                                <Header as="h3" content={t('account')}/>
                                <span style={{ fontSize: "1.2rem" }}>
                                    { form.customer_name || t('not_specified') }
                                    { !isValidCustomer() && <Icon name="alert-outline" style={{ marginLeft: "0.5rem", color: "var(--danger)" }}/>}
                                </span>
                                <br/>
                                <SuperDuperModal 
                                    closeIcon={false}
                                    closeOnDimmerClick={false}
                                    closeOnDocumentClick={false}
                                    size="small"
                                    header={t('account')}
                                    content={<DetailsForm document={document} form={form} setForm={setForm} type={"account"} errors={errors}/>}
                                    trigger={
                                        <span className="ref-link" style={{ color: "var(--primary)" }}>
                                            { t('manage_account_details') }
                                        </span>
                                    }
                                />

                                <>
                                    <Form.Field style={{ marginTop: "2rem" }}>
                                        <label> { t('due_days') } </label>
                                        <Button.Group size="small" basic style={{ borderRadius: 0 }}>
                                            <Button type="button" active={dueDays === 7} style={{ fontWeight: dueDays === 7 ? "bold" : "normal" }} onClick={ () => setDueDays(7) } content={"7" + t('days_short')}/>
                                            <Button type="button" active={dueDays === 14} style={{ fontWeight: dueDays === 14 ? "bold" : "normal" }} onClick={ () => setDueDays(14) } content={"14" + t('days_short')}/>
                                            <Button type="button" active={dueDays === 21} style={{ fontWeight: dueDays === 21 ? "bold" : "normal" }} onClick={ () => setDueDays(21) } content={"21" + t('days_short')}/>
                                            <Button type="button" active={dueDays === 28} style={{ fontWeight: dueDays === 28 ? "bold" : "normal" }} onClick={ () => setDueDays(28) } content={"28" + t('days_short')}/>
                                            <Button type="button" style={{ padding: 0, cursor: "normal"}}>
                                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                                    <FlexItem>
                                                        <SuperField as="input" 
                                                            size="small" 
                                                            placeholder={ t("custom") } 
                                                            style={{ width: "5rem"}}
                                                            value={ [7, 14, 21, 28].includes(parseInt(dueDays)) ? "" : dueDays }
                                                            onChange={(e, { value }) => {
                                                                if( value === "" || value === 0 ){
                                                                    setDueDays(14)
                                                                }

                                                                if( value > 0 ){
                                                                    setDueDays(parseInt(value))
                                                                }
                                                            }}
                                                        />
                                                    </FlexItem>
                                                    <FlexItem textAlign="center">
                                                        <div style={{ margin: "0 0.5rem" }}> { t('days') } </div>
                                                    </FlexItem>
                                                </FlexRow>
                                            </Button>
                                        </Button.Group>
                                    </Form.Field>
                                    <Form.Group style={{ marginTop: "1.8rem" }} widths={"equal"}>
                                        <SuperField as="datepicker"
                                            style={{ padding: 0 }}
                                            size="small"
                                            width="16"
                                            label={ t('date_of_issue') }
                                            placeholder={ t('date_of_issue') }
                                            value={(form.date_issue !== null && form.date_issue !== undefined && form.date_issue !== "") ? form.date_issue : moment().format("YYYY-MM-DD")}
                                            iconPosition="left"
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                date_issue: value
                                            })}
                                        />

                                        <SuperField as="datepicker"
                                            style={{ padding: 0 }}
                                            width="16"
                                            size="small"
                                            label={ t('date_of_supply') }
                                            placeholder={ t('date_of_supply') }
                                            value={(form.date_supply !== null && form.date_supply !== undefined && form.date_supply !== "") ? form.date_supply : moment().format("YYYY-MM-DD")}
                                            iconPosition="left"
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                date_supply: value
                                            })}
                                        />

                                        <SuperField as="datepicker"
                                            // className="invisible-input-border"
                                            style={{ opacity: 1, padding: 0 }}
                                            width="16"
                                            size="small"
                                            label={ t('date_of_due') }
                                            value={form.date_due}
                                            onChange={(e, { value }) => setForm({
                                                ...form,
                                                date_due: value
                                            })}
                                            iconPosition="left"
                                        />
                                    </Form.Group>

                                    <SuperField as="textarea"
                                        size="small"
                                        rows="4"
                                        width="15"
                                        label={t('note')}
                                        value={form.note}
                                        onChange={(e, { value }) => setForm({
                                            ...form,
                                            note: value
                                        })}
                                    />
                                </>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider/>

                <Segment loading={loadingItems} style={{ 
                    padding: 0, 
                    margin: 0, 
                    background: "transparent", 
                    boxShadow: "none", 
                    border: "none", 
                    minHeight: "10rem",
                }}>
                    { loadingItems && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_invoice_items') } </p> }
                    { !loadingItems && 
                        <>
                            <Grid>
                                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                                    <Grid.Column computer={4} tablet={4} mobile={16} style={{ padding: 0 }}>
                                        <Header as="h3" content={ t('invoice_items') }/>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" computer={12} tablet={12} mobile={16} style={{ padding: 0 }}>
                                        <Form.Group style={{ float: "right" }}>
                                            <SuperField as="choice"
                                                inline
                                                search
                                                clearable={false}
                                                style={{ width: "200px" }}
                                                size="tiny"
                                                type="currency_codes"
                                                error={errors?.currency}
                                                label={<span style={{ fontWeight: "bold"}}>{ t('currency') }</span>}
                                                value={ form.currency || "EUR" }
                                                onChange={(e, { value }) => setForm({ ...form, currency: value })}
                                            />
                                            <SuperField as="choice"
                                                inline
                                                clearable={false}
                                                style={{ width: "250px" }}
                                                size="tiny"
                                                label={<span style={{ fontWeight: "bold"}}>{ t('mode') }</span>}
                                                customOptions={[
                                                    { key: 1, value: 1, text: t('vat_payer')},
                                                    { key: 2, value: 2, text: t('not_vat_payer')},
                                                    { key: 3, value: 3, text: t('transfer_of_tax_liability')},
                                                ]}
                                                value={ form.tax_mode || 1 }
                                                error={errors?.tax_mode}
                                                onChange={(e, { value }) => {
                                                    if (value === 3) {
                                                        // set tax_rate for each item to 0
                                                        setItems(prev => prev.filter(item => {
                                                            item.tax_rate = 0

                                                            return item
                                                        }))
                                                    } else if (value === 1) {
                                                        // set tax_rate for each item to 20%
                                                        setItems(prev => prev.filter(item => {
                                                            item.tax_rate = "20.00"

                                                            return item
                                                        }))
                                                    }

                                                    setForm({ ...form, tax_mode: value })
                                                }}
                                            />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <FlexRow fontSize="0.9" background="transparent" padding="1rem 0" borders>
                                <FlexItem>
                                    <Form.Group style={{ marginBottom: 0 }}>
                                        <Form.Field style={{ paddingLeft: "1rem", fontWeight: "bold", width: "70px" }} content={t('code')}/>
                                        <Form.Field width={5} style={{ marginLeft: "1rem", fontWeight: "bold" }} content={<> { t('name') } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/>
                                        <Form.Field width={2} style={{ fontWeight: "bold" }} content={<> { t('amount') } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/>
                                        <Form.Field width={1} style={{ fontWeight: "bold"}} content={t('price_unit')}/>
                                        <Form.Field width={3} style={{ fontWeight: "bold" }} content={<> { t('unit_price') } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/>
                                        { (form.tax_mode === 1 || form.tax_mode === 3) && <Form.Field width={2} style={{ fontWeight: "bold" }} content={<> { t('tax_rate') + " (%)" } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/> }
                                        <Form.Field width={3} style={{ fontWeight: "bold" }} content={t('final_price')}/>
                                    </Form.Group>
                                </FlexItem>
                                <FlexItem basis="10%"/>
                            </FlexRow>

                            <EmptyRow length={items.length}/>

                            { items.map((item, key) => (
                                <FlexRow key={key} fontSize="0.9" background="transparent" padding="1rem 0" borders>
                                    <FlexItem>
                                        <Form.Group style={{ marginBottom: "0.5rem" }}>
                                            <SuperField size="small" as="input" style={{ width: "70px" }} value={item.code} onChange={(e, { value }) => onRecordChange(key, "code", value)}/>
                                            <SuperField size="small" as="input" error={item.error?.name ? true : false} width={5} value={item.name} onChange={(e, { value }) => onRecordChange(key, "name", value)}/>
                                            <SuperField size="small" as="input" error={item.error?.amount ? true : false} width={2} value={item.amount} onChange={(e, { value }) => onRecordChange(key, "amount", setDotSeparator(value))}/>
                                            <SuperField size="small" as="input" width={1} value={item.unit} onChange={(e, { value }) => onRecordChange(key, "unit", value)}/>
                                            { (form.tax_mode === 1 || form.tax_mode === 3) 
                                                ? 
                                                <>
                                                    <SuperField 
                                                        width={3}
                                                        as="input"
                                                        size="small"
                                                        error={item.error?.unit_price ? true : false}
                                                        value={item.unit_price}
                                                        icon={<BaseCalculator item={item} index={key}/>}
                                                        onChange={(e, { value }) => onRecordChange(key, "unit_price", setDotSeparator(value))}
                                                    />
                                                    <SuperField 
                                                        width={2}
                                                        as="input"
                                                        size="small"
                                                        error={item.error?.tax_rate ? true : false}
                                                        value={item.tax_rate}
                                                        onChange={(e, { value }) => onRecordChange(key, "tax_rate", setDotSeparator(value))}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <SuperField 
                                                        width={3}
                                                        as="input"
                                                        size="small"
                                                        error={item.error?.unit_price ? true : false}
                                                        value={item.unit_price}
                                                        onChange={(e, { value }) => onRecordChange(key, "unit_price", setDotSeparator(value))}
                                                    />
                                                </>
                                            }
                                            
                                            <Form.Field style={{ fontWeight: "bold", paddingTop: "0.5rem", fontSize: "1.2rem" }} width={3}>
                                                { calculateTotalForItem(item.amount, item.unit_price, form.tax_mode, item.tax_rate) } { form.currency } 
                                            </Form.Field>
                                        </Form.Group>
                                        {/* <Form.Group style={{ marginBottom: "0" }}>
                                            <SuperField width={10} size="small" label={ t("note") } as="input"/>
                                            <SuperField width={6} size="small" label={ t("discount") } as="input"/>
                                        </Form.Group> */}
                                    </FlexItem>
                                    <FlexItem basis="10%" textAlign="center">
                                        { item.processing 
                                            ? <Loader size="small" className='dark-loader' active inline/> 
                                            : 
                                            <>
                                                { item.error === null && 
                                                    <Icon 
                                                        name="close-outline" 
                                                        loading={item.processing} 
                                                        style={{ cursor: "pointer", color: "var(--danger)" }} 
                                                        onClick={() => onRemove(item, key)}
                                                    />
                                                }

                                                { item.error !== null && 
                                                    <Icon 
                                                        name="warning-outline" 
                                                        loading={item.processing} 
                                                        style={{ cursor: "pointer", color: "var(--danger)" }} 
                                                    />
                                                }
                                            </>
                                        }
                                    </FlexItem>
                                </FlexRow>
                            )) }

                            <Popup 
                                trigger={
                                    <Button type="button" style={{ background: "var(--dark)", color: "var(--white)", marginTop: "0.5rem" }} size="small" onClick={ () => onItemFormAdd() }>
                                        <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/>
                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                            { t('add_item') }
                                        </span>
                                    </Button>
                                }
                                content={t('hint_item_add')}
                            />
                        </>
                    }
                </Segment>

                <Divider/>

                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <SuperField as="input" 
                                type="file" 
                                width={12}
                                help="Allowed files: PDF"
                                label={ t('upload_scanned_file') }
                                onChange={(event) => {
                                    if (event.target.files.length > 0) {
                                        setScannedFile(event.target.files[0]);
                                    }
                                }}
                            />
                            { uploading && <div><Loader size="small" className='dark-loader' active inline/> { t('uploading') }... </div> }
                            { uploadError && <p style={{ color: "var(--danger)", fontWeight: "bold" }}> { uploadError?.scan } </p> }
                            { invoice?.scan && 
                                <a href={invoice.scan} target="_blank" rel="noopener noreferrer">
                                    { t('invoice') + " - " + invoice?.number + " (" + t('scan_version') + ")" }
                                </a>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field style={{ textAlign: "right", fontSize: "1.3rem" }}>
                                { t('total_price') } <br/>
                                <strong style={{ fontSize: "2rem", fontWeight: "bold" }}>
                                    { calculateTotalForInvoice(items, form.tax_mode) + " " + ( form.currency === undefined ? "EUR" : form.currency ) }
                                </strong>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                

                <Divider/>
                <Form.Group widths="equal">
                    <Form.Field>
                        { Object.keys(errors)?.length !== 0 &&
                            <p style={{ color: "var(--danger)", fontWeight: "bold", fontSize: "1.2rem", marginTop: "0.5rem" }}> 
                                { t('error_occured_please_check_your_filled_data_in_form') }
                            </p>
                        }
                    </Form.Field>
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose} disabled={isProcessing}/>
                        <ModalSubmit
                            disabled={isProcessing}
                            loading={isProcessing}
                            text={t('save')}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
};

export default Invoice;