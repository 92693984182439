import React from 'react';
//store
import { useHasPermissions } from '@helpers/hooks';
//components
import { Tab, Grid } from 'semantic-ui-react';
import PersonalInfoSection from './personal-tab/PersonalInfoSection';
// import ProfileInfoSection from './personal-tab/ProfileInfoSection';
import OtherInfoSection from './personal-tab/OtherInfoSection';
import BusinessDetailSection from './personal-tab/BusinessDetailSection';
import ExperienceSection from './personal-tab/ExperienceSection';
import EducationSection from './personal-tab/EducationSection';
import SkillSection from './personal-tab/SkillSection';
import LanguageSection from './personal-tab/LanguageSection';
import InterestSection from './personal-tab/InterestSection';
import FamilySection from './personal-tab/FamilySection';


const ProfileCardInfoTab = ({ data, setData, patchEndpoint }) => {
    const isManageable = useHasPermissions(["common.c_manage_user_employees", "common.c_manage_all_employees"]);

    return (
        <Tab.Pane style={{ border: "none", boxShadow: "none" }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} table={16} mobile={16}>
                        {/* <ProfileInfoSection data={data} setData={setData} patchEndpoint={patchEndpoint} /> */}
                        <PersonalInfoSection data={data} setData={setData} patchEndpoint={patchEndpoint} />
                        <OtherInfoSection data={data} setData={setData} patchEndpoint={patchEndpoint} />
                        <BusinessDetailSection data={data} setData={setData} patchEndpoint={patchEndpoint} />
                        <FamilySection result={data} setResult={setData} patchEndpoint={patchEndpoint} />
                        <ExperienceSection result={data} setResult={setData} isManageable={isManageable} patchEndpoint={patchEndpoint} />
                        <EducationSection result={data} setResult={setData} isManageable={isManageable} patchEndpoint={patchEndpoint} />
                        <SkillSection result={data} setResult={setData} isManageable={isManageable} patchEndpoint={patchEndpoint} />
                        <LanguageSection result={data} setResult={setData} isManageable={isManageable} patchEndpoint={patchEndpoint} />
                        <InterestSection result={data} setResult={setData} isManageable={isManageable} patchEndpoint={patchEndpoint} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Tab.Pane>
    );
};

export default ProfileCardInfoTab;