import React, {useState} from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
//components
import Icon from '@components/Icon';
import Address from '@components/forms/Address';
import CanView from '@components/perms/CanView';
import ConfirmModal from '@components/modals/ConfrimModal';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import { Divider, Grid, Header, Form } from 'semantic-ui-react';
// specific components
import BusinessDetailForm from '../forms/BusinessDetailForm';


const BusinessDetailSection = ({ data, setData, patchEndpoint }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0)

    const handleFreelanceRemoval = async (id) => {
        setView(0)
        const request = await requests.patch(API.EMPLOYEES + "remove_freelancer/?id=" + id)

        if (request.status === 200) {
            setData(request.response)
        }
    }

    return (
        <>
        <Form>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem textAlign="center">
                            <Header icon >
                                <Icon  name='briefcase-outline' style={{ fontSize: "5rem" }} />
                                <h3>{t('business_info')}</h3>
                            </Header>
                        </FlexItem>
                    </FlexRow>

                </Grid.Column>
                <Grid.Column computer="12" tablet="16" mobile="16">
                    <Grid style={{ padding: 0 }} >
                        <Grid.Row style={{ padding: 0 }} >
                            <Grid.Column only="computer" computer="16">
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}>
                                    <Icon name="briefcase-outline" style={{ marginRight: "0.5rem" }}/>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>{t('business_info')}</span>
                                </Header>
                                <Header floated="right" as="h4" style={{ marginBottom: 0 }}>
                                    { data.business_detail?.id !== undefined &&
                                        <CanView permissions={['common.c_manage_all_employees', 'company.c_manage_only_assigned_unit_employees']}>
                                            <span style={{ cursor: "pointer", marginRight: "1.5rem" }}>
                                                <ConfirmModal
                                                    description={t('are_you_sure_that_you_want_to_remove_business_details')}
                                                    onConfirm={() => handleFreelanceRemoval(data.id)}
                                                    button={
                                                        <span>
                                                            <span style={{ position: "relative", top: "-0.2rem", color: "var(--danger)" }}>{t('remove_business_details')}</span>
                                                        </span>
                                                    }
                                                />
                                            </span>
                                        </CanView>
                                    }
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('edit')}</span>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <Icon name="close-outline" style={{ fontSize: "1rem", color: "var(--danger)", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('cancel')}</span>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} only="mobile tablet" tablet="16" mobile="16">
                                <Header style={{ padding: 0 }} floated="left" as="h4">
                                    { data.business_detail?.id !== undefined &&
                                        <CanView permissions={['common.c_manage_all_employees', 'company.c_manage_only_assigned_unit_employees']}>
                                            <span style={{ cursor: "pointer", marginRight: "1.5rem" }}>
                                                <ConfirmModal
                                                    description={t('are_you_sure_that_you_want_to_remove_business_details')}
                                                    onConfirm={() => handleFreelanceRemoval(data.id)}
                                                    button={
                                                        <span>
                                                            <span style={{ position: "relative", top: "-0.2rem", color: "var(--danger)" }}>{t('remove_business_details')}</span>
                                                        </span>
                                                    }
                                                />
                                            </span>
                                        </CanView>
                                    }
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('business_info')}</span>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginLeft: "0.5rem" }}/>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('business_info')}</span>
                                            <Icon name="close-outline" style={{ fontSize: "1rem", marginLeft: "0.5rem", color: "var(--danger)" }}/>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                    { view === 1 ?
                        <BusinessDetailForm
                            data={data}
                            setView={setView}
                            patchEndpoint={patchEndpoint}
                            onConfirm={setData}
                        /> :
                    <Grid stackable>
                        <Grid.Row columns={4}>
                            <Grid.Column>
                                {t('company_name')} <br />
                                <strong>{data?.business_detail?.name || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('id_number')} <br />
                                <strong>{data?.business_detail?.identification_number || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('dit_number')} <br />
                                <strong>{data?.business_detail?.dit_number || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('vat_number')} <br />
                                <strong>{data?.business_detail?.vat_number || '~' }</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                {t('tax_mode')} <br />
                                <strong>{data?.business_detail?.tax_mode_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('executive_manager')} <br />
                                <strong>{data?.business_detail?.executive_manager || '~'}</strong>
                            </Grid.Column>
                            
                        </Grid.Row>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                {t('nace_code')} <br />
                                <strong>
                                    {
                                        data?.business_detail?.nace_code?.id 
                                        ? `${data?.business_detail?.nace_code?.code} ${data?.business_detail?.nace_code?.name}`
                                        : '~'
                                    }
                                </strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('register_folder')} <br />
                                <strong>{data?.business_detail?.business_register_folder || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="1">
                            <Grid.Column>
                                {t('business_activities')} <br />
                                <strong>{data?.business_detail?.business_activities || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                {t('billing_address')} <br />
                                <strong> <Address address={data?.business_detail?.billing_address}/></strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('shipping_address')} <br />
                                <strong> <Address address={data?.business_detail?.shipping_address}/></strong>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </Form>
        </>
    );
};

export default BusinessDetailSection;