import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
// store
import { API } from '@store/config'
import { icons } from '@icons'
import { routes } from '@routes'
import { requests } from '@helpers/requests'
// components
import Loading from '@components/general/Loading'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container, Form, Grid, Button, Header, Icon, Segment, Message, Divider } from 'semantic-ui-react'
import ContractsFieldGroup from '@components/forms/jobs/ContractsFieldGroup'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg'

const JobOfferForm = () => {
    const { t } = useTranslation()
    const params = useParams()
    const history = useHistory()

    const [fetchingIscos, setFetchingIscos] = useState(false)
    const [iscoCodes, setIscoCodes] = useState([])

    const [salary, setSalary] = useState({
        id: 0,
        salary_type: 1,
        min: '',
        max: '',
        currency: 'EUR',
        additional_info: '',
        variable_remuneration_ratio: 0,
        variable_remuneration_min_value: 0,
        variable_remuneration_max_value: 0,
        bonuses: [],
    })

    const [contract, setContract] = useState({
        id: 0,
        contract_type: 1,
        work_schedule: 1,
        work_shift: 1,
        seniority_level: '',
        work_level: '',
        salary: 0,
    })

    const [position, setPosition] = useState({
        id: 0,
        position_number: '',
        job_contract: null,
        title: '',
        description: '',
        is_external: false,
        work_factors: '',
        work_activity: '',
        group: '',
        job_family: '',
        level: '',
        isco: '',
        bonuses: [],
    })

    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        { name: t('jobs_positions'), icon: icons.JOBS, href: routes.JOBS_POSITIONS },
        { name: params.id === 0 ? t('create_job_offer') : position.title, icon: 'file', href: '' },
    ]

    const setPositionValues = (response, id) => {
        setPosition({
            ...position,
            id: id,
            position_number: response?.position_number || '',
            title: response?.title || '',
            description: response.description || '',
            job_contract: response?.job_contract?.id || '',
            work_factors: response?.work_factors || '',
            work_activity: response?.work_activity || '',
            isco: response?.isco?.id || '',
            group: response?.group?.id || '',
            level: response?.level?.id || '',
            job_family: response?.job_family?.id || '',
            is_external: response.is_external,
            bonuses: response.bonuses.map((item) => item.id),
            old_bonuses: response.bonuses.map((item) => item.id),
        })

        const salaryData = response?.job_contract?.salary
        const job_contract = response?.job_contract

        setSalary({
            id: salaryData?.id ? salaryData?.id : 0,
            salary_type: salaryData?.salary_type ? salaryData?.salary_type?.toString() : 1,
            min: salaryData?.min ? salaryData?.min : '',
            max: salaryData?.max ? salaryData?.max : '',
            currency: salaryData?.currency ? salaryData?.currency?.toString() : '',
            additional_info: salaryData?.additional_info ? salaryData?.additional_info : '',
            variable_remuneration_ratio: salaryData?.variable_remuneration_ratio ? salaryData?.variable_remuneration_ratio : '',
            variable_remuneration_min_value: salaryData?.variable_remuneration_min_value ? salaryData?.variable_remuneration_min_value : '',
            variable_remuneration_max_value: salaryData?.variable_remuneration_max_value ? salaryData?.variable_remuneration_max_value : '',
        })

        setContract({
            id: job_contract?.id ? job_contract?.id : 0,
            contract_type: job_contract?.contract_type ? job_contract?.contract_type?.toString() : 1,
            work_schedule: job_contract?.work_schedule ? job_contract?.work_schedule?.toString() : 1,
            work_shift: job_contract?.work_shift ? job_contract?.work_shift?.toString() : 1,
            seniority_level: job_contract?.seniority_level ? job_contract?.seniority_level?.toString() : 1,
            work_level: job_contract?.work_level ? job_contract?.work_level?.toString() : 1,
            salary: salaryData ? salaryData?.id : 0,
        })
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const result = await requests.get(API.JOB_POSITIONS + params.id + '/')

            if (result.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (result.status === 200) {
                // update position
                setPositionValues(result.response, result.response.id)
            }
            setIsLoading(false)
        }

        if (params.id) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [params.id])

    useEffect(() => {
        async function fetchIscoCodes() {
            setFetchingIscos(true)
            const request = await requests.get(API.JOBS + 'isco/')
            if (request.status === 200) {
                setIscoCodes(
                    request.response.map((item) => ({
                        id: item.id,
                        value: item.id,
                        text: `${item.code || ''} - ${item.fullname} ${item.shortname ? `- (${item.shortname})` : ''}`,
                    }))
                )
            }
            setFetchingIscos(false)
        }

        fetchIscoCodes()
    }, [])

    // Form states:
    const [isLoading, setIsLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState([])

    // visibility states:
    const [contractView, setContractView] = useState(true)

    const handleSubmit = async () => {
        setErrors([])
        setIsProcessing(true)

        let salaryData = {
            salary_type: salary.salary_type,
            currency: salary.currency,
            variable_remuneration_ratio: salary.variable_remuneration_ratio,
            variable_remuneration_min_value: salary.variable_remuneration_min_value,
            variable_remuneration_max_value: salary.variable_remuneration_max_value,
            additional_info: salary.additional_info,
        }

        if (salary.min !== '') {
            salaryData['min'] = salary.min
        }
        if (salary.max !== '') {
            salaryData['max'] = salary.max
        }

        let contractData = {
            contract_type: contract.contract_type,
            work_schedule: contract.work_schedule,
            work_shift: contract.work_shift,
            seniority_level: contract.seniority_level,
            work_level: contract.work_level,
        }

        // create or update job position:
        let request = null

        let data = {
            title: position.title,
            description: position.description,
            is_external: position.is_external,
            work_factors: position.work_factors,
            work_activity: position.work_activity,
            isco: position.isco !== '' ? position.isco : null,
            level: position.level !== '' ? position.level : null,
            group: position.group !== '' ? position.group : null,
            position_number: position.position_number,
            job_family: position.job_family !== '' ? position.job_family : null,
            // to be created using RESTQL
            job_contract: {
                contract_type: contractData.contract_type !== '' ? contractData.contract_type : null,
                work_schedule: contractData.work_schedule,
                work_shift: contractData.work_shift,
                seniority_level: contractData.seniority_level !== '' ? contractData.seniority_level : null,
                work_level: contractData.work_level !== '' ? contractData.work_level : 1,
                salary: {
                    salary_type: salaryData.salary_type !== '' ? salaryData.salary_type : null,
                    min: salaryData.min,
                    max: salaryData.max,
                    currency: salaryData.currency,
                    additional_info: salaryData.additional_info,
                    variable_remuneration_ratio: salaryData.variable_remuneration_ratio !== '' ? salaryData.variable_remuneration_ratio : null,
                    variable_remuneration_min_value: salaryData.variable_remuneration_min_value !== '' ? salaryData.variable_remuneration_min_value : null,
                    variable_remuneration_max_value: salaryData.variable_remuneration_max_value !== '' ? salaryData.variable_remuneration_max_value : null,
                },
            },
        }

        let oldBonuses = position?.old_bonuses || []
        if (position.bonuses.length > 0) {
            data['bonuses'] = {
                add: position.bonuses,
            }

            if (oldBonuses.length > 0) {
                data['bonuses'] = {
                    remove: oldBonuses,
                    add: position.bonuses,
                }
            }
        }

        if (position.id === 0) {
            // create
            request = await requests.post(API.JOB_POSITIONS, data)
        } else {
            // update
            request = await requests.put(API.JOB_POSITIONS + position.id + '/', data)
        }

        if (request.status === 400) setErrors(request.response)
        if (request.status === 200 || request.status === 201) history.push(routes.JOBS_POSITIONS)
        setIsProcessing(false)
    }

    return (
        <CanView permissions={['jobs.c_manage_job_positions']} redirect>
            <BreadcrumbNav items={breadcrumb_items} />
            {isLoading ? (
                <Loading />
            ) : (
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={16} tablet={16} mobile={16} style={{ padding: 0 }}>
                                {isLoading ? (
                                    <Loading />
                                ) : (
                                    <Form
                                        onSubmit={() => handleSubmit()}
                                        style={{
                                            background: 'var(--light)',
                                            padding: '2rem',
                                            paddingBottom: '3.5rem',
                                            marginBottom: '1rem',
                                        }}
                                    >
                                        <Header style={{ paddingBottom: '2rem', paddingTop: '0rem' }} as="h2" content={'1. ' + t('base_information')} />
                                        <Form.Group widths="equal">
                                            <SuperField
                                                as="input"
                                                required
                                                label={t('title')}
                                                placeholder={t('title')}
                                                value={position.title}
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        title: value,
                                                    })
                                                }
                                            />
                                            <SuperField
                                                as="choice"
                                                search
                                                label={t('isco')}
                                                disabled={fetchingIscos}
                                                loading={fetchingIscos}
                                                settings="types/isco"
                                                value={position.isco}
                                                customOptions={iscoCodes}
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        isco: value,
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group widths="equal">
                                            <SuperField
                                                as="input"
                                                label={t('code')}
                                                placeholder={t('code')}
                                                value={position.position_number}
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        position_number: value,
                                                    })
                                                }
                                            />

                                            <SuperField
                                                as="choice"
                                                search
                                                label={t('job_family')}
                                                settings="types/job-families"
                                                value={position.job_family}
                                                endpoint={API.JOBS + 'job_families/'}
                                                text="title"
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        job_family: value,
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group widths="equal">
                                            <SuperField
                                                as="choice"
                                                search
                                                label={t('group')}
                                                settings="types/position-groups"
                                                value={position.group}
                                                endpoint={API.JOBS + 'position_groups/'}
                                                text="title"
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        group: value,
                                                    })
                                                }
                                            />
                                            <SuperField
                                                as="choice"
                                                search
                                                label={t('level')}
                                                settings="types/position-levels"
                                                value={position.level}
                                                endpoint={API.JOBS + 'position_levels/'}
                                                text="title"
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        level: value,
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <CanView permissions={['payrolls.c_view_all_payrolls']}>
                                            <SuperField
                                                as="choice"
                                                search
                                                multiple
                                                label={t('bonuses')}
                                                settings="types/bonuses"
                                                value={position.bonuses}
                                                endpoint={API.PAYROLLS + 'bonuses/'}
                                                text="title"
                                                onChange={(e, { value }) =>
                                                    setPosition({
                                                        ...position,
                                                        bonuses: value,
                                                    })
                                                }
                                            />
                                        </CanView>

                                        <SuperField
                                            as="checkbox"
                                            label={t('is_external')}
                                            value={position.is_external}
                                            onChange={() =>
                                                setPosition({
                                                    ...position,
                                                    is_external: !position.is_external,
                                                })
                                            }
                                        />

                                        <Form.Field>
                                            {/* <Form.TextArea
                                                // autoFocus
                                                rows={12}
                                                label={t('job_description')}
                                                placeholder={t('enter_job_description')}
                                                value={position.description}
                                                onChange={(e) => setPosition({
                                                    ...position,
                                                    description: e.target.value
                                                })}
                                            /> */}

                                            <label style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{t('job_description')}</label>
                                            <EditorProvider>
                                                <Editor
                                                    containerProps={{
                                                        style: {
                                                            resize: 'vertical',
                                                            width: '100%',
                                                            background: 'white',
                                                            minHeight: '250px',
                                                        },
                                                    }}
                                                    value={position.description}
                                                    onChange={(e) =>
                                                        setPosition((prev) => ({
                                                            ...prev,
                                                            description: e.target.value,
                                                        }))
                                                    }
                                                >
                                                    <Toolbar>
                                                        <BtnUndo />
                                                        <BtnRedo />
                                                        <Separator />
                                                        <BtnBold />
                                                        {/* <BtnItalic /> */}
                                                        <BtnUnderline />
                                                        {/* <BtnStrikeThrough /> */}
                                                        <Separator />
                                                        <BtnNumberedList />
                                                        <BtnBulletList />
                                                        <Separator />
                                                        <BtnLink />
                                                        <Separator />
                                                        <BtnClearFormatting />
                                                        <HtmlButton />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        </Form.Field>

                                        <SuperField
                                            as="input"
                                            label={t('work_factors')}
                                            placeholder={t('work_factors')}
                                            value={position.work_factors}
                                            onChange={(e, { value }) =>
                                                setPosition({
                                                    ...position,
                                                    work_factors: value,
                                                })
                                            }
                                        />

                                        <SuperField
                                            as="input"
                                            label={t('work_activity')}
                                            placeholder={t('work_activity')}
                                            value={position.work_activity}
                                            onChange={(e, { value }) =>
                                                setPosition({
                                                    ...position,
                                                    work_activity: value,
                                                })
                                            }
                                        />

                                        <Divider />

                                        <Segment clearing style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'block',
                                                    width: '100%',
                                                    overflow: 'hidden',
                                                }}
                                                onClick={() => setContractView(!contractView)}
                                            >
                                                <Icon style={{ float: 'right', paddingTop: '0.5rem' }} name={contractView ? 'angle up' : 'angle down'} />
                                                <Header style={{ marginBottom: 0 }} floated="left" as="h2" content={'2. ' + t('contract_information')} />
                                            </div>

                                            <div
                                                style={{
                                                    float: 'left',
                                                    marginTop: '1rem',
                                                    width: '100%',
                                                    display: contractView ? 'block' : 'none',
                                                }}
                                            >
                                                <ContractsFieldGroup salary={salary} setSalary={setSalary} contract={contract} setContract={setContract} />
                                            </div>
                                        </Segment>

                                        <Message error visible={errors.length > 0} header={t('error_submission_message')} list={errors} />

                                        <Form.Field style={{ textAlign: 'right' }}>
                                            <Button disabled={isProcessing || position.title === ''} loading={isProcessing} size="huge" primary>
                                                {' '}
                                                {t('save')}{' '}
                                            </Button>
                                        </Form.Field>
                                    </Form>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )}
        </CanView>
    )
}

export default JobOfferForm
