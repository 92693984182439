import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import UserAvatar from 'react-user-avatar';
import ListView from '@components/ListView';
import AvatarList from '@components/AvatarList';
import CanView from '@components/perms/CanView';
import DatePresets from '@components/DatePresets';
import Attachments from '@components/Attachments';
import SuperField from '@components/forms/SuperField';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Icon as SemanticIcon, Button, Form, Label, Popup, Divider } from 'semantic-ui-react';
// custom components
import DuplicateOrder from './components/DuplicateOrder';
import OrderStageForm from './components/OrderStageForm';
import ProcessStageForm from './components/ProcessStageForm';
import DeleteStageAction from './components/DeleteStageAction';
import OrderOperationStats from './components/OrderOperationStats';
import UpdateOrderStatusForm from './components/UpdateOrderStatusForm';
import AssignProcessGroupForm from './components/AssignProcessGroupForm';
// order components
import OrderDetail from '../orders/shared/OrderDetail';
import InvoiceReferenceIndicator from '../orders/shared/InvoiceReferenceIndicator';
import HROrderFulfillment from '../orders/recruitment/components/HROrderFulfillment';
import SaleOrderFulfillment from '../orders/sales/components/SaleOrderFulfillment';

const OrderProcesses = () => {
    const { t } = useTranslation();
    const dateFormat = useSelector((state) => state?.date_format || 'YYYY-MM-DD');
    const canManage = useHasPermissions(['orders.c_manage_order']);
    const canManageFulfillments = useHasPermissions(['orders.c_manage_order_fulfillment']);

    const order_status_choices = useSelector((state) => state.choices.order_status_choices);
    const statuses = Object.entries(order_status_choices).map((choice, idx) => ({
        key: idx,
        text: choice[1],
        value: choice[0],
    }));

    // eslint-disable-next-line
    const firstDay = moment().startOf('month').format('YYYY-MM-DD');
    // eslint-disable-next-line
    const lastDay = moment().endOf('month').format('YYYY-MM-DD');

    // eslint-disable-next-line
    const [customAction, setCustomAction] = useState('');
    const [open, setOpen] = useState(0);
    const [attachmentsOpen, setAttachmentsOpen] = useState(0);

    const determinateStatusColor = (record) => {
        let iconColor = 'var(--dark)';

        if (record?.order_status === 1) iconColor = 'var(--primary)';
        if (record?.order_status === 2) iconColor = 'var(--info)';
        if (record?.order_status === 3) iconColor = 'var(--blue)';
        if (record?.order_status === 4) iconColor = 'var(--success)';
        if (record?.order_status === 5) iconColor = 'var(--warning)';
        if (record?.order_status === 6) iconColor = 'var(--danger)';

        return iconColor;
    };

    const formatDate = (value) => {
        if (value) {
            return moment(value).format(dateFormat);
        }

        return '--';
    };

    const handleDisplayShortAddress = (address) => {
        if (address?.country !== '' && address?.city !== '') return `${address?.city}, ${address?.country}`;

        return '';
    };

    const onStatusChange = async (status, record, filters, setData, setTotal, resourcetype) => {
        const request = await requests.patch(API.ORDERS + record.id + '/', {
            order_status: parseInt(status),
            resourcetype: resourcetype,
        });
        if (request.status === 200) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.order === record.id) {
                        item.order_display.order_status = request.response.order_status;
                        item.order_display.order_status_display = request.response.order_status_display;
                    }

                    return item;
                })
            );

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('record_status_changed'),
                animation: 'pulse',
                time: 2000,
            });
            setOpen(null);
        }
    };

    const saleItemPricePreview = (items) => {
        if (items.length === 0) return 0;
        const currencies = [];
        for (const item of items) {
            if (!currencies.includes(item.currency)) {
                currencies.push(item.currency);
            }
        }

        let totals = [];
        for (let i = 0; i < currencies.length; i++) {
            // calculate total for currency
            let total = 0;
            let currency = currencies[i];
            for (let y = 0; y < items.length; y++) {
                if (items[y].currency === currency) {
                    total += items[y].price_without_tax * items[y].quantity;
                }
            }

            totals.push({
                currency: currency,
                sum: total,
            });
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {totals.map((total) => (
                    <span>
                        {' '}
                        {thousandsSeparators(parseFloat(total.sum).toFixed(2))} {total.currency}{' '}
                    </span>
                ))}
            </div>
        );
    };

    const onOrderConfirm = async (id, setData) => {
        const request = await requests.put(API.ORDERS + id + '/confirm/');
        if (request.status === 200) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.order_display.id === id) {
                        item.order_display.confirmed_on = request.response.confirmed_on;
                        item.order_display.confirmed_by = request.response.confirmed_by;
                    }

                    return item;
                })
            );
        }
    };

    const ManageFulfillments = ({ item, setData, onClose }) => {
        return (
            <div>
                {item.type.order_type_display === 'HROrder' && (
                    <HROrderFulfillment
                        record={item.order_display}
                        onClose={onClose}
                        customDataHandler={(response) => {
                            setData((prev) =>
                                prev.filter((stage) => {
                                    if (stage.id === item.id) {
                                        stage.order_display = response;
                                    }
                                    return stage;
                                })
                            );
                        }}
                    />
                )}
                {item.type.order_type_display === 'SaleOrder' && (
                    <SaleOrderFulfillment
                        customDataHandler={(response) =>
                            setData((prev) =>
                                prev.filter((stage) => {
                                    if (stage.id === item.id) {
                                        stage.order_display = response;
                                    }
                                    return stage;
                                })
                            )
                        }
                        order={item.order_display}
                        onClose={onClose}
                    />
                )}
            </div>
        );
    };

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="2"
            endpoint={API.ORDERS + 'sale_stages/'}
            initialFilters={{
                is_purchase_type: '',
                date_from: '',
                date_to: '',
                process: '',
                currency: '',
                order_status: '',
                ordering: 'number',
            }}
            bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                {
                    as: 'modal',
                    name: t('assign_to_process_group'),
                    icon: 'file-tray-full-outline',
                    isHidden: !canManage,
                    modal: <AssignProcessGroupForm selected={selected} setSelected={setSelected} setData={setData} />,
                },
                {
                    as: 'modal',
                    icon: 'pencil-outline',
                    isHidden: !canManage,
                    name: t('update_order_state'),
                    modal: <UpdateOrderStatusForm selected={selected} setSelected={setSelected} setData={setData} />,
                },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            size="small"
                            label={t('date_from')}
                            value={filters.date_from}
                            style={{ height: '2.75rem' }}
                            onChange={(e, { value }) => setFilters({ ...filters, date_from: value })}
                        />
                        <SuperField
                            as="datepicker"
                            size="small"
                            label={t('date_to')}
                            value={filters.date_to}
                            style={{ height: '2.75rem' }}
                            onChange={(e, { value }) => setFilters({ ...filters, date_to: value })}
                        />
                    </Form.Group>
                    <DatePresets
                        from={filters.date_from}
                        to={filters.date_to}
                        onSelection={(firstMonthDay, lastMonthDay, year) => {
                            setFilters((prev) => ({
                                ...prev,
                                date_from: firstMonthDay,
                                date_to: lastMonthDay,
                            }));
                        }}
                    />

                    <Divider />

                    <SuperField
                        as="choice"
                        search
                        text="title"
                        size="small"
                        value={filters.process}
                        label={t('process_group')}
                        endpoint={API.ORDERS + 'sale_processes/?query={id, title}'}
                        onChange={(e, { value }) => setFilters({ ...filters, process: value })}
                    />

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            label={t('state')}
                            value={filters.order_status}
                            type="order_status_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, order_status: value })}
                        />

                        <SuperField
                            as="choice"
                            search
                            size="small"
                            label={t('record_type')}
                            customOptions={[
                                { key: 1, value: false, text: t('sell') },
                                { key: 2, value: true, text: t('buy') },
                            ]}
                            value={filters.is_purchase_type}
                            onChange={(e, { value }) => setFilters({ ...filters, is_purchase_type: value })}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            search
                            size="small"
                            label={t('record_type')}
                            customOptions={[
                                { key: 1, value: false, text: t('sell') },
                                { key: 2, value: true, text: t('buy') },
                            ]}
                            value={filters.is_purchase_type}
                            onChange={(e, { value }) => setFilters({ ...filters, is_purchase_type: value })}
                        />
                        <SuperField
                            as="choice"
                            search
                            size="small"
                            type="currency_codes"
                            label={t('currency')}
                            value={filters.currency}
                            onChange={(e, { value }) => setFilters({ ...filters, currency: value })}
                        />
                    </Form.Group>
                </>
            )}
            renderStatisticView={(filters, data, total, params, setTotal, setData, setFilters, fetchData) => (
                <CanView permissions={['reports.c_view_order_reports']}>
                    <OrderOperationStats
                        params={params}
                        data={data}
                        fetchData={fetchData}
                        setFilters={setFilters}
                        currency={filters.currency}
                    />
                </CanView>
            )}
            tableHeaders={[
                { title: t('record') },
                { title: t('process_group') },
                { title: t('type') },
                { title: t('date') },
                { title: t('order_details') },
                { title: t('supplier') },
                { title: t('account') },
                { title: t('price_without_tax') },
                { title: t('responsible_persons') },
                { title: t('documents') },
                { title: t('state') },
            ]}
            // setTableRowBackground={(item) => item.color ? item.color + "44" : "transparent" }
            renderCells={(record, setData, setTotal, idx, filters) => [
                { content: record.number },
                { content: record.process?.title || '' },
                {
                    content: (
                        <Label
                            size="tiny"
                            style={{
                                fontWeight: 'bold',
                                color: 'var(--dark)',
                                textTransform: 'uppercase',
                                background:
                                    record.order_display?.is_purchase_order === false ? '#0080001A' : '#fe00001A',
                                borderBottom:
                                    record.order_display?.is_purchase_order === false
                                        ? '3px solid #008000'
                                        : '3px solid #fe0000',
                            }}
                        >
                            {record.order_display?.is_purchase_order === false ? t('sell') : t('buy')}
                        </Label>
                    ),
                },
                {
                    content: (
                        <div>
                            {record.type.order_type_display === 'TransportOrder' ? (
                                <>
                                    {t('loading_date')}: <br />
                                    <strong>{formatDate(record?.order_display?.loading_date)}</strong> <br />
                                    {t('unloading_date')}: <br />
                                    <strong>{formatDate(record.order_display?.fulfillment?.delivery_date)}</strong>
                                </>
                            ) : (
                                <>
                                    <strong>{formatDate(record.date)}</strong>
                                </>
                            )}
                        </div>
                    ),
                },
                {
                    content: (
                        <div>
                            {record.order_display.order_number !== record.number && (
                                <>
                                    {t('order_number_short')}: <strong>{record.order_display.order_number}</strong>
                                    <br />
                                </>
                            )}
                            {record.type.order_type_display === 'TransportOrder' && (
                                <>
                                    {t('route')}:{' '}
                                    <strong>
                                        {handleDisplayShortAddress(record.order_display?.loading_address)} -{' '}
                                        {handleDisplayShortAddress(record.order_display?.destination_address)}
                                    </strong>{' '}
                                    <br />
                                    {t('vehicle')}:{' '}
                                    <strong>
                                        {record.order_display.truck?.car_plate_number ||
                                            record.order_display.external_truck}{' '}
                                        /{' '}
                                        {record.order_display.trailer?.car_plate_number ||
                                            record.order_display.external_trailer}
                                    </strong>{' '}
                                    <br />
                                </>
                            )}

                            {record.order_display.is_deleted !== true ? (
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <div className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {t('show_details')}
                                        </div>
                                    }
                                    content={<OrderDetail id={record.order} />}
                                />
                            ) : (
                                <span style={{ color: 'var(--danger)' }}> {t('order_was_deleted')} </span>
                            )}
                        </div>
                    ),
                },
                {
                    content:
                        record.order_display?.is_purchase_order === true ? (
                            record.order_display?.business_detail ? (
                                <>
                                    <Link
                                        to={routes.SUPPLIERS + record.order_display?.business_detail?.id}
                                        target="_blank"
                                    >
                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {record.order_display?.business_detail?.name}
                                        </span>
                                    </Link>
                                    <br />
                                    <small>{record.order_display?.business_detail?.identification_number}</small>
                                </>
                            ) : (
                                '--'
                            )
                        ) : record.order_display?.owner?.business_detail ? (
                            <>
                                <Link to={routes.UNIT_DETAIL + record.order_display?.owner?.id} target="_blank">
                                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                        {record.order_display?.owner?.business_detail?.name}
                                    </span>
                                </Link>
                                <br />
                                <small>{record.order_display?.owner?.business_detail?.identification_number}</small>
                            </>
                        ) : (
                            '--'
                        ),
                },
                {
                    content:
                        record.order_display?.is_purchase_order === true ? (
                            record.order_display?.owner?.business_detail ? (
                                <>
                                    <Link to={routes.UNIT_DETAIL + record.order_display?.owner?.id} target="_blank">
                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {record.order_display?.owner?.business_detail?.name}
                                        </span>
                                    </Link>
                                    <br />
                                    <small>{record.order_display?.owner?.business_detail?.identification_number}</small>
                                </>
                            ) : (
                                '--'
                            )
                        ) : record.order_display?.business_detail ? (
                            <>
                                <Link to={routes.ACCOUNTS + record.order_display?.business_detail?.id} target="_blank">
                                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                        {record.order_display?.business_detail?.name}
                                    </span>
                                </Link>
                                <br />
                                <small>{record.order_display?.business_detail?.identification_number}</small>
                            </>
                        ) : (
                            '--'
                        ),
                },

                {
                    content: (
                        <>
                            <div>
                                {record.type.order_type_display === 'TransportOrder' && (
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {thousandsSeparators(record.order_display?.price?.sum || 0) +
                                            ' ' +
                                            record.order_display?.price?.currency}
                                        <sup style={{ marginLeft: '0.5rem' }}>
                                            <Popup
                                                basic
                                                trigger={
                                                    <Icon name="information-circle" style={{ fontSize: '1rem' }} />
                                                }
                                                content={
                                                    <div>
                                                        <strong>{t('total_fulfilled')}: </strong> <br />
                                                        {record.order_display.fulfillment
                                                            ? thousandsSeparators(
                                                                  record.order_display.fulfillment?.total_fee || 0
                                                              ) +
                                                              ' ' +
                                                              record.order_display?.price?.currency
                                                            : `0  ${record.order_display?.price?.currency}`}
                                                    </div>
                                                }
                                            />
                                        </sup>
                                    </div>
                                )}

                                {record.type.order_type_display === 'HROrder' && (
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {thousandsSeparators(
                                            parseFloat(
                                                (record.order_display?.fee?.sum || 0) * record.order_display.amount
                                            )?.toFixed(2) || 0
                                        ) +
                                            ' ' +
                                            record.order_display?.fee?.currency}
                                        <sup style={{ marginLeft: '0.5rem' }}>
                                            <Popup
                                                basic
                                                trigger={
                                                    <Icon name="information-circle" style={{ fontSize: '1rem' }} />
                                                }
                                                content={
                                                    <div>
                                                        <strong>{t('total_fulfilled')}: </strong> <br />
                                                        {thousandsSeparators(
                                                            parseFloat(
                                                                (record.order_display.fee?.sum || 0) *
                                                                    (record.order_display?.total_fulfillments || 0)
                                                            ).toFixed(2)
                                                        ) +
                                                            ' ' +
                                                            record.order_display?.fee?.currency}
                                                    </div>
                                                }
                                            />
                                        </sup>
                                    </div>
                                )}

                                {record.type.order_type_display === 'SaleOrder' && (
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {saleItemPricePreview(record.order_display.items)}
                                        <sup style={{ marginLeft: '0.5rem' }}>
                                            <Popup
                                                basic
                                                trigger={
                                                    <Icon name="information-circle" style={{ fontSize: '1rem' }} />
                                                }
                                                content={
                                                    <>
                                                        <strong>{t('total_fulfilled')}: </strong> <br />
                                                        <>
                                                            {record.order_display?.total_price?.length === 0 &&
                                                                0 + ' EUR'}
                                                            <div>
                                                                {record.order_display?.total_price?.map(
                                                                    (currency, index) => (
                                                                        <>
                                                                            {thousandsSeparators(currency?.sum || 0) +
                                                                                ' ' +
                                                                                currency.sum_currency}{' '}
                                                                            <br />
                                                                        </>
                                                                    )
                                                                )}
                                                            </div>
                                                        </>
                                                    </>
                                                }
                                            />
                                        </sup>
                                    </div>
                                )}
                            </div>
                        </>
                    ),
                },
                {
                    content: (
                        <div style={{ display: 'flex', marginTop: '1rem' }}>
                            {record.order_display?.order_manager && (
                                <Popup
                                    position="top center"
                                    trigger={
                                        <span style={{ cursor: 'normal' }}>
                                            <UserAvatar
                                                style={{
                                                    color: 'white',
                                                    paddingLeft: '0rem',
                                                    fontSize: 25 / 2.5 + 'px',
                                                    borderRadius: '50%',
                                                    border: '2px solid white',
                                                }}
                                                size={25}
                                                name={record.order_display?.order_manager?.fullname || 'JD'}
                                                // color={getRandomColor()}
                                                src={record.order_display?.order_manager?.profile_picture}
                                            />
                                            <Icon
                                                name="ribbon"
                                                style={{
                                                    position: 'relative',
                                                    bottom: 0,
                                                    top: '-0.7rem',
                                                    left: '0.1rem',
                                                    fontSize: '1rem',
                                                    borderRadius: '50%',
                                                    border: '2px solid white',
                                                    background: 'var(--light)',
                                                }}
                                            />
                                        </span>
                                    }
                                    content={
                                        <span>
                                            {t('order_manager') + ': '}{' '}
                                            <strong>{record.order_display?.order_manager?.fullname}</strong>
                                        </span>
                                    }
                                />
                            )}
                            {record.order_display?.assigned_to?.length > 0 && (
                                <>
                                    <div
                                        style={{
                                            paddingLeft: '0.3rem',
                                            paddingRight: '0.3rem',
                                            paddingTop: '0.3rem',
                                            opacity: 0.5,
                                            userSelect: 'none',
                                        }}
                                    >
                                        {' '}
                                        •{' '}
                                    </div>
                                    <AvatarList
                                        size="25"
                                        alignDirection="start"
                                        persons={record.order_display?.assigned_to.map((item) => ({
                                            id: item?.id,
                                            name: item?.fullname,
                                            avatar: item?.profile_picture || '',
                                            displayName: item?.fullname,
                                        }))}
                                    />
                                </>
                            )}
                        </div>
                    ),
                },
                {
                    content: (
                        <Label basic>
                            <Popup
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === record.id}
                                onOpen={() => setAttachmentsOpen(record.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {record.order_display.attachments.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        viewOnly={!canManage}
                                        setData={setData}
                                        record={record.order_display}
                                        customDataHandler={(response, toRemove) => {
                                            if (response !== null) {
                                                // patch
                                                setData((prev) =>
                                                    prev.filter((item) => {
                                                        if (item.id === record.id) {
                                                            item.order_display.attachments = response.attachments;
                                                        }
                                                        return item;
                                                    })
                                                );
                                            } else {
                                                // delete
                                                setData((prev) =>
                                                    prev.filter((item) => {
                                                        if (item.id === record.id) {
                                                            item.order_display.attachments =
                                                                record.order_display.attachments.filter(
                                                                    (attachment) => attachment.id !== toRemove
                                                                );
                                                        }
                                                        return item;
                                                    })
                                                );
                                            }
                                        }}
                                        sourceEndpoint={API.ORDERS}
                                        closeView={() => setAttachmentsOpen(0)}
                                        moduleName={record.type.order_type_display}
                                        additionalData={{ resourcetype: record.type.order_type_display }}
                                    />
                                }
                            />
                        </Label>
                    ),
                },
                {
                    content:
                        record.order_display.confirmed_on === null ? (
                            <>
                                <SemanticIcon
                                    size="small"
                                    name="circle"
                                    style={{ color: 'var(--dark)', position: 'relative', top: '-0.1rem' }}
                                />{' '}
                                {t('not_confirmed')} <br />
                                <CanView permissions={['orders.c_confirm_order']}>
                                    <ConfirmModal
                                        description={t('confirm_message')}
                                        onConfirm={() => onOrderConfirm(record.order_display.id, setData)}
                                        button={
                                            <small style={{ color: 'var(--primary)' }} className="ref-link">
                                                {' '}
                                                {t('add_confirmation')}{' '}
                                            </small>
                                        }
                                    />
                                </CanView>
                            </>
                        ) : (
                            <>
                                {canManage ? (
                                    <Popup
                                        basic
                                        hoverable
                                        position="bottom center"
                                        style={{ padding: 0 }}
                                        open={open === record?.id}
                                        onOpen={() => setOpen(record?.id)}
                                        onClose={() => {
                                            if (open === record?.id && customAction === 'update') {
                                                setOpen(record?.id);
                                            } else {
                                                setOpen(null);
                                            }
                                        }}
                                        trigger={
                                            <div>
                                                <SemanticIcon
                                                    size="small"
                                                    name="circle"
                                                    style={{
                                                        color: determinateStatusColor(record.order_display),
                                                        position: 'relative',
                                                        top: '-0.1rem',
                                                    }}
                                                />
                                                {record.order_display.order_status_display}
                                                <Icon
                                                    name="chevron-down"
                                                    style={{
                                                        marginLeft: '0.4rem',
                                                        position: 'relative',
                                                        top: '0.2rem',
                                                    }}
                                                />
                                            </div>
                                        }
                                        content={
                                            <div
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {statuses.map((item) => (
                                                    <Button
                                                        basic
                                                        size="tiny"
                                                        type="button"
                                                        style={{ marginRight: 0 }}
                                                        active={
                                                            parseInt(item.value) ===
                                                            parseInt(record.order_display.order_status)
                                                        }
                                                        key={item.key}
                                                        content={
                                                            <>
                                                                <SemanticIcon
                                                                    size="small"
                                                                    name="circle"
                                                                    style={{
                                                                        color: determinateStatusColor({
                                                                            order_status: parseInt(item.value),
                                                                        }),
                                                                        position: 'relative',
                                                                        top: '-0.1rem',
                                                                    }}
                                                                />
                                                                {item.text}
                                                            </>
                                                        }
                                                        onClick={() =>
                                                            onStatusChange(
                                                                item.value,
                                                                record.order_display,
                                                                filters,
                                                                setData,
                                                                setTotal,
                                                                record.type.order_type_display
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        }
                                    />
                                ) : (
                                    <Label
                                        style={{
                                            color: 'var(--light)',
                                            background: determinateStatusColor(record.order_display),
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <span style={{ position: 'relative', top: '0.1rem' }}>
                                            {record.order_display.status_display}
                                        </span>
                                    </Label>
                                )}
                                <div>
                                    {record.type.order_type_display === 'TransportOrder' && (
                                        <InvoiceReferenceIndicator fulfillment={record.order_display.fulfillment} />
                                    )}
                                </div>
                                <div>
                                    <small>
                                        {' '}
                                        {t('confirmed_on')}{' '}
                                        {moment(record.order_display.confirmed_on).format(dateFormat)}{' '}
                                    </small>
                                </div>
                            </>
                        ),
                },
            ]}
            actions={[
                {
                    as: 'modal',
                    modalSize: 'tiny',
                    type: 'add',
                    modalCentered: false,
                    name: t('add_operation'),
                    modal: <ProcessStageForm />,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'add-circle-outline',
                    name: t('create_fulfillment'),
                    isHidden: (item) => item.type.order_type_display === 'TransportOrder',
                    disabled: (item) => item.type.order_type_display === 'TransportOrder' || !canManageFulfillments,
                    customIconColor: 'var(--dark)',
                    modal: (item, setData) => <ManageFulfillments item={item} setData={setData} />,
                    permissions: ['orders.c_manage_order_fulfillment'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'layers-outline',
                    disabled: true,
                    name: (item) => (item.order_display.is_purchase_order ? t('receive_invoice') : t('issue_invoice')),
                    customIconColor: 'var(--blue)',
                    modal: <div>...</div>,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    modalSize: 'tiny',
                    icon: 'copy-outline',
                    name: t('duplicate'),
                    modalCentered: false,
                    modal: <DuplicateOrder />,
                    customIconColor: 'var(--blue)',
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: 'modal',
                    type: 'edit',
                    modalCentered: false,
                    name: t('edit'),
                    permissions: ['orders.c_manage_order'],
                    modal: (item, setData) => (
                        <OrderStageForm
                            stage={item}
                            handleConfirm={async (response, processGroup) => {
                                // update stage:
                                const request = await requests.patch(API.ORDERS + 'sale_stages/' + item.id + '/', {
                                    process: processGroup !== '' ? processGroup : null,
                                });

                                if (response.order_number === response.original_order_number) {
                                    // not filled custom order number... assign based on stage number
                                    const updateOrder = await requests.patch(API.ORDERS + response.id + '/', {
                                        order_number: item.number,
                                        resourcetype: item.type.order_type_display,
                                    });

                                    if (updateOrder.status === 200) {
                                        response = updateOrder.response;
                                    }
                                }

                                if (request.status === 200) {
                                    setData((prev) =>
                                        prev.filter((currItem) => {
                                            if (currItem.id === item.id) {
                                                item.order_display = response;
                                                item.process = request.response.process;
                                            }

                                            return item;
                                        })
                                    );
                                }
                            }}
                            resourceType={item.type.order_type_display.toLowerCase()}
                            isPurchase={item.order_display.is_purchase_order}
                        />
                    ),
                },
                {
                    as: 'modal',
                    type: 'custom',
                    modalSize: 'tiny',
                    icon: 'close-outline',
                    name: t('remove'),
                    customIconColor: 'var(--danger)',
                    modal: (item) => <DeleteStageAction stage={item} />,
                    permissions: ['orders.c_manage_order'],
                },
            ]}
        />
    );
};

export default OrderProcesses;
